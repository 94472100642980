import React, { useEffect, useMemo, useState } from "react";
import api from "shared/api";
import TablePaginationFooter from "./TablePaginationFooter";
import TableSearchField from "./TableSearchField";
import YetAnotherTable from "./YetAnotherTable";

// Maybe this needs a bit more love, I've basically added useStateForPagination
// so these can be used internally and not molest the query string, but thinking
// maybe it should be 2x wrapper components and one core component...
const GenericListPageContents = ({
  apiPath,
  apiParams = {},
  renderBody,
  renderHeader = null,
  renderHeaderRow,
  renderRow,
  resetKey = null,
  paginationParams,
  setPaginationParams,
  sortParams = { sort: null, type: null },
  setSortParams,
  isFlush,
}) => {
  // State Vars
  const [maxPage, setMaxPage] = useState(1);

  const [entitiesForPage, setEntitiesForPage] = useState({});

  // Reset state vars
  useEffect(() => {
    setPaginationParams({ page: 1 });
    setEntitiesForPage(lookup =>
      Object.keys(lookup).length === 0 ? lookup : {}
    );
  }, [
    paginationParams.q,
    paginationParams.per_page,
    sortParams.type,
    resetKey,
    setPaginationParams,
    setSortParams,
  ]);

  const entities = useMemo(() => {
    if (sortParams.sort) {
      return (
        (entitiesForPage[paginationParams.page] &&
          entitiesForPage[paginationParams.page][sortParams.sort]) ||
        null
      );
    } else {
      return entitiesForPage[paginationParams.page]?.default || null;
    }
  }, [entitiesForPage, paginationParams.page, sortParams.sort]);

  // Fetch entities when page, query or sort changes.
  useEffect(() => {
    if (entities) {
      return;
    }
    const params = {
      ...apiParams,
      page: paginationParams.page,
      count: paginationParams.per_page,
      query: paginationParams.q,
      sort: sortParams.sort,
      type: sortParams.type,
    };
    if (!sortParams.sort) delete params.sort;

    const controller = new AbortController();
    api
      .get(apiPath, {
        params,
        signal: controller.signal,
      })
      .then(response => {
        if (!response) {
          return;
        }
        const { data } = response;
        const updateKey = sortParams.sort
          ? { [sortParams.sort]: data.data }
          : { default: data.data };
        setEntitiesForPage({
          ...entitiesForPage,
          [paginationParams.page]: {
            ...entitiesForPage[paginationParams.page],
            ...updateKey,
          },
        });

        setMaxPage(
          Math.ceil(data.meta.total_count / paginationParams.per_page)
        );
      });

    return () => controller.abort();
  }, [
    apiParams,
    apiPath,
    entities,
    entitiesForPage,
    paginationParams.page,
    paginationParams.per_page,
    paginationParams.q,
    sortParams.sort,
    sortParams.type,
  ]);

  return (
    <YetAnotherTable
      data={entities}
      isLoading={entities === null}
      isFlush={isFlush}
      renderBody={renderBody}
      renderFooter={() => (
        <TablePaginationFooter
          currentPage={paginationParams.page}
          lastPage={maxPage}
          onPageSelect={page => setPaginationParams({ page })}
        />
      )}
      renderHeader={
        renderHeader ||
        (() => (
          <div className="card-header">
            <div className="row">
              <div className="col">
                <TableSearchField
                  currentQuery={paginationParams.q}
                  onQueryChange={q =>
                    setPaginationParams({ q: q || undefined })
                  }
                />
              </div>
            </div>
          </div>
        ))
      }
      renderHeaderRow={renderHeaderRow}
      renderRow={renderRow}
    />
  );
};

export default GenericListPageContents;
