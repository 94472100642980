import React, { useMemo } from "react";

const StatusBadge = ({ expires_at, published_at, is_test }) => {
  const [status, colour] = useMemo(() => {
    const now = new Date();
    if (expires_at && new Date(expires_at) < now) {
      return ["Expired", "danger"];
    }
    if (published_at) {
      return new Date(published_at) < now
        ? ["Published", "success"]
        : ["Scheduled", "info"];
    } else {
      return ["Draft", "warning"];
    }
  }, [expires_at, published_at]);

  return (
    <>
      <span className={`ms-3 badge bg-${colour}-soft`}>{status}</span>
      {is_test && <span className={`ms-3 badge bg-secondary-soft`}>Test</span>}
    </>
  );
};

export default StatusBadge;
