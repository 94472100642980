import React from "react";
import { Route, Switch } from "react-router-dom";
import VenueChainListPage from "./venueChain/VenueChainListPage";
import VenueChainDetailPage from "./venueChain/VenueChainDetailPage";

const VenueChainSection = () => (
  <Switch>
    <Route path="/venue-chains/:id" component={VenueChainDetailPage} />
    <Route component={VenueChainListPage} />
  </Switch>
);

export default VenueChainSection;
