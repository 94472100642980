import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import api from "shared/api";
import CardModal from "shared/components/CardModal";
import DateTimeField from "shared/components/formFields/DateTimeField";
import SingleSelectField from "shared/components/formFields/SingleSelectField";
import LoadingSpinner from "shared/components/LoadingSpinner";
import moment from "moment";

const AddOfferToVenueModal = ({ onAdded, onClose, show, venueId }) => {
  const [offers, setOffers] = useState(null);

  useEffect(() => {
    if (offers) {
      return;
    }

    api.get("/offers", { params: { count: 250 } }).then(({ data }) => {
      setOffers(data.data);
    });
  }, [offers]);

  return (
    <CardModal onClose={onClose} show={show} title="Add Offer">
      {() => (
        <>
          <Formik
            initialValues={{
              offer_id: "",
              available_from: "",
              available_until: "",
            }}
            onSubmit={({ offer_id, available_from, available_until }) =>
              new Promise(resolve => {
                const newOffer = {
                  id: parseInt(offer_id, 10),
                };

                if (available_from) {
                  newOffer.available_from = moment(available_from)
                    .utc()
                    .format();
                }

                if (available_until) {
                  newOffer.available_until = moment(available_until)
                    .utc()
                    .format();
                }

                api.post(`/venues/${venueId}/offers`, [newOffer]).then(
                  ({ data }) => {
                    resolve();
                    onAdded(data.data);
                  },
                  err => {
                    console.log(err);
                    resolve();
                  }
                );
              })
            }
            validationSchema={Yup.object().shape({
              offer_id: Yup.number().required(
                "You must select an offer to add."
              ),
              available_from: Yup.date(),
              available_until: Yup.date(),
            })}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <div className="card-body overflow-visible">
                  <div className="row">
                    <div className="col-12">
                      <SingleSelectField
                        className="form-group"
                        label="Offer"
                        name="offer_id"
                        options={offers || []}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <DateTimeField
                        className="form-group"
                        label="Published From"
                        minDate={new Date()}
                        maxDate={values.available_until || null}
                        name="available_from"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <DateTimeField
                        className="form-group"
                        label="Published Until"
                        minDate={values.available_from || new Date()}
                        name="available_until"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    className="btn btn-white"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary ms-3" type="submit">
                    {isSubmitting ? <LoadingSpinner /> : "Confirm"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </CardModal>
  );
};

export default AddOfferToVenueModal;
