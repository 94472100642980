import { useField } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import styles from "./ColourField.module.scss";
import { SketchPicker } from "react-color";

const ColourField = ({ label, name }) => {
  const fieldRef = useRef(null);
  const [field, meta, { setValue }] = useField(name);
  const [isShowingPicker, setIsShowingPicker] = useState(false);

  const handleClickAway = useCallback(
    e => {
      if (!isShowingPicker) {
        return;
      }

      if (fieldRef?.current && !fieldRef.current.contains(e.target)) {
        setIsShowingPicker(false);
      }
    },
    [isShowingPicker]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickAway);

    return () => {
      window.removeEventListener("click", handleClickAway);
    };
  }, [handleClickAway]);

  return (
    <div className="position-relative" ref={fieldRef}>
      <div className={styles.pickerContainer}>
        {isShowingPicker && (
          <SketchPicker
            color={field.value || undefined}
            onChangeComplete={({ hex }) => setValue(hex)}
          />
        )}
      </div>
      {label && <label className="form-label">{label}</label>}
      <div
        className={cx(styles.colourBubble, meta && meta.error && "is-invalid")}
        onClick={() => setIsShowingPicker(!isShowingPicker)}
        role="button"
        style={{
          backgroundColor: field.value || "transparent",
        }}
      />
      {meta && meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default ColourField;
