import React, { useState } from "react";
import moment from "moment";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { useHistory } from "react-router";

import PageHeader from "../../shared/components/PageHeader";
import VenueChainFormModal from "./listPage/VenueChainFormModal";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import TableHeaderSortable from "shared/components/TableHeaderSortable";

const VenueChainListPage = () => {
  const [isCreatingChain, setIsCreatingChain] = useState(false);
  const history = useHistory();

  const [sortParams, setSortParams] = useQueryParams({
    sort: withDefault(StringParam, null),
  });

  return (
    <div>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <button
              className="btn btn-primary lift"
              onClick={() => setIsCreatingChain(true)}
            >
              Create Group
            </button>
          </div>
        )}
      >
        Venue Groups
      </PageHeader>
      <QueryStringListPageContents
        apiPath="/venue-chains"
        setSortParams={setSortParams}
        sortParams={sortParams}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "ID", sortBy: "id" },
              { label: "Name", sortBy: "name" },
              { label: "Venue Count", sortBy: "venue_count" },
              { label: "Last Updated", sortBy: "updated_at" },
            ]}
            setSortParams={setSortParams}
            sortParams={sortParams}
          />
        )}
        renderRow={chain => (
          <tr
            key={chain.id}
            role="button"
            onClick={() => history.push(`/venue-chains/${chain.id}`)}
          >
            <td>{chain.id}</td>
            <td>{chain.name}</td>
            <td>{chain.venue_count}</td>
            <td>{moment(chain.updated_at).format("Do MMM YYYY, HH:mm")}</td>
          </tr>
        )}
      />
      <VenueChainFormModal
        onClose={() => setIsCreatingChain(false)}
        onConfirm={chain => history.push(`venue-chains/${chain.id}`)}
        show={isCreatingChain}
      />
    </div>
  );
};

export default VenueChainListPage;
