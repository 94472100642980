import React from "react";

const InfoCardKeyValueList = ({ items }) => (
  <div className="list-group list-group-flush my-n3">
    {items.map((item, idx) => (
      <div className="list-group-item" key={idx}>
        <div className="row align-items-center">
          <div className="col">
            <h5 className="my-0">{item.label}</h5>
          </div>
          <div className="col-auto">
            {item.renderValue?.() || (
              <p className="small text-muted my-0">{item.value}</p>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default InfoCardKeyValueList;
