import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const Loading = ({ height }) => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ minHeight: height ? height : 400 }}
  >
    <LoadingSpinner />
  </div>
);

export default Loading;
