import React, { useEffect, useState, useCallback } from "react";
import { useParams, Redirect } from "react-router-dom";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import Helmet from "react-helmet";
import PageHeader from "shared/components/PageHeader";
import VenueChainFormModal from "./listPage/VenueChainFormModal";
import VenueChainVenueListTable from "./detailPage/VenueChainVenueListTable";
import DeleteModal from "shared/modals/DeleteModal";
import VenueChainAddVenuesForm from "./detailPage/VenueChainAddVenuesForm";

const VenueChainDetailPage = () => {
  const { id: chainId } = useParams();

  const [chain, setChain] = useState(null);
  const [isUpdatingChain, setIsUpdatingChain] = useState(false);
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);
  const [resetKey, setResetKey] = useState(null);

  useEffect(() => {
    if (chain && chain.id === parseInt(chainId, 10)) {
      return;
    }

    api.get(`/venue-chains/${chainId}`).then(({ data }) => {
      setChain(data.data);
    });
  }, [chain, chainId]);

  const deleteChain = useCallback(() => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    api
      .delete(`/venue-chains/${chainId}`)
      .then(() => setRedirectPath("/venue-chains"))
      .finally(() => setIsDeleting(false));
  }, [chainId, isDeleting]);

  const onUpdateVenues = useCallback(
    params => {
      return new Promise((resolve, reject) => {
        if (isUpdating) {
          resolve();
        }
        setIsUpdating(true);
        api.put(`/venue-chains/${chainId}`, params).then(
          () => {
            setResetKey(Date.now());
            setIsUpdating(false);
            resolve();
          },
          () => {
            setIsUpdating(false);
            reject();
          }
        );
      });
    },
    [chainId, setResetKey, isUpdating]
  );

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (!chain) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`${chain.name} | Groups`}</title>
      </Helmet>
      <PageHeader
        pretitle="Groups"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <button
              className="btn btn-primary lift ms-3"
              onClick={() => setIsUpdatingChain(true)}
            >
              <i className="fe fe-edit-3 me-2" />
              Edit Name
            </button>
            <button
              className="btn btn-outline-danger lift ms-3"
              onClick={() => setIsShowingDeleteModal(true)}
            >
              <i className="fe fe-archive me-2" />
              Delete
            </button>
          </div>
        )}
      >
        {chain.name}
      </PageHeader>
      <VenueChainAddVenuesForm
        handleAddVenues={selectedVenues =>
          onUpdateVenues({
            add_venue_ids: selectedVenues.map(venue => venue.id),
          })
        }
      />
      <VenueChainVenueListTable
        chainId={chainId}
        resetKey={resetKey}
        handleRemoveVenues={ids =>
          onUpdateVenues({
            remove_venue_ids: ids,
          })
        }
      />
      <VenueChainFormModal
        chain={chain}
        onClose={() => setIsUpdatingChain(false)}
        onConfirm={updatedChain => {
          setChain(updatedChain);
          setIsUpdatingChain(false);
        }}
        show={isUpdatingChain}
      />
      <DeleteModal
        handleClose={() => setIsShowingDeleteModal(false)}
        handleAction={deleteChain}
        isDeleting={isDeleting}
        isOpen={isShowingDeleteModal}
        item="Group"
      >
        <p className="my-0" style={{ whiteSpace: "pre-line" }}>
          Are you sure you wish to delete this group?
          <br />
          {chain.venue_count} venue{chain.venue_count !== 1 ? "s" : ""} will be
          abandoned.
        </p>
      </DeleteModal>
    </div>
  );
};

export default VenueChainDetailPage;
