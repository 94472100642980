import React from "react";

const FormSubmitButton = ({ children, icon = "check", isSubmitting }) => (
  <button
    className="btn btn-primary d-block d-md-inline-block"
    disabled={isSubmitting}
    type="submit"
  >
    {isSubmitting ? (
      <>
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        />
        Submitting...
      </>
    ) : (
      <>
        <i className={`fe fe-${icon} me-2`} />
        {children}
      </>
    )}
  </button>
);

export default FormSubmitButton;
