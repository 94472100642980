import React from "react";
import MediaItem from "./MediaItem";

const MediaBlock = ({ mediaUrls }) => {
  if (!mediaUrls || mediaUrls.length === 0) {
    return <p className="text-muted">No media available.</p>;
  }

  const [firstImageUrl, ...secondaryMediaUrls] = mediaUrls;

  return (
    <div>
      {/* First image is special obv */}
      <div className="row">
        <div className="col-6 col-md-4 col-lg-12 mb-3">
          <MediaItem alt="Main Media" mediaUrl={firstImageUrl} />
        </div>
        {secondaryMediaUrls.map((mediaUrl, idx) => (
          <div className="col-6 col-md-4" key={idx}>
            <div
              className="mb-3"
              style={{
                paddingBottom: "100%",
                position: "relative",
              }}
            >
              <MediaItem
                alt="Alternative Media"
                mediaUrl={mediaUrl}
                style={{ position: "absolute", height: "100%" }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaBlock;
