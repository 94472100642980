import React, { useState } from "react";
import { Link } from "react-router-dom";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";
import DropdownButton from "shared/components/DropdownButton";
import RemoveOfferFromVenueModal from "app/venues/detailPage/RemoveOfferFromVenueModal";
import UpdateVenueOfferPauseStatusModal from "app/venues/detailPage/UpdateVenueOfferPauseStatusModal";

const OfferVenueListTable = ({ offerId }) => {
  const [removeOfferVenue, setRemoveOfferVenue] = useState(null);
  const [pauseOfferVenue, setPauseOfferVenue] = useState(null);
  const [resetKey, setResetKey] = useState(null);
  return (
    <>
      <LocalStateListPageContents
        apiPath={`/offers/${offerId}/venues`}
        perPage={5}
        renderHeader={() => (
          <div className="card-header">
            <h4 className="card-header-title">Venues</h4>
          </div>
        )}
        resetKey={resetKey}
        renderHeaderRow={() => (
          <thead>
            <tr>
              <th>Name</th>
              <th>Redemptions</th>
              <th />
            </tr>
          </thead>
        )}
        renderRow={venue => (
          <tr key={venue.id}>
            <td>
              <Link to={`/venues/${venue.id}`}>{venue.name}</Link>
              {venue.is_paused && (
                <span className="badge bg-light text-dark mx-3">Paused</span>
              )}
            </td>
            <td>{venue.redemption_count}</td>
            <td>
              <DropdownButton type="ellipses">
                <button
                  className="dropdown-item"
                  onClick={() => setPauseOfferVenue(venue)}
                  type="button"
                >
                  {venue.is_paused ? "Unpause" : "Pause"} venue
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => setRemoveOfferVenue(venue)}
                  type="button"
                >
                  Remove venue
                </button>
              </DropdownButton>
            </td>
          </tr>
        )}
      />
      <RemoveOfferFromVenueModal
        offerId={offerId}
        title={"Remove Venue"}
        description={
          "Please confirm you'd like to remove this venue from this offer."
        }
        onClose={() => setRemoveOfferVenue(null)}
        onRemoved={() => {
          setRemoveOfferVenue(null);
          setResetKey(Date.now());
        }}
        show={Boolean(removeOfferVenue)}
        venueId={removeOfferVenue?.id}
      />
      <UpdateVenueOfferPauseStatusModal
        offerId={offerId}
        onClose={() => setPauseOfferVenue(null)}
        onUpdated={updatedOffer => {
          setPauseOfferVenue(null);
          setResetKey(Date.now());
        }}
        show={Boolean(pauseOfferVenue)}
        venueId={pauseOfferVenue?.id}
        targetPauseStatus={!pauseOfferVenue?.is_paused}
      />
    </>
  );
};

export default OfferVenueListTable;
