import { Tooltip as BsTooltip } from "bootstrap";
import React, { useEffect, useRef } from "react";

const Tooltip = ({ label, children, placement = "top" }) => {
  const childRef = useRef(undefined);

  useEffect(() => {
    const tooltip = new BsTooltip(childRef.current, {
      title: label,
      placement: placement,
      html: true,
    });

    return () => tooltip.dispose();
  });

  return React.cloneElement(children, {
    ref: childRef,
  });
};

export default Tooltip;
