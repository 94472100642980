import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import PageHeader from "shared/components/PageHeader";
import { badgesForOffer } from "./shared";
import BootstrapCard from "shared/components/BootstrapCard";
import styles from "./OfferDetailPage.module.scss";
import OfferPreview from "./detailPage/OfferPreview";
import CopyButton from "shared/components/CopyButton";
import DataCard from "shared/components/DataCard";
import SettingListItemGroup from "shared/components/SettingListItemGroup";
import moment from "moment";
import OfferRedemptionListTable from "./detailPage/OfferRedemptionListTable";
import { renderBadgesForCampaign } from "app/campaigns/shared";
import OfferVenueListTable from "./detailPage/OfferVenueListTable";

const OfferDetailPage = () => {
  const { id: offerId } = useParams();

  const [offer, setOffer] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (offer && offer.id === parseInt(offerId, 10)) {
      return;
    }

    api.get(`/offers/${offerId}`).then(({ data }) => setOffer(data.data));
  }, [offer, offerId]);

  useEffect(() => {
    if (stats) {
      return;
    }

    api.get(`/offers/${offerId}/stats`).then(({ data }) => setStats(data.data));
  });

  if (!(offer && stats)) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`${offer.name} | Offers`}</title>
      </Helmet>
      <PageHeader
        pretitle="offers"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link
              className="btn btn-primary lift"
              to={`/offers/${offerId}/edit`}
            >
              Edit
            </Link>
          </div>
        )}
      >
        {offer.name}
        <span className="h5 ms-2 align-middle">
          {badgesForOffer(offer).map((badge, idx) => (
            <span className={cx(`badge bg-${badge.variant}`, "ms-2")} key={idx}>
              {badge.label}
            </span>
          ))}
        </span>
      </PageHeader>

      <div className="row">
        <div className="col-6 col-md-3">
          <DataCard label="Redemptions (Last 7 Days)">
            {stats.recent_redemption_count.toLocaleString()}
          </DataCard>
        </div>
        <div className="col-6 col-md-3">
          <DataCard label="Total Redemptions">
            {stats.total_redemption_count.toLocaleString()}
          </DataCard>
        </div>
        <div className="col-6 col-md-3">
          <DataCard label="Total Unique">
            {stats.unique_customer_count.toLocaleString()}
          </DataCard>
        </div>
        <div className="col-6 col-md-3">
          <DataCard label="Drink Rating">
            {offer.type === "free_drink"
              ? Math.round(stats.average_drink_rating * 10) / 10
              : "N/A"}
          </DataCard>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8">
          <BootstrapCard title="Offer Details">
            <div className="row">
              {offer.image_url && (
                <div className="col-12 col-md-auto">
                  <img
                    alt={offer.name}
                    className={styles.offerImage}
                    src={offer.image_url.replace(
                      "cdn.drinki.com",
                      "cdn.dusk.app"
                    )}
                  />
                </div>
              )}
              <div className="col mt-4 mt-md-0">
                <h2>{offer.name}</h2>
                {offer.description && <p>{offer.description}</p>}
              </div>
              <div className="col-12 mt-4">
                {offer.ingredients && (
                  <>
                    <h4>Ingredients</h4>
                    <p>{offer.ingredients}</p>
                  </>
                )}
                {offer.redemption_terms && (
                  <>
                    <h4>Redemption Terms</h4>
                    <p>{offer.redemption_terms}</p>
                  </>
                )}
                {offer.redemption_info && (
                  <>
                    <h4>Redemption Info</h4>
                    <p>{offer.redemption_info}</p>
                  </>
                )}
              </div>
              <hr />
              {offer.icon_url && (
                <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                  <OfferPreview offer={offer} />
                </div>
              )}
              <div className="col-12 col-md-6">
                <h5>Offer ID</h5>
                <p className="small mb-0">
                  {offer.id}
                  <CopyButton className="ms-2" text={String(offer.id)} />
                </p>
              </div>
            </div>
          </BootstrapCard>
          <OfferVenueListTable offerId={offerId} />
          <OfferRedemptionListTable offerId={offerId} />
        </div>
        <div className="col-12 col-lg-4">
          {stats.campaigns.map(campaign => (
            <BootstrapCard
              key={campaign.id}
              renderTitle={() => (
                <>
                  <Link to={`/campaigns/${campaign.id}`}>
                    <h4 className="card-header-title">{campaign.name}</h4>
                  </Link>
                  {renderBadgesForCampaign(campaign)}
                </>
              )}
            >
              <div className="list-group list-group-flush my-n3">
                <SettingListItemGroup
                  items={[
                    {
                      title: "Starts",
                      value: campaign.published_from
                        ? moment(campaign.published_from).format(
                            "Do MMM YYYY, h:mma"
                          )
                        : "N/A",
                    },
                    {
                      title: "Expires",
                      value: campaign.published_until
                        ? moment(campaign.published_until).format(
                            "Do MMM YYYY, h:mma"
                          )
                        : "N/A",
                    },
                  ]}
                />
                <SettingListItemGroup
                  items={[
                    {
                      title: "Redemptions",
                      value: campaign.redemption_count.toLocaleString(),
                    },
                    {
                      title: "Drink Limit",
                      value: campaign.total_redemption_limit
                        ? campaign.total_redemption_limit.toLocaleString()
                        : "N/A",
                    },
                  ]}
                />
                <SettingListItemGroup
                  items={[
                    {
                      title: "Offers",
                      value: campaign.offer_count.toLocaleString(),
                    },
                    {
                      title: "Venues",
                      value: campaign.venue_count.toLocaleString(),
                    },
                  ]}
                />
              </div>
            </BootstrapCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferDetailPage;
