import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import constants from "shared/constants";

const StaticMap = ({ location, google }) => {
  return (
    google && (
      <Map
        center={{
          lat: location.latitude,
          lng: location.longitude,
        }}
        containerStyle={{
          borderRadius: 4,
          position: "relative",
          width: "100%",
          height: "300px",
          overflow: "hidden",
        }}
        google={google}
        initialCenter={{
          lat: location.latitude,
          lng: location.longitude,
        }}
        disableDefaultUI={true}
        visible={true}
      >
        <Marker
          position={{
            lat: location.latitude,
            lng: location.longitude,
          }}
        />
      </Map>
    )
  );
};

export default GoogleApiWrapper({ apiKey: constants.googleApiKey })(StaticMap);
