import React from "react";
import CopyButton from "shared/components/CopyButton";
import YetAnotherTable from "shared/components/YetAnotherTable";
import { formatDateTime } from "shared/utilities";

import IconAmex from "assets/images/iconPaymentAmex.svg";
import IconMastercard from "assets/images/iconPaymentMastercard.svg";
import IconVisa from "assets/images/iconPaymentVisa.svg";

const paymentIconLookup = {
  amex: IconAmex,
  mastercard: IconMastercard,
  visa: IconVisa,
};

const CustomerCardListTable = ({ cards }) => (
  <YetAnotherTable
    data={cards}
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Card</th>
          <th>Fidel ID</th>
          <th>Date Added</th>
        </tr>
      </thead>
    )}
    renderRow={card => (
      <tr key={card.id}>
        <td>
          <div className="d-flex align-items-center">
            <img
              alt={card.scheme}
              src={paymentIconLookup[card.scheme]}
              height="20"
            />
            <span className="ms-3">{card.last_numbers}</span>
          </div>
        </td>
        <td>
          {card.id} <CopyButton className="ms-1" text={card.id} />
        </td>
        <td>{formatDateTime(card.created_at)}</td>
      </tr>
    )}
    title="Linked Cards"
  />
);

export default CustomerCardListTable;
