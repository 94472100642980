import moment from "moment";

export const badgesForCategory = category => {
  const isExpired =
    category.display_until &&
    new Date() > moment(category.display_until).toDate();
  const isScheduled =
    category.display_from &&
    new Date() < moment(category.display_from).toDate();
  const isUnpublished = category.is_campaign && !category.display_from;

  return [
    !(isScheduled || isExpired || isUnpublished) && {
      variant: "success",
      label: "Active",
    },
    isScheduled && {
      variant: "secondary",
      label: `Scheduled ${moment(category.display_from).format(
        "YYYY-MM-DD HH:mm"
      )}`,
    },
    isUnpublished && {
      variant: "secondary",
      label: "Unpublished",
    },
    isExpired && {
      variant: "danger",
      label: "Expired",
    },
    category.is_campaign && {
      variant: "warning",
      label: "Campaign",
    },
  ].filter(Boolean);
};
