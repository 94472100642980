import React, { useCallback } from "react";
import AsyncSelect from "react-select/async";
import api from "shared/api";
import SelectVenueOption from "shared/components/SelectVenueOption";

const VenueSelectionVenueSearchSelect = ({ onVenueSelect }) => {
  const loadOptions = useCallback(
    query =>
      new Promise((resolve, reject) => {
        api.get("/venues", { params: { query, count: 20 } }).then(
          ({ data }) =>
            resolve(
              data.data.map(venue => ({
                label: venue.name,
                value: venue.id,
                venue: venue,
              }))
            ),
          reject
        );
      }),
    []
  );

  return (
    <AsyncSelect
      cacheOptions
      components={{ Option: SelectVenueOption }}
      defaultOptions
      onChange={({ venue }) => onVenueSelect(venue)}
      loadOptions={loadOptions}
      value={null}
    />
  );
};

export default VenueSelectionVenueSearchSelect;
