import React from "react";
import CardModal from "shared/components/CardModal";
import LoadingSpinner from "shared/components/LoadingSpinner";

const DeleteModal = ({
  isOpen,
  item = "",
  handleAction,
  handleClose,
  isDeleting,
  error,
  children,
}) => {
  return (
    <CardModal onClose={handleClose} show={isOpen} title={`Delete ${item}`}>
      {() => (
        <>
          <div className="card-body">
            {children ? (
              children
            ) : (
              <p className="my-0">
                You're about to delete this {item}.<br />
                This action cannot be reversed.
              </p>
            )}
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button
              className="btn btn-white"
              onClick={handleClose}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn btn-danger ms-3"
              onClick={handleAction}
              type="button"
            >
              {isDeleting && <LoadingSpinner />}
              {!isDeleting && "Delete"}
            </button>
          </div>
        </>
      )}
    </CardModal>
  );
};

export default DeleteModal;
