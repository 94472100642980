import React from "react";

import cx from "classnames";

const WizardTabs = ({ options, selectedIndex, onTabSelect }) => (
  <div className="card">
    <div className="card-body py-0">
      <ul className="nav nav-tabs nav-tabs-sm">
        {options.map((option, idx) => (
          <li className="nav-item" key={idx}>
            <button
              className={cx("nav-link", selectedIndex === idx && "active")}
              onClick={() => onTabSelect(idx)}
              type="button"
            >
              {option}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default WizardTabs;
