import React from "react";
import { Route, Switch } from "react-router-dom";
import RewardCreatePage from "./reward/RewardCreatePage";
import RewardEditPage from "./reward/RewardEditPage";
import RewardListPage from "./reward/RewardListPage";
import RewardCodeCreatePage from "./rewardCode/RewardCodeCreatePage";

const RewardSection = () => (
  <Switch>
    <Route path="/rewards/create" component={RewardCreatePage} />
    <Route path="/rewards/:id/edit" component={RewardEditPage} />
    <Route path="/rewards/:id/codes" component={RewardCodeCreatePage} />
    <Route component={RewardListPage} />
  </Switch>
);

export default RewardSection;
