import React from "react";
import BootstrapModal from "./BootstrapModal";

const CardModal = ({ children, onClose, show, title }) => (
  <BootstrapModal show={show}>
    <div className="modal-card card">
      <div className="card-header">
        <h4 className="card-header-title">{title}</h4>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
      {children({ dismiss: onClose })}
    </div>
  </BootstrapModal>
);

export default CardModal;
