import React from "react";
import GenericEntityEditPage from "../../shared/components/GenericEntityEditPage";
import CategoryForm from "./createUpdate/CategoryForm";

const RewardCategoryEditPage = () => (
  <GenericEntityEditPage
    apiBasePath="/reward-categories"
    adminBasePath="/reward-categories"
    relevantKeys={["colour", "image_url", "name", "subtitle"]}
    entityName="Reward Category"
    FormComponent={CategoryForm}
  />
);

export default RewardCategoryEditPage;
