import * as Yup from "yup";

const formValidation = {
  email: Yup.string()
    .email("A valid email is required.")
    .required("A valid email is required."),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters long.")
    .required("A password is required."),
  name: Yup.string().required("Name is required."),
  imageURL: Yup.string()
    .url("Needs to be a valid URL.")
    .required("Image is required."),
};
export default formValidation;
