import { useFormikContext } from "formik";
import React, { useState } from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import ImageUploadModal from "shared/components/ImageUploadModal";
import SortableMedia from "shared/components/SortableMedia";
import VideoUploadModal from "shared/components/VideoUploadModal";

const VenueMediaForm = () => {
  // Cover Image
  const [isUploadingCoverImage, setIsUploadingCoverImage] = useState(false);

  // Additional Media
  const [isUploadingMoreImages, setIsUploadingMoreImages] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <BootstrapCard title="Cover image">
            {values.cover_image_url && (
              <img
                alt="Cover"
                className="mb-3 w-100 rounded-3"
                src={values.cover_image_url}
              />
            )}
            <button
              className="btn btn-md btn-primary"
              onClick={() => setIsUploadingCoverImage(true)}
              type="button"
            >
              Upload
            </button>
          </BootstrapCard>
        </div>
        <div className="col-12 col-md-8">
          <BootstrapCard title="Additional media">
            {values.additional_media_urls.length > 0 && (
              <SortableMedia name="additional_media_urls" />
            )}
            <button
              className="btn btn-md btn-primary"
              onClick={() => setIsUploadingMoreImages(true)}
              type="button"
            >
              Upload Images
            </button>
            <button
              className="btn btn-md btn-primary ms-2"
              onClick={() => setIsUploadingVideo(true)}
              type="button"
            >
              Upload Video
            </button>
          </BootstrapCard>
        </div>
      </div>
      {/* For Uploading Cover Image */}
      <ImageUploadModal
        isSingle={true}
        onClose={() => setIsUploadingCoverImage(false)}
        onConfirm={imageUrls => {
          setIsUploadingCoverImage(false);
          setFieldValue("cover_image_url", imageUrls[0]);
        }}
        resize={{ width: 1284, height: 1284 }}
        show={isUploadingCoverImage}
      />
      {/* For Uploading Additional Images */}
      <ImageUploadModal
        onClose={() => setIsUploadingMoreImages(false)}
        onConfirm={imageUrls => {
          setIsUploadingMoreImages(false);
          setFieldValue("additional_media_urls", [
            ...values.additional_media_urls,
            ...imageUrls,
          ]);
        }}
        resize={{ width: 1284, height: 1284 }}
        show={isUploadingMoreImages}
      />
      {/* For Uploading Videos */}
      <VideoUploadModal
        onClose={() => setIsUploadingVideo(false)}
        onConfirm={videoUrl => {
          setIsUploadingVideo(false);
          setFieldValue("additional_media_urls", [
            ...values.additional_media_urls,
            videoUrl,
          ]);
        }}
        show={isUploadingVideo}
      />
    </>
  );
};

export default VenueMediaForm;
