import React from "react";
import cx from "classnames";
import TooltipIcon from "shared/components/TooltipIcon";

const InputField = ({ children, meta, tooltip, isGroup = true, ...props }) => {
  return (
    <div className={cx(isGroup ? "form-group" : undefined, props.className)}>
      <span className="d-flex align-items-center">
        {props.label && (
          <label htmlFor={props.id || props.name}>{props.label}</label>
        )}
        {tooltip && <TooltipIcon label={tooltip} className="ms-1" />}
      </span>
      {children}
      {meta && meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default InputField;
