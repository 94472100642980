import React from "react";

const FormSpinner = () => (
  <div className="row pt-5">
    <div className="col justify-content-center text-center">
      <div className="spinner-border align-self-center" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
);

export default FormSpinner;
