import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import api from "shared/api";
import FormPageHeader from "shared/components/FormPageHeader";
import FormSpinner from "shared/components/FormSpinner";
import OfferForm from "./createEditPages/OfferForm";

const OfferEditPage = () => {
  const { id: offerId } = useParams();

  const [offer, setOffer] = useState(null);

  useEffect(() => {
    if (offer && offer.id === parseInt(offerId, 10)) {
      return;
    }

    api.get(`/offers/${offerId}`).then(({ data }) => setOffer(data.data));
  }, [offer, offerId]);

  if (!offer) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Edit Offer | ${offer.name}`}</title>
      </Helmet>
      <OfferForm
        offer={offer}
        renderTitleComponent={({ isSubmitting }) => (
          <FormPageHeader isSubmitting={isSubmitting} pretitle="offers">
            Edit {offer.name}
          </FormPageHeader>
        )}
      />
    </div>
  );
};

export default OfferEditPage;
