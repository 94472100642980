import React, { useCallback, useState } from "react";

import { Map, Marker } from "google-maps-react";
import { useField } from "formik";

const MapField = ({ className, label, ...props }) => {
  const [field, , { setValue }] = useField(props.name);
  const [enableRelocate, setEnableRelocate] = useState(false);
  const fieldId = props.id || props.name;

  const onMapClick = useCallback(
    (_mapProps, _map, clickEvent) => {
      if (!enableRelocate) {
        return;
      }

      setValue({
        latitude: clickEvent.latLng.lat(),
        longitude: clickEvent.latLng.lng(),
      });
    },
    [enableRelocate, setValue]
  );

  return (
    <div className={className}>
      <label className="form-label" htmlFor={fieldId}>
        {label}
      </label>
      <label className="d-flex small mt-0 mb-3" htmlFor="enableRelocate">
        <input
          className="me-2"
          id="enableRelocate"
          type="checkbox"
          checked={enableRelocate}
          onChange={e => setEnableRelocate(e.target.checked)}
        />
        Enable changing location
      </label>

      {window.google && (
        <Map
          center={{
            lat: field.value?.latitude || 51.5072,
            lng: field.value?.longitude || -0.1276,
          }}
          containerStyle={{
            borderRadius: 4,
            position: "relative",
            width: "100%",
            height: "300px",
            overflow: "hidden",
          }}
          google={window.google}
          initialCenter={{
            lat: field.value?.latitude || 51.5072,
            lng: field.value?.longitude || -0.1276,
          }}
          onClick={onMapClick}
          disableDefaultUI={true}
          visible={true}
        >
          {field.value?.latitude && (
            <Marker
              name="Venue Location"
              position={{
                lat: field.value?.latitude,
                lng: field.value?.longitude,
              }}
            />
          )}
        </Map>
      )}
    </div>
  );
};

export default MapField;
