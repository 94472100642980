import { handleActions } from "redux-actions";
import {
  LOGIN_SUCCESS,
  VERIFY_SESSION,
  VERIFY_SESSION_FAILURE,
  LOGOUT,
  UPDATE_PROFILE,
} from "./actionTypes";

const initialState = {
  isLoggedIn: false,
  isVerifyingSession: false,
  idToken: null,
  user: null,
};

export default handleActions(
  {
    [LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      isLoggedIn: true,
      isVerifyingSession: false,
      idToken: action.payload.session.id_token,
      user: action.payload.user,
    }),
    [VERIFY_SESSION]: state => ({
      ...state,
      isVerifyingSession: true,
    }),
    [VERIFY_SESSION_FAILURE]: state => ({
      ...state,
      isVerifyingSession: false,
    }),
    [UPDATE_PROFILE]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
