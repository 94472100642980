import React from "react";
import cx from "classnames";

import styles from "./VenueOfferRow.module.scss";
import moment from "moment";
import DropdownButton from "shared/components/DropdownButton";
import TableImagePlacheolder from "shared/components/TableImagePlaceholder";
import { badgesForOffer } from "app/offers/shared";

const VenueOfferRow = ({
  offer,
  onDeleteClick,
  onUpdateAvailabilityClick,
  onUpdatePauseStatusClick,
}) => (
  <div className="list-group-item">
    <div className="row align-items-center">
      <div className="col-auto">
        {offer.image_url ? (
          <img
            alt={offer.name}
            className={cx("bg-light", styles.venueOfferImage)}
            src={offer.image_url}
          />
        ) : (
          <TableImagePlacheolder />
        )}
      </div>

      <div className="col">
        {/* #Content */}
        <div className="d-flex">
          <h4 className="my-0">{offer.name}</h4>

          {/* Badges */}
          <div className="d-flex">
            {badgesForOffer(offer).map((badge, idx) => (
              <span
                className={cx(`badge bg-${badge.variant}`, "ms-2")}
                key={idx}
              >
                {badge.label}
              </span>
            ))}
          </div>
        </div>
        <small className="text-muted">
          {[
            offer.campaign?.name,
            `Created ${moment(offer.created_at).format("DD/MM/YYYY")}`,
            offer.available_from &&
              moment(offer.available_from).toDate() > new Date() &&
              `Scheduled for ${moment(offer.available_from).format(
                "hh:mma, Do MMM YYYY"
              )}`,
            offer.available_until &&
              moment(offer.available_until).toDate() > new Date() &&
              `Expires ${moment(offer.available_until).format(
                "hh:mma, Do MMM YYYY"
              )}`,
          ]
            .filter(Boolean)
            .join(" • ")}
        </small>
      </div>

      <div className="col-auto">
        <div className="dropdown">
          <DropdownButton type="ellipses">
            <button
              className="dropdown-item"
              onClick={onUpdateAvailabilityClick}
              type="button"
            >
              Update Overrides
            </button>
            <button
              className="dropdown-item"
              onClick={onDeleteClick}
              type="button"
            >
              Remove Offer
            </button>
            <button
              className="dropdown-item"
              onClick={onUpdatePauseStatusClick}
              type="button"
            >
              {offer.is_paused ? "Resume" : "Pause"} Offer
            </button>
          </DropdownButton>
        </div>
      </div>
    </div>
  </div>
);

export default VenueOfferRow;
