import React, { useState } from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";
import SettingListItem from "shared/components/SettingListItem";

const ResetOfferRedemptionLimitActionItem = ({ isLoading, onClick }) => {
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);
  return (
    <SettingListItem
      icon="unlock"
      title="Reset Offer Redemption Limit"
      subtitle="Reset the user's offer redemption limit for the day."
    >
      <button
        className="btn btn-sm btn-white"
        onClick={() => {
          onClick().then(() => {
            setShowSuccessIcon(true);
            setTimeout(() => {
              setShowSuccessIcon(false);
            }, 1000);
          });
        }}
      >
        {isLoading && <LoadingSpinner className="mt-1" />}
        {!isLoading &&
          (showSuccessIcon ? <span className="fe fe-check" /> : "Reset")}
      </button>
    </SettingListItem>
  );
};

export default ResetOfferRedemptionLimitActionItem;
