import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../shared/components/PageHeader";
import { Form, Formik } from "formik";
import Textbox from "../../shared/components/Textbox";
import FormSubmitButton from "../../shared/components/FormSubmitButton";
import InputField from "../../shared/components/InputField";
import api from "../../shared/api";
import { updateProfile } from "../auth/actions";
import { addToServiceFeedback } from "../serviceFeedback/actions";
import { extractAPIErrorStringNoCB } from "../../shared/utils/extractErrors";

const UserProfilePage = () => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  function onFormSubmit({ first_name, last_name, email }) {
    return new Promise(async resolve => {
      api.put(`/users/${user.id}`, { first_name, last_name, email }).then(
        ({ data }) => {
          dispatch(
            addToServiceFeedback({
              type: "success",
              message: "Your profile has been successfully updated",
            })
          );
          dispatch(updateProfile(data.data));
          resolve();
        },
        err => {
          dispatch(
            addToServiceFeedback({
              type: "warning",
              message: extractAPIErrorStringNoCB(err),
            })
          );
          resolve();
        }
      );
    });
  }

  return (
    <div>
      {user && (
        <div>
          <PageHeader pretitle="Your profile">
            <div className="row">
              <div className="col col-md-6">
                {user.first_name} {user.last_name}
              </div>
              <div className="col col-md-6">
                <InputField>
                  <input
                    className="form-control"
                    type="text"
                    label="Email"
                    name="email"
                    value={user.email}
                    disabled
                  />
                </InputField>
              </div>
            </div>
          </PageHeader>
          <Formik initialValues={user} onSubmit={onFormSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <div className="row">
                  <div className="col col-md-6">
                    <Textbox label="First name" name="first_name" />
                  </div>
                  <div className="col col-md-6">
                    <Textbox label="Last name" name="last_name" />
                  </div>
                </div>
                <hr className="my-5" />
                <div className="row pb-5">
                  <div className="col text-md-right">
                    <FormSubmitButton isSubmitting={isSubmitting}>
                      Update Profile
                    </FormSubmitButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default UserProfilePage;
