import React from "react";
import { Route, Switch } from "react-router-dom";
import RewardCategoryCreatePage from "./rewardCategory/RewardCategoryCreatePage";
import RewardCategoryEditPage from "./rewardCategory/RewardCategoryEditPage";
import RewardCategoryListPage from "./rewardCategory/RewardCategoryListPage";

const RewardCategorySection = () => (
  <Switch>
    <Route
      path="/reward-categories/create"
      component={RewardCategoryCreatePage}
    />
    <Route
      path="/reward-categories/:id/edit"
      component={RewardCategoryEditPage}
    />
    <Route component={RewardCategoryListPage} />
  </Switch>
);

export default RewardCategorySection;
