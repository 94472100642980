import React from "react";
import { Route, Switch } from "react-router-dom";
import GuideCreatePage from "./guides/GuideCreatePage";
import GuideEditPage from "./guides/GuideEditPage";
import GuideListPage from "./guides/GuideListPage";

const GuidesSection = () => {
  return (
    <Switch>
      <Route path="/guides/create" component={GuideCreatePage} />
      <Route path="/guides/:id/edit" component={GuideEditPage} />
      <Route component={GuideListPage} />
    </Switch>
  );
};

export default GuidesSection;
