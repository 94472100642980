import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import api from "../../shared/api";
import FormSubmitButton from "../../shared/components/FormSubmitButton";
import FormWrapper from "../../shared/components/FormWrapper";
import PageHeader from "../../shared/components/PageHeader";
import Textbox from "../../shared/components/Textbox";
import formValidation from "../../shared/formValidation";

import { addToServiceFeedback } from "../serviceFeedback/actions";
import { extractAPIErrorString } from "../../shared/utils/extractErrors";
import { useDispatch } from "react-redux";

const AdminUserInvitePage = () => {
  const [redirect, setRedirect] = useState(null);
  const dispatch = useDispatch();

  const onFormSubmit = ({ email }, { setSubmitting }) => {
    setSubmitting(true);
    api
      .post("/users", { email })
      .then(
        () => {
          setSubmitting(false);
          setRedirect(true);
        },
        extractAPIErrorString(error => {
          dispatch(
            addToServiceFeedback({
              type: "warning",
              message: error,
            })
          );
        })
      )
      .finally(() => setSubmitting(false));
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/admin-users",
          state: {
            justInvited: true,
          },
        }}
      />
    );
  }

  return (
    <FormWrapper>
      <PageHeader pretitle="dusk admin">Invite an Admin</PageHeader>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={onFormSubmit}
        validationSchema={Yup.object().shape({
          email: formValidation.email,
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <Textbox
              label="New Admin's Email"
              placeholder="jane@dusk.app"
              name="email"
            />
            <hr className="my-5" />
            <div className="row pb-5">
              <div className="col text-md-right">
                <FormSubmitButton icon="mail" isSubmitting={isSubmitting}>
                  Send Invite
                </FormSubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default AdminUserInvitePage;
