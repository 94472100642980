import React, { Fragment } from "react";
import { useField } from "formik";
import TextField from "shared/components/formFields/TextField";
import MultiSelectTextField from "shared/components/formFields/MultiSelectTextField";

const MultiValueLabel = props => (
  <div
    className={props.innerProps.className}
    key={props.data.id || props.data.name}
  >
    {props.data.name}
  </div>
);

const CityAreasField = ({ name }) => {
  const [field, , { setValue }] = useField(name);
  return (
    <div>
      {(field.value || []).length === 0 && (
        <p className="text-muted my-0">
          Add city areas using the button above.
        </p>
      )}
      {(field.value || [])
        .filter(({ removed }) => !removed)
        .map((cityArea, idx) => (
          <Fragment key={cityArea.id || idx}>
            {idx > 0 && <hr />}
            <div>
              <div className="d-flex flex-row align-items-center justify-content-between">
                {cityArea.id ? (
                  <p className="mb-0">{cityArea.name}</p>
                ) : (
                  <TextField
                    inputClassName="form-control-sm"
                    name={`${name}.${field.value.indexOf(cityArea)}.name`}
                    placeholder="Area Name"
                  />
                )}

                <button
                  className="btn btn-sm btn-white"
                  onClick={() =>
                    setValue(
                      field.value.map(innerCityArea =>
                        innerCityArea !== cityArea
                          ? innerCityArea
                          : { ...innerCityArea, removed: true }
                      )
                    )
                  }
                  type="button"
                >
                  <span className="fe fe-trash-2" />
                </button>
              </div>
              <MultiSelectTextField
                className="mt-3"
                createOption={val => ({
                  label: val,
                  name: val,
                  new: true,
                  value: val,
                })}
                components={{ MultiValueLabel }}
                name={`${name}.${field.value.indexOf(cityArea)}.children`}
                placeholder="Begin typing sub areas, Enter to create."
              />
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default CityAreasField;
