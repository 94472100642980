import React, { useState } from "react";

import FormSubmitButton from "../../shared/components/FormSubmitButton";
import { Form, Formik } from "formik";
import formValidation from "../../shared/formValidation";
import Textbox from "../../shared/components/Textbox";
import { useLocation } from "react-router-dom";

import * as Yup from "yup";
import api from "../../shared/api";
import { useDispatch } from "react-redux";
import { completeLogin } from "./actions";
import qs from "qs";

const ResetPassword = () => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { email, code } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  });

  const onFormSubmit = (formData, { setSubmitting }) => {
    setSubmitting(true);
    setError(false);

    api
      .post("/auth/reset-password", {
        email: formData.email,
        code: formData.code,
        password: formData.password,
      })
      .then(({ data }) => {
        setSubmitting(false);
        dispatch(completeLogin(data.data));
      })
      .catch(err => {
        setSubmitting(false);
        if (err && err.response) {
          setError(err.response.data.error);
        } else {
          setError("There was an issue");
        }
        console.log(err, err.response);
      });
  };

  return (
    <Formik
      initialValues={{
        email: email || "",
        code: code || "",
        password: "",
      }}
      onSubmit={onFormSubmit}
      validationSchema={Yup.object().shape({
        email: formValidation.email,
        code: Yup.string().required("Verification code is required."),
        password: formValidation.password,
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <Textbox label="Email" name="email" placeholder="jane@dusk.app" />
          <Textbox
            label="Verification code"
            name="code"
            placeholder="••••••••"
            type="password"
          />
          <Textbox
            label="New password"
            name="password"
            placeholder="••••••••"
            type="password"
          />
          <FormSubmitButton
            className="btn-block"
            disabled={isSubmitting}
            type="submit"
            buttonType="outline-dark"
          >
            Reset Password
          </FormSubmitButton>
          {error && <label className="my-3 text-danger">{error}</label>}
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
