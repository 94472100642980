import React, { useMemo } from "react";

const LocationBlock = ({ venue }) => {
  const data = useMemo(() => {
    return [
      [
        { label: "Address", value: venue.address?.line1 },
        { label: "", value: venue.address?.line2 },
        { label: "Postcode", value: venue.address?.postcode },
        { label: "Phone", value: venue.phone },
      ],
      [
        { label: "City", value: venue.city?.name },
        {
          label: "Area",
          value: venue.city_areas?.map(area => area.name).join(", "),
        },
      ],
      [
        {
          label: "Station",
          value: venue.stations?.map(station => station.name).join(", "),
        },
      ],
    ].map(group => group.filter(item => Boolean(item.value)));
  }, [venue]);

  const formatPhoneNumber = number => {
    return number.startsWith("+44") ? number : "+44" + number.slice(1);
  };

  return (
    <div className="list-group list-group-flush pt-2">
      {data
        .filter(group => group.length)
        .map((group, index) => (
          <div className="list-group-item py-2" key={index}>
            {group.map((item, itemIndex) => (
              <div className="row align-items-center py-1" key={itemIndex}>
                <div className="col">
                  <h5 className="my-0">{item.label}</h5>
                </div>
                {item.label === "Phone" ? (
                  <>
                    <a
                      className="d-sm-none col-auto"
                      href={`tel:${formatPhoneNumber(item.value)}`}
                    >
                      {item.value}
                    </a>
                    <div className="d-none d-sm-block col-auto">
                      {item.value}
                    </div>
                  </>
                ) : (
                  <div className="col-auto">{item.value}</div>
                )}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default LocationBlock;
