import React from "react";

const ListLabelPreview = ({ label }) => (
  <div className="p-2 px-3 rounded" style={{ backgroundColor: "black" }}>
    <p className="small my-0" style={{ color: label.tint_color }}>
      <img
        alt={label.text}
        className="me-2 mb-1"
        src={label.icon_url}
        style={{ width: "20px", height: "20px" }}
      />
      {label.text}
    </p>
  </div>
);

export default ListLabelPreview;
