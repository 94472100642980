import React, { useState } from "react";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";
import api from "../../shared/api";
import FormWrapper from "../../shared/components/FormWrapper";
import PageHeader from "../../shared/components/PageHeader";
import RewardForm from "./createUpdate/RewardForm";
import { extractAPIErrorString } from "shared/utils/extractErrors";

const RewardCreatePage = () => {
  const [redirect, setRedirect] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const onFormSubmit = (formData, { setSubmitting }) => {
    setSubmitting(true);

    const apiData = {};

    // Get rid of empty strings etc
    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        apiData[key] = formData[key];
      }
    });
    api.post("/rewards", apiData).then(
      () => {
        setSubmitting(false);
        setRedirect(true);
      },
      extractAPIErrorString(err => {
        setErrorText(err);
        setSubmitting(false);
      })
    );
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/rewards",
        }}
      />
    );
  }

  return (
    <FormWrapper>
      <Helmet>
        <title>Create Reward</title>
      </Helmet>
      <PageHeader pretitle="dusk admin">Add a Reward</PageHeader>
      <RewardForm onFormSubmit={onFormSubmit} />
      {errorText && <p className="text-danger mt-2">{errorText}</p>}
    </FormWrapper>
  );
};

export default RewardCreatePage;
