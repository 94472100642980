import React, { useState } from "react";
import ImageUploadModal from "shared/components/ImageUploadModal";
import cx from "classnames";
import { useField } from "formik";

const ImageUploadField = ({
  name,
  label,
  alt,
  resize,
  rounded = false,
  imageRatio,
  imageStyle,
  imageContainerClass,
}) => {
  const [field, meta, { setValue }] = useField(name);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  return (
    <div className="form-group">
      <label className="form-label d-block">{label}</label>
      {field.value && (
        <div
          className={cx(
            "mb-3",
            imageRatio && `ratio ratio-${imageRatio}`,
            imageContainerClass
          )}
        >
          <img
            alt={alt}
            className={cx(rounded && "rounded-3")}
            src={field.value}
            style={{ objectFit: "cover", ...imageStyle }}
          />
        </div>
      )}
      <button
        className={cx(
          "btn btn-sm btn-primary is-invalid",
          meta && meta.touched && meta.error && "is-invalid"
        )}
        onClick={() => setIsUploadingImage(true)}
        type="button"
      >
        Upload
      </button>
      {meta && meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
      <ImageUploadModal
        isSingle={true}
        onClose={() => setIsUploadingImage(false)}
        onConfirm={imageUrls => {
          setIsUploadingImage(false);
          setValue(imageUrls[0]);
        }}
        resize={resize}
        show={isUploadingImage}
      />
    </div>
  );
};

export default ImageUploadField;
