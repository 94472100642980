import React from "react";

const OpeningHoursCardRow = ({ days, hours }) => (
  <div className="row align-items-center py-1">
    <div className="col">
      <h5 className="my-0">{days}</h5>
    </div>
    <div className="col-auto">{hours}</div>
  </div>
);

export default OpeningHoursCardRow;
