import React, { useContext } from "react";
import cx from "classnames";
import { WizardContext } from "./index";

const FormWizardHeader = ({ children, isInCard }) => {
  const className = cx("nav nav-tabs", {
    "nav-overflow": !isInCard,
    "header-tabs": !isInCard,
    "nav-tabs-sm": isInCard,
    "card-header-tabs": isInCard,
  });
  return <ul className={className}>{children}</ul>;
};

const FormWizardTab = ({ step = 0, children }) => {
  const { goTo, current, completion, disabled } = useContext(WizardContext);
  const handleStep = () => goTo(step);
  const className = cx(
    {
      active: step === current,
      disabled: disabled[step],
    },
    "nav-link"
  );

  return (
    <li
      className="nav-item"
      onClick={handleStep}
      style={{
        cursor: !disabled[step] ? "pointer" : "not-allowed",
      }}
    >
      <span className={className}>
        {completion[step] ? (
          <i className="fe fe-check me-2" />
        ) : (
          <i className="fe fe-edit-3 me-2" />
        )}
        {children}
      </span>
    </li>
  );
};

export { FormWizardTab };

export default FormWizardHeader;
