import React, { useCallback, useMemo } from "react";
import { useField } from "formik";
import CampaignVenueSetField from "./CampaignVenueSetField";

const CampaignVenueSetForm = ({
  offers,
  chains,
  collections,
  venueLookup,
  onAddVenues,
}) => {
  const [field, , { setValue }] = useField("venue_sets");

  const getScroll = addingSet => {
    let pos = document.body.getBoundingClientRect().top * -1;
    if (addingSet) {
      pos += 493; // The height of a new set card and margin
    }
    return pos;
  };

  const venueSetDefaultName = useMemo(() => {
    const setNumberList = field.value
      .map(
        set =>
          set.name.match(/Set [0-9]+/g) &&
          parseInt(set.name.replace(/\D+/g, ""), 10)
      )
      .sort();
    return `Set ${setNumberList.length ? setNumberList.at(-1) + 1 : 1}`;
  }, [field]);

  const handleDelete = useCallback(
    async idx => {
      const scroll = getScroll();
      field.value.splice(idx, 1);
      await setValue(field.value);
      window.scrollTo(0, scroll);
    },
    [field, setValue]
  );

  const handleAdd = useCallback(async () => {
    const scroll = getScroll(true);
    await setValue([
      ...field.value,
      {
        name: venueSetDefaultName,
        venue_ids: [],
        offer_ids: [],
      },
    ]);
    window.scrollTo(0, scroll);
  }, [field, setValue, venueSetDefaultName]);

  return (
    <>
      {field.value.map((set, idx) => {
        return (
          <CampaignVenueSetField
            key={idx}
            name={`venue_sets.${idx}`}
            offers={offers}
            chains={chains}
            collections={collections}
            venueLookup={venueLookup}
            onDeleteSet={() => handleDelete(idx)}
            onAddVenues={onAddVenues}
          />
        );
      })}
      <button
        className="btn btn-md btn-outline-primary mb-4 w-100"
        onClick={handleAdd}
        type="button"
      >
        + <br /> New Set
      </button>
    </>
  );
};

export default CampaignVenueSetForm;
