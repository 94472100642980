import { useField } from "formik";
import React, { useMemo } from "react";
import cx from "classnames";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const MultipleSelectField = ({
  className,
  label,
  options,
  isCreatable,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props.name);

  const selectOptions = useMemo(
    () => options.map(({ id, name }) => ({ value: String(id), label: name })),
    [options]
  );

  const optionLookup = useMemo(
    () =>
      (selectOptions || []).reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [selectOptions]
  );

  const fieldValue = useMemo(() => {
    if (["string", "number"].indexOf(typeof field.value) !== -1) {
      return [optionLookup[String(field.value)] || ""].filter(Boolean);
    }

    if (Array.isArray(field.value)) {
      if (isCreatable) {
        return [
          ...new Set(
            field.value.map(
              val => optionLookup[String(val)] || { value: val, label: val }
            )
          ),
        ];
      } else {
        return field.value
          .map(val => optionLookup[String(val)] || "")
          .filter(Boolean);
      }
    }

    return [];
  }, [field.value, optionLookup, isCreatable]);

  const fieldId = props.id || props.name;

  return (
    <div className={className}>
      <label className="form-label" htmlFor={fieldId}>
        {label}
      </label>
      {isCreatable ? (
        <CreatableSelect
          className={cx(meta?.touched && meta?.error && "is-invalid")}
          isMulti
          options={selectOptions}
          {...field}
          {...props}
          onChange={values =>
            setValue((values || []).map(({ value }) => value))
          }
          value={fieldValue}
        />
      ) : (
        <Select
          className={cx(meta?.touched && meta?.error && "is-invalid")}
          isMulti
          options={selectOptions}
          {...field}
          {...props}
          onChange={values =>
            setValue((values || []).map(({ value }) => value))
          }
          value={fieldValue}
        />
      )}
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default MultipleSelectField;
