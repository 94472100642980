import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormSubmitButton from "../../../shared/components/FormSubmitButton";
import ImageDrop from "../../../shared/components/ImageDrop";
import Textbox from "../../../shared/components/Textbox";
import DeleteButton from "../../../shared/components/DeleteButton";

const RewardCollectionForm = ({
  onFormSubmit,
  initialValues = {
    title: "",
    subtitle: "",
    icon_url: "",
  },
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Title is required."),
        subtitle: Yup.string().required("Subtitle is required"),
      })}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Textbox
            label="Collection title"
            placeholder="Best Picks"
            name="title"
          />
          <Textbox
            label="Collection subtitle"
            placeholder="We compiled some picks we feel are best..."
            name="subtitle"
          />
          <ImageDrop
            label="Collection icon"
            name="icon_url"
            imageType="reward_collection"
          />
          <hr className="my-5" />
          <div className="row pb-5">
            <div className="col">
              {values.id && (
                <DeleteButton endpoint="reward-collections" id={values.id}>
                  Collection
                </DeleteButton>
              )}
              <FormSubmitButton isSubmitting={isSubmitting}>
                {values.id ? "Save" : "Create"}
              </FormSubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RewardCollectionForm;
