import React from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import MultipleSelectField from "shared/components/formFields/MultipleSelectField";
import PriceLevelField from "shared/components/formFields/PriceLevelField";
import TextField from "shared/components/formFields/TextField";
import TextAreaField from "./TextAreaField";

const VenueEditorialForm = ({ categories }) => (
  <BootstrapCard title="Editorial">
    <div className="row">
      <div className="col-12">
        <TextAreaField
          className="form-group"
          label="Description"
          name="description"
          tooltip="This description supports markdown formatting. <a href='https://www.notion.so/duskapp/Markdown-Text-formatting-in-app-a199beef80c04ef795efadcef2220585' target='_blank'>Read documentation here.</a>"
          autogrow
        />
      </div>
      <div className="col-12">
        <MultipleSelectField
          className="form-group"
          label="Categories"
          name="category_ids"
          options={categories}
        />
      </div>
      <div className="col-12">
        <PriceLevelField
          className="form-group"
          label="Price Level"
          name="price_level"
        />
      </div>
      <div className="col-12">
        <TextField
          className="form-group"
          label="Fidel Location ID"
          name="fidel_location_id"
        />
      </div>
    </div>
  </BootstrapCard>
);

export default VenueEditorialForm;
