import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import VenueForm from "./createEditPages/VenueForm";
import Helmet from "react-helmet";
import FormPageHeader from "shared/components/FormPageHeader";

const VenueEditPage = () => {
  const { id: venueId } = useParams();

  const [venue, setVenue] = useState(null);

  useEffect(() => {
    if (venue && venue.id === parseInt(venueId, 10)) {
      return;
    }

    api.get(`/venues/${venueId}`).then(({ data }) => setVenue(data.data));
  }, [venue, venueId]);

  if (!venue) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Edit Venue | ${venue.name}`}</title>
      </Helmet>

      <VenueForm
        initialVenue={venue}
        renderTitleComponent={({ isSubmitting }) => (
          <FormPageHeader isSubmitting={isSubmitting} pretitle="venues">
            Edit {venue.name}
          </FormPageHeader>
        )}
      />
    </div>
  );
};

export default VenueEditPage;
