import axios from "axios";
import cx from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import api from "shared/api";
import CardModal from "./CardModal";
import Loading from "./Loading";
import RemovableMediaPreview from "./RemovableMediaPreview";
import styles from "./ImageUploadModal.module.scss";
import LoadingSpinner from "./LoadingSpinner";

const VideoUploadModal = ({ onClose, onConfirm, show }) => {
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [assetUrl, setAssetUrl] = useState(null);

  useEffect(() => {
    if (presignedUrl) {
      return;
    }
    api.get("/media/presigned-url").then(({ data }) => {
      setPresignedUrl(data.data.upload_url);
      setAssetUrl(data.data.asset_url);
    });
  }, [presignedUrl]);

  const onDrop = useCallback(
    acceptedFiles => {
      const videoFile = acceptedFiles[0];

      setIsUploading(true);

      axios
        .put(presignedUrl, videoFile, {
          headers: {
            "Content-Type": "video/mp4",
          },
        })
        .then(() => {
          setUploadedVideo(assetUrl);
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [assetUrl, presignedUrl]
  );

  return (
    <CardModal onClose={onClose} show={show} title="Upload Video">
      {() =>
        presignedUrl === null ? (
          <Loading />
        ) : (
          <>
            <div className="card-body">
              {(isUploading || uploadedVideo) && (
                <div className="row">
                  <div className="col-4 col-md-3 col-lg-2">
                    {uploadedVideo ? (
                      <RemovableMediaPreview
                        imageUrl={uploadedVideo}
                        onRemove={() => {
                          setUploadedVideo(null);
                        }}
                      />
                    ) : (
                      <div
                        className={cx(
                          "border border-light mb-3",
                          styles.loadingSquare
                        )}
                      >
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <Dropzone
                accept="video/mp4"
                maxFiles={1}
                multiple={false}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      className="dropzone dropzone-single dz-clickable"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="dz-default dz-message">
                        <button className="dz-button" type="button">
                          Drop image here, or click for file selector.
                        </button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="card-footer d-flex justify-content-end">
              <button className="btn btn-white" onClick={onClose} type="button">
                Cancel
              </button>
              <button
                className="btn btn-primary ms-3"
                disabled={uploadedVideo === null}
                onClick={() => {
                  onConfirm(uploadedVideo);
                  setPresignedUrl(null);
                  setAssetUrl(false);
                  setUploadedVideo(null);
                  setIsUploading(false);
                }}
                type="button"
              >
                Confirm
              </button>
            </div>
          </>
        )
      }
    </CardModal>
  );
};

export default VideoUploadModal;
