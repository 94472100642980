import { createActions } from "redux-actions";
import api from "../../shared/api";
import {
  LOGIN_SUCCESS,
  VERIFY_SESSION,
  VERIFY_SESSION_FAILURE,
  LOGOUT,
  UPDATE_PROFILE,
} from "./actionTypes";

const {
  loginSuccess,
  verifySession,
  verifySessionFailure,
  logout,
  updateProfile,
} = createActions(
  LOGIN_SUCCESS,
  VERIFY_SESSION,
  VERIFY_SESSION_FAILURE,
  LOGOUT,
  UPDATE_PROFILE
);

const completeLogin =
  ({ session, user }) =>
  dispatch => {
    localStorage.setItem("REF_TOKEN", session.refresh_token);
    dispatch(loginSuccess({ session, user }));
  };

const refreshSession = refreshToken => dispatch => {
  dispatch(verifySession());

  api
    .post("/auth/verify-session", { refresh_token: refreshToken })
    .then(({ data }) => {
      dispatch(completeLogin(data.data));
    })
    .catch(() => {
      localStorage.removeItem("REF_TOKEN");
      dispatch(verifySessionFailure());
    });
};

const logoutUser = () => dispatch => {
  localStorage.clear();
  dispatch(logout({}));
};

export {
  completeLogin,
  loginSuccess,
  refreshSession,
  logoutUser,
  updateProfile,
};
