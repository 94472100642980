import React, { useEffect, useState } from "react";

import TextAreaField from "app/venues/createEditPages/TextAreaField";
import { Form, Formik } from "formik";
import BootstrapCard from "shared/components/BootstrapCard";
import TextField from "shared/components/formFields/TextField";
import VenueSelectionCard from "shared/components/VenueSelectionCard";
import FormSpinner from "shared/components/FormSpinner";
import api from "shared/api";
import { Link, Redirect } from "react-router-dom";
import LoadingSpinner from "shared/components/LoadingSpinner";

import * as Yup from "yup";
import YetAnotherTable from "shared/components/YetAnotherTable";
import { badgesForVenue } from "app/venues/shared";
import cx from "classnames";
import moment from "moment";

export default function VenueCollectionForm({ initialCollection }) {
  const [chains, setChains] = useState(null);
  const [collections, setCollections] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [venueLookup, setVenueLookup] = useState({});

  // Load venues from the initial collection
  useEffect(() => {
    if (initialCollection?.venues?.length) {
      api
        .get("/venues", {
          params: {
            venue_ids: initialCollection.venues.map(({ id }) => id).join(","),
          },
          count: 1000,
        })
        .then(({ data }) => {
          setVenueLookup(lookup => ({
            ...lookup,
            ...data.data.reduce((acc, venue) => {
              acc[venue.id] = venue;
              return acc;
            }, {}),
          }));
        });
    }
  }, [initialCollection]);

  // Load Chains
  useEffect(() => {
    if (chains) {
      return;
    }

    api
      .get("/venue-chains", { params: { count: 250 } })
      .then(({ data }) => setChains(data.data));
  }, [chains]);

  // Load Collections
  useEffect(() => {
    if (collections) {
      return;
    }

    api
      .get("/venue-collections", { params: { count: 250 } })
      .then(({ data }) => setCollections(data.data));
  }, [collections]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (!(chains && collections)) {
    return <FormSpinner />;
  }

  return (
    <Formik
      initialValues={{
        name: initialCollection?.name || "",
        description: initialCollection?.description || "",
        slug: initialCollection?.slug || "",
        venue_ids: initialCollection?.venues?.map(({ id }) => id) || [],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string(),
        slug: Yup.string(),
        venue_ids: Yup.array(Yup.number()).required(),
      })}
      onSubmit={collection =>
        new Promise(resolve => {
          const req = initialCollection?.id
            ? api.put(`/venue-collections/${initialCollection.id}`, collection)
            : api.post("/venue-collections", collection);

          req
            .then(() => {
              setRedirect("/venue-collections");
            })
            .finally(resolve);
        })
      }
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <BootstrapCard title="Editorial">
            <div className="row">
              <div className="col-12 col-md-6">
                <TextField className="form-group" label="Name" name="name" />
              </div>
              <div className="col-12 col-md-6">
                <TextField
                  className="form-group"
                  label="Slug"
                  name="slug"
                  placeholder="Leave blank to autogenerate"
                />
              </div>
              <div className="col-12">
                <TextAreaField
                  className="form-group"
                  label="Description"
                  name="description"
                />
              </div>
            </div>
          </BootstrapCard>
          <VenueSelectionCard
            chains={chains}
            collections={collections}
            onVenuesSelected={venues => {
              setVenueLookup({
                ...venueLookup,
                ...venues.reduce((acc, venue) => {
                  acc[venue.id] = venue;
                  return acc;
                }, {}),
              });

              setFieldValue("venue_ids", [
                ...new Set([
                  ...values.venue_ids,
                  ...venues.map(({ id }) => id),
                ]),
              ]);
            }}
          />

          <YetAnotherTable
            data={values.venue_ids.map(id => venueLookup[id]).filter(Boolean)}
            renderHeaderRow={() => (
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Areas</th>
                  <th>City</th>
                  <th>Offers</th>
                  <th>Updated</th>
                  <th />
                </tr>
              </thead>
            )}
            renderRow={venue => (
              <tr key={venue.id}>
                <td>
                  {venue.name ? (
                    <Link to={`/venues/${venue.id}`}>{venue.name}</Link>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  {badgesForVenue(venue).map((badge, idx) => (
                    <span
                      className={cx(
                        `badge bg-${badge.variant}`,
                        idx > 0 && "ms-2"
                      )}
                      key={idx}
                    >
                      {badge.label}
                    </span>
                  ))}
                </td>
                <td>{venue.city_areas.map(({ name }) => name).join(", ")}</td>
                <td>{venue.city?.name || "N/A"}</td>
                <td>
                  {[
                    {
                      count: venue.offers.reduce((acc, offer) => {
                        if (offer.type === "free_drink") {
                          return acc === null ? 1 : acc + 1;
                        }
                        return acc;
                      }, null),
                      type: "Free Drink",
                    },
                    {
                      count: venue.offers.reduce((acc, offer) => {
                        if (offer.type !== "free_drink") {
                          return acc === null ? 1 : acc + 1;
                        }
                        return acc;
                      }, null),
                      type: "Follow-up Offer",
                    },
                  ]
                    .filter(type => type.count)
                    .map(type => (
                      <p
                        className="my-0"
                        key={type.type}
                      >{`${type.count} ${type.type}`}</p>
                    ))}
                </td>
                <td>{moment(venue.updated_at).format("Do MMM YYYY")}</td>
                <td>
                  <button
                    className="btn btn-sm"
                    onClick={() =>
                      setFieldValue(
                        "venue_ids",
                        values.venue_ids.filter(id => id !== venue.id)
                      )
                    }
                  >
                    <i className="fe fe-trash" />
                  </button>
                </td>
              </tr>
            )}
            title={`Venues (${values.venue_ids.length})`}
          />

          <div className="nav row align-items-center justify-content-end pb-5">
            <div className="col-auto">
              <button
                className="btn btn-md btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? <LoadingSpinner /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
