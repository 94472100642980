import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import api from "shared/api";
import BootstrapCard from "shared/components/BootstrapCard";
import MapField from "shared/components/formFields/MapField";
import MultipleSelectField from "shared/components/formFields/MultipleSelectField";
import SingleSelectField from "shared/components/formFields/SingleSelectField";
import TextField from "shared/components/formFields/TextField";
import GooglePlaceIdSelector from "./GooglePlaceIdSelector";
import GooglePlaceInfo from "./GooglePlaceInfo";

const VenueLocationForm = ({
  chains,
  cities,
  googlePlaceData,
  setGooglePlaceData,
}) => {
  const { handleBlur, setFieldValue, setValues, values } = useFormikContext();

  useEffect(() => {
    setGooglePlaceData(null);
    if (!values.google_place_id) {
      return;
    }
    api.get(`/google-places/${values.google_place_id}`).then(({ data }) => {
      setGooglePlaceData(data.data);
    });
  }, [values.google_place_id, setGooglePlaceData]);

  const stationLookup = useMemo(
    () =>
      (cities || []).reduce((acc, city) => {
        acc[String(city.id)] = city.stations;
        return acc;
      }, {}),
    [cities]
  );

  const areaLookup = useMemo(
    () =>
      (cities || []).reduce((acc, city) => {
        acc[String(city.id)] = city.subareas;
        return acc;
      }, {}),
    [cities]
  );

  return (
    <BootstrapCard title="Location">
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="form-label">Google Search</label>
            <GooglePlaceIdSelector
              onPlaceSelect={placeId => {
                setFieldValue("google_place_id", placeId);
              }}
            />
          </div>

          <TextField
            className="form-group"
            label="Google Place ID"
            name="google_place_id"
            onBlur={e => {
              handleBlur(e);
              setFieldValue("google_place_id", e.target.value || null);
            }}
          />
        </div>
        {googlePlaceData && (
          <div className="col-12 col-md-auto">
            <GooglePlaceInfo
              googlePlaceData={googlePlaceData}
              onPrefillClick={() =>
                setValues({
                  ...values,
                  ...googlePlaceData,
                  name: values.name || googlePlaceData.name,
                  google_place_id: values.google_place_id,
                  list_cta_name: googlePlaceData.name,
                  media_urls: values.media_urls || [],
                })
              }
              showPrefillOption={true}
            />
          </div>
        )}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 col-md-6">
          <TextField className="form-group" label="Venue name" name="name" />
        </div>
        <div className="col-12 col-md-6">
          <SingleSelectField
            className="form-group"
            label="Group"
            name="chain_id"
            options={chains || []}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <MapField className="form-group" label="Map" name="location" />
        </div>
        <div className="col-12 col-md-6">
          <TextField
            className="form-group"
            label="Address Line 1"
            name="address.line1"
          />
          <TextField
            className="form-group"
            label="Postcode"
            name="address.postcode"
          />
          <TextField className="form-group" label="Phone number" name="phone" />
        </div>
        <div className="col-12 col-md-6">
          <SingleSelectField
            className="form-group"
            label="City"
            name="city_id"
            options={cities || []}
          />
        </div>
        {values.city_id && (
          <>
            <div className="col-12 col-md-6">
              <MultipleSelectField
                className="form-group"
                label="City Areas"
                name="city_area_ids"
                options={areaLookup[String(values.city_id)] || []}
              />
            </div>
            <div className="col-12 col-md-6">
              <MultipleSelectField
                className="form-group"
                label="Stations"
                name="station_ids"
                options={stationLookup[String(values.city_id)] || []}
              />
            </div>
          </>
        )}
      </div>
    </BootstrapCard>
  );
};

export default VenueLocationForm;
