import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";

import api from "shared/api";
import WizardStepper from "app/venues/createEditPages/WizardStepper";
import FormSpinner from "shared/components/FormSpinner";
import WizardTabs from "shared/components/WizardTabs";
import CampaignDetailForm from "./CampaignDetailForm";
import CampaignVenueSetForm from "./CampaignVenueSetForm";
import CampaignBrandingForm from "./CampaignBrandingForm";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import VenueCategoryFormModal from "app/venueCategories/VenueCategoryFormModal";
import FormLeavePrompt from "shared/components/FormLeavePrompt";
import FormValidationNotification from "shared/components/FormValidationNotification";

const CampaignForm = ({ initialCampaign, renderTitleComponent }) => {
  const savedCampaign = useMemo(() => {
    if (!initialCampaign) {
      return null;
    }

    const asIsProperties = [
      "alerts_banner",
      "branding_toggles",
      "cta",
      "description",
      "guide",
      "guide_sync",
      "image_url",
      "list_label",
      "map_marker_icons",
      "name",
      "offer_ids",
      "offers_require_card_link",
      "post_drink_email_stub",
      "post_drink_survey",
      "published_from",
      "published_until",
      "total_redemption_limit",
      "daily_redemption_limits",
      "venue_detail_banner",
      "venue_ids",
      "media_urls",
      "venue_sets",
    ];

    return {
      ...asIsProperties.reduce((acc, key) => {
        if (
          typeof initialCampaign[key] !== "undefined" &&
          initialCampaign[key] !== null
        ) {
          acc[key] = initialCampaign[key];
        }
        return acc;
      }, {}),
      category_id: initialCampaign.category?.id,
    };
  }, [initialCampaign]);

  const [campaign, setCampaign] = useState({ ...(savedCampaign || {}) });
  const [pageIdx, setPageIdx] = useState(0);

  const [redirectPath, setRedirectPath] = useState(null);

  const [chains, setChains] = useState(null);
  const [collections, setCollections] = useState(null);
  const [offers, setOffers] = useState(null);
  const [categories, setCategories] = useState(null);

  const [isCreatingCategory, setIsCreatingCategory] = useState(false);

  const [venues, setVenues] = useState(initialCampaign?.venues || []);

  const venueLookup = useMemo(
    () =>
      venues.reduce((acc, venue) => {
        acc[venue.id] = venue;
        return acc;
      }, {}),
    [venues]
  );

  // Load Chains
  useEffect(() => {
    if (chains) {
      return;
    }

    api
      .get("/venue-chains", { params: { count: 250 } })
      .then(({ data }) => setChains(data.data));
  }, [chains]);

  // Load Venues
  useEffect(() => {
    if (
      !initialCampaign?.venue_sets.reduce(
        (acc, val) => [...acc, ...val.venue_ids],
        []
      )
    ) {
      return;
    }
    api
      .get("/venues", {
        params: { campaign_id: initialCampaign.id, count: 1000 },
      })
      .then(({ data }) => setVenues(data.data));
  }, [initialCampaign]);

  // Load Collections
  useEffect(() => {
    if (collections) {
      return;
    }

    api
      .get("/venue-collections", { params: { count: 250 } })
      .then(({ data }) => setCollections(data.data));
  }, [collections]);

  // Load Categories
  useEffect(() => {
    if (categories) {
      return;
    }

    api
      .get("/venue-categories", { params: { count: 250, campaign: 1 } })
      .then(({ data }) => setCategories(data.data));
  }, [categories]);

  // Load Offers
  useEffect(() => {
    if (offers) {
      return;
    }

    api
      .get("/offers", { params: { count: 250 } })
      .then(({ data }) => setOffers(data.data));
  }, [offers]);

  const onSave = useCallback(
    values =>
      new Promise(resolve => {
        const payload = { ...values };
        setCampaign(payload);

        [
          "alerts_banner",
          "branding_toggles",
          "cta",
          "list_label",
          "map_marker_icons",
          "post_drink_email_stub",
          "venue_detail_banner",
        ].forEach(key => {
          const val = payload[key];

          // Treat empty objects as null
          if (!val || Object.keys(val).length === 0) {
            payload[key] = null;
          }
        });
        payload.venue_sets = values.venue_sets
          .filter(set => set.offer_ids?.length || set.venue_ids?.length)
          .map(set => ({
            ...set,
            offer_ids: set.offer_ids.map(id => parseInt(id, 10)),
          }));

        // Empty strings as null
        Object.keys(payload).forEach(key => {
          if (payload[key] === "") {
            payload[key] = null;
          }
        });

        if (!payload.branding_toggles.list_label) {
          payload.list_label = null;
        }

        if (!payload.branding_toggles.venue_detail_banner) {
          payload.venue_detail_banner = null;
        }

        // Hook up linked guides. Because we're using an ajax field we need to
        // provide the entire guide object to the form, but only send guide_id
        // to the api.
        if (!payload.branding_toggles.linked_guide) {
          payload.guide = undefined;
          payload.guide_id = null;
          payload.guide_sync = false;
        } else if (payload.guide) {
          payload.guide_id = payload.guide.id;
          payload.guide = undefined;
        }
        payload.branding_toggles.linked_guide = undefined;

        const req = initialCampaign
          ? api.put(`/campaigns/${initialCampaign.id}`, payload)
          : api.post("/campaigns", payload);

        req
          .then(({ data }) => {
            setRedirectPath(`/campaigns/${data.data.id}`);
          })
          .finally(resolve);
      }),
    [initialCampaign]
  );

  const onCreateCategoryClick = useCallback(
    () => setIsCreatingCategory(true),
    []
  );

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (!(offers && chains && collections && categories)) {
    return <FormSpinner />;
  }

  return (
    <Formik
      initialValues={{
        description: campaign.description || "",
        name: campaign.name || "",
        published_from: campaign.published_from || "",
        published_until: campaign.published_until || "",
        total_redemption_limit: campaign.total_redemption_limit || "",
        daily_redemption_limits: campaign.daily_redemption_limits || null,
        media_urls: campaign.media_urls || [],
        offer_ids: campaign.offer_ids || [],
        offers_require_card_link:
          typeof campaign.offers_require_card_link === "undefined"
            ? true
            : campaign.offers_require_card_link,

        venue_ids: campaign.venue_ids || [],

        alerts_banner: campaign.alerts_banner || {},
        branding_toggles: {
          ...(campaign.branding_toggles || {
            list_label: false,
            venue_detail_banner: false,
            map_marker_icons: false,
          }),
          linked_guide: Boolean(campaign.guide),
        },
        guide: campaign.guide ?? undefined,
        guide_sync: campaign.guide_sync ?? false,
        cta: campaign.cta || {},
        category_id: campaign.category_id || "",
        list_label: campaign.list_label || {},
        map_marker_icons: campaign.map_marker_icons || {},
        post_drink_email_stub: campaign.post_drink_email_stub || "",
        venue_detail_banner: campaign.venue_detail_banner || {},
        venue_sets: campaign.venue_sets || [
          { name: "Set 1", venue_ids: [], offer_ids: [] },
        ],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name required"),
        list_label: Yup.object()
          .notRequired()
          .when("branding_toggles.list_label", value => {
            if (value) {
              return Yup.object({
                icon_url: Yup.string().required("Icon url required"),
                text: Yup.string().required("Text required"),
              });
            }
          }),
        venue_detail_banner: Yup.object()
          .notRequired()
          .when("branding_toggles.venue_detail_banner", value => {
            if (value) {
              return Yup.object({
                title_text: Yup.lazy(() =>
                  Yup.string().required("Title required")
                ),
                body_text: Yup.lazy(() =>
                  Yup.string().required("Body required")
                ),
              });
            }
          }),
      })}
      onSubmit={onSave}
    >
      {({ isSubmitting, setFieldValue }) => (
        <>
          <Form>
            {renderTitleComponent?.({ isSubmitting })}
            <FormValidationNotification />
            <WizardTabs
              onTabSelect={setPageIdx}
              options={["Details", "Venues & Offers", "Branding"]}
              selectedIndex={pageIdx}
            />

            {pageIdx === 0 && <CampaignDetailForm offers={offers} />}

            {pageIdx === 1 && (
              <CampaignVenueSetForm
                offers={offers}
                chains={chains}
                collections={collections}
                venueLookup={venueLookup}
                onAddVenues={newVenues => setVenues([...venues, ...newVenues])}
              />
            )}

            {pageIdx === 2 && (
              <CampaignBrandingForm
                categories={categories}
                onCreateCategoryClick={onCreateCategoryClick}
              />
            )}

            <WizardStepper
              isSaving={isSubmitting}
              onBackClick={() => {
                setPageIdx(pageIdx - 1);
              }}
              onNextClick={() => {
                setPageIdx(pageIdx + 1);
              }}
              pageIdx={pageIdx}
              pageCount={3}
            />
            <FormLeavePrompt />
          </Form>
          <VenueCategoryFormModal
            isCampaign={true}
            onClose={() => setIsCreatingCategory(false)}
            onConfirm={category => {
              setCategories([category, ...categories]);
              setFieldValue("category_id", category.id);
              setIsCreatingCategory(false);
            }}
            show={isCreatingCategory}
          />
        </>
      )}
    </Formik>
  );
};

export default CampaignForm;
