import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import WeeklyNumericFieldRow from "./WeeklyNumericFieldRow";
import { v4 as uuidv4 } from "uuid";
import TooltipIcon from "../TooltipIcon";

const WeeklyNumericField = ({ label, className, name, ...props }) => {
  const [field, , { setValue }] = useField(name);
  const id = useMemo(() => props.id || uuidv4(), [props.id]);
  const onRowUpdate = useCallback(
    (partial, idx) => {
      // Update the relevant row
      const updatedSlices = field.value.map((row, rowIdx) =>
        idx === rowIdx
          ? {
              ...(row || {}),
              ...partial,
            }
          : row
      );

      // Re-sort the slices incase a day was changed
      updatedSlices.sort((a, b) => {
        if (a.day_idx !== b.day_idx) {
          return a.day_idx - b.day_idx;
        }

        if (a.start > b.start) {
          return 1;
        }

        return a.start < b.start ? -1 : 0;
      });

      // Update the Formik value
      setValue(updatedSlices);
    },
    [field.value, setValue]
  );

  const onNewRow = useCallback(() => {
    const lastDay =
      field.value && field.value.length > 0
        ? field.value[field.value.length - 1]
        : null;

    const nextDayIdx = lastDay ? Math.min(6, lastDay.day_idx + 1) : 0;

    setValue([
      ...(field.value || []),
      {
        day_idx: nextDayIdx,
        limit: lastDay?.limit || "",
      },
    ]);
  }, [field.value, setValue]);

  return (
    <div className={className}>
      {label && (
        <label className="form-label d-block" htmlFor={id}>
          {label}
          {props.tooltip ? <TooltipIcon label={props.tooltip} /> : null}
        </label>
      )}
      {(field.value || []).map((slice, idx) => (
        <WeeklyNumericFieldRow
          key={idx}
          name={`${name}.${idx}`}
          onUpdate={partial => onRowUpdate(partial, idx)}
          onRemove={() => setValue(field.value.filter(row => slice !== row))}
          slice={slice}
        />
      ))}
      <button
        className="btn btn-primary btn-sm"
        onClick={onNewRow}
        type="button"
      >
        Add day
      </button>
    </div>
  );
};

export default WeeklyNumericField;
