import React from "react";
import CopyButton from "shared/components/CopyButton";

const InfoCardValueList = ({ items }) =>
  items.map((item, idx) => (
    <div className="d-flex flex-row align-items-center mb-3" key={idx}>
      <div style={{ width: "85%" }}>
        <p className="small text-muted text-truncate mb-0">{item}</p>
      </div>
      <div className=" d-flex justify-content-end" style={{ width: "15%" }}>
        <CopyButton text={String(item)} className="btn-sm" />
      </div>
    </div>
  ));

export default InfoCardValueList;
