import React, { useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { Link, useParams } from "react-router-dom";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import PageHeader from "shared/components/PageHeader";
import { renderBadgesForCampaign } from "./shared";
import DataCard from "shared/components/DataCard";
import moment from "moment";
import BootstrapCard from "shared/components/BootstrapCard";
import SettingListItemGroup from "shared/components/SettingListItemGroup";
import CopyButton from "shared/components/CopyButton";
import ListLabelPreview from "./shared/ListLabelPreview";
import BannerPreview from "./shared/BannerPreview";
import { formatDateTime } from "shared/utilities";
import CampaignRedemptionListTable from "./detailPage/CampaignRedemptionListTable";
import CampaignVenueSetCard from "./detailPage/CampaignVenueSetCard";
import SettingListSwitchItem from "shared/components/SettingListSwitchItem";
import cx from "classnames";
import styles from "./CampaignDetailPage.module.scss";
import UpdateVenueSetAvailabilityModal from "./detailPage/UpdateVenueSetAvailabilityModal";

const CampaignDetailPage = () => {
  const { id: campaignId } = useParams();

  const [campaign, setCampaign] = useState(null);
  const [offers, setOffers] = useState(null);
  const [stats, setStats] = useState(null);
  const [updatingParam, setUpdatingParam] = useState(null);
  const [showUpdateVenueSetModal, setShowUpdateVenueSetModal] = useState(null);

  const offerLookup = useMemo(
    () =>
      offers?.reduce((acc, offer) => {
        acc[offer.id] = offer;
        return acc;
      }, {}),
    [offers]
  );

  // Update campaign attributes
  const updateCampaign = useCallback(
    (param, value) => {
      if (updatingParam) {
        return;
      }

      setUpdatingParam(param);

      api
        .put(`/campaigns/${campaignId}`, { [param]: value })
        .then(({ data }) => {
          setCampaign({ ...data.data, venue_sets: campaign.venue_sets });
        })
        .finally(() => setUpdatingParam(null));
    },
    [campaignId, campaign, updatingParam]
  );

  const setCampaignVenueSet = useCallback(
    venueSet => {
      const venueSetsCopy = [...campaign.venue_sets];
      const venueSetIndex = campaign.venue_sets.findIndex(
        set => set.id === venueSet.id
      );
      venueSetsCopy[venueSetIndex] = venueSet;
      setCampaign({ ...campaign, venue_sets: venueSetsCopy });
    },
    [campaign]
  );

  useEffect(() => {
    if (campaign && campaign.id === parseInt(campaignId, 10)) {
      return;
    }

    api
      .get(`/campaigns/${campaignId}`, { params: { expanded: "true" } })
      .then(({ data }) => {
        setCampaign(data.data);
      });

    api.get(`/campaigns/${campaignId}/stats`).then(({ data }) => {
      setStats(data.data);
    });

    api
      .get("/offers", { params: { campaign_id: campaignId } })
      .then(({ data }) => {
        setOffers(data.data);
      });
  }, [campaignId, campaign]);

  const daysLive = useMemo(() => {
    if (!campaign) {
      return 0;
    }
    // Campaign isn't scheduled yet.
    if (!campaign.published_from) {
      return 0;
    }

    const dateNow = new Date();
    const momentCampaignStart = moment(campaign.published_from);

    // Campaign hasn't started yet.
    if (momentCampaignStart.toDate() > dateNow) {
      return 0;
    }

    // Campaign has started, no set end.
    const daysSinceStart = moment(dateNow).diff(momentCampaignStart, "days");
    if (!campaign.published_until) {
      return daysSinceStart;
    }

    const momentCampaignEnd = moment(campaign.published_until);

    // We're in the middle of the campaign
    if (momentCampaignEnd.toDate() > dateNow) {
      return daysSinceStart;
    }

    // It's after the end of the campaign
    return momentCampaignEnd.diff(momentCampaignStart, "days");
  }, [campaign]);

  const brandingRows = useMemo(() => {
    if (!campaign) {
      return null;
    }
    const options = [];

    if (campaign.category) {
      options.push({
        title: "Category",
        value: (
          <>
            <p className="small mb-0">
              <img
                alt={campaign.category.name}
                className={cx("me-2 mb-1", styles.categoryIcon)}
                src={campaign.category.icon_url}
              />
              {campaign.category.name}
            </p>
          </>
        ),
      });
    }

    if (campaign.branding_toggles?.list_label && campaign.list_label) {
      options.push({
        title: "List Label",
        value: <ListLabelPreview label={campaign.list_label} />,
      });
    }

    if (campaign.post_drink_email_stub) {
      options.push({
        title: "PDS",
        value: (
          <div className="text-truncate" style={{ maxWidth: "235px" }}>
            {campaign.post_drink_email_stub}
          </div>
        ),
      });
    }

    if (
      campaign.branding_toggles?.venue_detail_banner &&
      campaign.venue_detail_banner
    ) {
      options.push({
        title: "Venue Detail Banner",
        value: <BannerPreview banner={campaign.venue_detail_banner} />,
      });
    }

    if (
      campaign.branding_toggles?.map_marker_icons &&
      campaign.map_marker_icons
    ) {
      options.push({
        title: "Map Markers",
        value: (
          <div>
            {Object.values(campaign.map_marker_icons)
              .filter(Boolean)
              .map(url => (
                <img
                  alt="Map Marker"
                  className={styles.mapMarkerIcon}
                  key={url}
                  src={url}
                />
              ))}
          </div>
        ),
      });
    }

    if (campaign.guide) {
      options.push({
        title: "Linked guide",
        value: (
          <Link
            className="text-truncate"
            to={`/guides/${campaign.guide.id}/edit`}
            style={{ maxWidth: "235px" }}
          >
            {campaign.guide.name}
          </Link>
        ),
      });
    }

    return options;
  }, [campaign]);

  if (!(campaign && stats && offers)) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`${campaign.name} | Campaigns`}</title>
      </Helmet>
      <PageHeader
        pretitle="campaigns"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link
              className="btn btn-primary lift"
              to={`/campaigns/${campaignId}/edit`}
            >
              Edit
            </Link>
          </div>
        )}
      >
        {campaign.name}
        <span className="h5 ms-2 align-middle">
          {renderBadgesForCampaign(campaign)}
        </span>
      </PageHeader>

      <div className="row">
        <div className="col-6 col-md-3">
          <DataCard label="Drink redemptions">
            {stats.drink_redemptions.toLocaleString()}
          </DataCard>
        </div>
        <div className="col-6 col-md-3">
          <DataCard label="Offer redemptions">
            {stats.offer_redemptions.toLocaleString()}
          </DataCard>
        </div>
        <div className="col-6 col-md-3">
          <DataCard label="Days live">{daysLive.toLocaleString()}</DataCard>
        </div>
        <div className="col-6 col-md-3">
          <DataCard label="Venues live">
            {stats.live_venues.toLocaleString()}
          </DataCard>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8">
          {campaign.venue_sets.map(set => (
            <CampaignVenueSetCard
              key={set.id}
              venueSet={set}
              offerLookup={offerLookup}
              setCampaignVenueSet={updatedSet =>
                setCampaignVenueSet(updatedSet)
              }
              showUpdateVenueSetModal={venueSet =>
                setShowUpdateVenueSetModal(venueSet)
              }
            />
          ))}
          <CampaignRedemptionListTable campaignId={campaignId} />
        </div>
        <div className="col-12 col-lg-4">
          <BootstrapCard title="Campaign">
            <div className="list-group list-group-flush my-n3">
              <SettingListItemGroup
                items={[
                  {
                    title: "Name",
                    value: campaign.name,
                  },
                ]}
              />
              <SettingListItemGroup
                items={[
                  {
                    title: "Starts",
                    value: campaign.published_from
                      ? formatDateTime(campaign.published_from)
                      : "N/A",
                  },
                  {
                    title: "Expires",
                    value: campaign.published_until
                      ? formatDateTime(campaign.published_until)
                      : "N/A",
                  },
                ]}
              />
              <SettingListSwitchItem
                isChecked={campaign.is_test}
                isLoading={updatingParam === "is_test"}
                title="Test mode"
                onChange={e => updateCampaign("is_test", e)}
              />
              <SettingListItemGroup
                items={[
                  {
                    title: "Campaign ID",
                    value: (
                      <>
                        {campaign.id}
                        <CopyButton
                          className="ms-2"
                          text={String(campaign.id)}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </BootstrapCard>
          <BootstrapCard title="Actions">
            <div className="list-group list-group-flush my-n3">
              <SettingListSwitchItem
                isChecked={campaign.is_paused}
                isLoading={updatingParam === "is_paused"}
                title="Pause"
                subtitle="Temporarily remove branding &amp; hide campaign offers."
                onChange={e => updateCampaign("is_paused", e)}
              />
            </div>
          </BootstrapCard>
          {brandingRows && brandingRows.length > 0 && (
            <BootstrapCard title="Branding" bodyClassName="py-3">
              <div className="list-group list-group-flush my-n3">
                {brandingRows.map((item, idx) => (
                  <SettingListItemGroup key={idx} items={[item]} />
                ))}
              </div>
            </BootstrapCard>
          )}
        </div>
      </div>
      <UpdateVenueSetAvailabilityModal
        show={Boolean(showUpdateVenueSetModal)}
        onUpdated={updatedVenueSet => {
          setShowUpdateVenueSetModal(null);
          setCampaignVenueSet(updatedVenueSet);
        }}
        onClose={() => setShowUpdateVenueSetModal(null)}
        venueSet={showUpdateVenueSetModal}
      />
    </div>
  );
};

export default CampaignDetailPage;
