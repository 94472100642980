import React from "react";
import { Link } from "react-router-dom";
import GenericListItemWrapper from "../../../shared/components/GenericListItemWrapper";

const RewardBrandListItem = ({ brand }) => (
  <GenericListItemWrapper>
    {/* Avatar Holder?? */}
    <div className="col-auto">
      <img
        alt={brand.name || brand.brand_name}
        src={brand.logo_url || brand.brand_logo}
        style={{ width: 50, height: 50, borderRadius: 25 }}
      />
    </div>

    <div className="col-5 ml-n2">
      <h4 className="mb-1">{`${brand.name || brand.brand_name}`}</h4>
      <p className="small text-muted mb-0">{`Last Updated: ${brand.updated_at}`}</p>
    </div>

    <div className="col-auto ml-auto">
      <Link
        className="btn btn-outline-primary btn-sm"
        to={{ pathname: `/reward-brands/${brand.id}/edit` }}
      >
        Edit
      </Link>
    </div>
  </GenericListItemWrapper>
);

export default RewardBrandListItem;
