import React from "react";
import cx from "classnames";
import TextField from "./TextField";
import WeeklyNumericField from "./WeeklyNumericField";

export default function DailyRedemptionLimitsField({
  className,
  baseTooltip,
  dailyTooltip,
  name,
}) {
  return (
    <div className={cx(className)}>
      <TextField
        label="Daily drink limit"
        name={`${name}.base`}
        type="number"
        tooltip={baseTooltip}
      />
      <WeeklyNumericField
        label="Daily overrides"
        className="mt-4"
        name={`${name}.days`}
        tooltip={dailyTooltip}
      />
    </div>
  );
}
