import React, { useCallback, useState } from "react";
import cx from "classnames";

const CopyButton = ({ className, text, children }) => {
  const [status, setStatus] = useState(null);

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(text).then(
      () => {
        setStatus(true);
        setTimeout(() => {
          setStatus(null);
        }, 1000);
      },
      () => {
        setStatus(false);
        setTimeout(() => {
          setStatus(null);
        }, 1000);
      }
    );
  }, [text]);

  if (!navigator?.clipboard?.writeText) {
    return null;
  }

  return (
    <button
      className={cx("btn btn-sm btn-white", className)}
      onClick={onClick}
      type="button"
    >
      {status === null && <span className="fe fe-copy" />}
      {status === true && <span className="fe fe-check" />}
      {status === false && <span className="fe fe-x" />}
      {children && <span className="ms-2">{children}</span>}
    </button>
  );
};

export default CopyButton;
