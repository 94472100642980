import cx from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./BootstrapModal.module.scss";

console.log("STYLES: ", styles);

// pointer-events: ${(props) =>
//   props.show ? (props.show && props.blockBackdrop ? "none" : "all") : "none"};
const BootstrapModal = ({
  show,
  children,
  size,
  zIndex = 1040,
  skipRelease = false,
}) => {
  const [prevHeight, setPrevHeight] = useState(null);
  useEffect(() => {
    function fixScreen() {
      setPrevHeight(window.scrollY);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
      document.body.style.left = 0;
      document.body.style.right = 0;
    }

    function releaseScreen() {
      document.body.style.position = "";
      document.body.style.left = null;
      document.body.style.right = null;
      document.body.style.top = null;
      window.scrollTo(0, parseInt(prevHeight || "0", 10));
    }
    if (!show && !skipRelease) {
      releaseScreen();
    } else {
      fixScreen();
    }
    return () => {
      document.body.style.position = "";
      document.body.style.left = null;
      document.body.style.right = null;
      document.body.style.top = null;
    };
  }, [show, prevHeight, setPrevHeight, skipRelease]);

  return (
    <>
      <div>
        <div className={cx(styles.backdrop, show && styles.active)} />
      </div>
      <div
        className={cx("modal fade", styles.container, show && "show d-block")}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className={`modal-dialog modal-dialog-centered${
            size ? ` modal-${size}` : ""
          }`}
          role="document"
        >
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default BootstrapModal;
