import React from "react";
import { Link, useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import { badgesForVenue } from "app/venues/shared";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";

const VenueChainVenueListTable = ({
  chainId,
  resetKey,
  handleRemoveVenues,
}) => {
  const history = useHistory();
  return (
    <LocalStateListPageContents
      apiPath="/venues"
      apiParams={{ chain_id: chainId }}
      perPage={10}
      resetKey={resetKey}
      renderHeaderRow={() => (
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Areas</th>
            <th>City</th>
            <th>Offers</th>
            <th>Updated</th>
            <th />
          </tr>
        </thead>
      )}
      renderRow={venue => (
        <tr key={venue.id}>
          <td>
            {venue.name ? (
              <Link to={`/venues/${venue.id}`}>{venue.name}</Link>
            ) : (
              "N/A"
            )}
          </td>
          <td>
            {badgesForVenue(venue).map((badge, idx) => (
              <span
                className={cx(`badge bg-${badge.variant}`, idx > 0 && "ms-2")}
                key={idx}
              >
                {badge.label}
              </span>
            ))}
          </td>
          <td>{venue.city_areas.map(({ name }) => name).join(", ")}</td>
          <td>{venue.city?.name || "N/A"}</td>
          <td>
            {[
              {
                count: venue.offers.reduce((acc, offer) => {
                  if (offer.type === "free_drink") {
                    return acc === null ? 1 : acc + 1;
                  }
                  return acc;
                }, null),
                type: "Free Drink",
              },
              {
                count: venue.offers.reduce((acc, offer) => {
                  if (offer.type !== "free_drink") {
                    return acc === null ? 1 : acc + 1;
                  }
                  return acc;
                }, null),
                type: "Follow-up Offer",
              },
            ]
              .filter(type => type.count)
              .map(type => (
                <p
                  className="my-0"
                  key={type.type}
                >{`${type.count} ${type.type}`}</p>
              ))}
          </td>
          <td>{moment(venue.updated_at).format("Do MMM YYYY")}</td>
          <td>
            <button
              className="btn btn-sm"
              onClick={() => history.push(`/venues/${venue.id}`)}
            >
              <i className="fe fe-edit" />
            </button>
            <button
              className="btn btn-sm"
              onClick={() => handleRemoveVenues([venue.id])}
            >
              <i className="fe fe-trash" />
            </button>
          </td>
        </tr>
      )}
    />
  );
};

export default VenueChainVenueListTable;
