import TextAreaField from "app/venues/createEditPages/TextAreaField";
import { useField } from "formik";
import React from "react";
import ColourField from "shared/components/formFields/ColourField";
import TextField from "shared/components/formFields/TextField";
import BannerPreview from "../shared/BannerPreview";

const BannerField = ({ name }) => {
  const [field] = useField(name);

  return (
    <>
      <h3>Colours</h3>
      <div className="row">
        <div className="col-4">
          <ColourField label="Background" name={`${name}.background_color`} />
        </div>
        <div className="col-4">
          <ColourField label="Border" name={`${name}.border_color`} />
        </div>
        <div className="col-4">
          <ColourField label="Text" name={`${name}.text_color`} />
        </div>
      </div>
      <hr />
      <h3 className="mt-4">Copy</h3>
      <div className="row">
        <div className="col-12">
          <TextField
            className="form-group"
            label="Title"
            name={`${name}.title_text`}
          />
        </div>
        <div className="col-12">
          <TextAreaField
            className="form-group"
            label="Body"
            name={`${name}.body_text`}
            placeholder="Contents of your banner here..."
          />
        </div>
      </div>
      {[
        "background_color",
        "border_color",
        "text_color",
        "title_text",
        "body_text",
      ].every(key => Boolean(field.value?.[key])) && (
        <div className="col-12 d-flex flex-column align-items-start">
          <label className="form-label">Preview</label>
          <BannerPreview banner={field.value} />
        </div>
      )}
    </>
  );
};

export default BannerField;
