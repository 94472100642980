import React, { useCallback, useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "shared/components/Button";
import FormWizard, { WizardContext } from "shared/components/FormWizard";
import FormWizardHeader, {
  FormWizardTab,
} from "shared/components/FormWizard/FormWizardHeader";
import Input from "shared/components/Input";
import SpecialSelect from "shared/components/SpecialSelect";
import Textarea from "shared/components/Textarea";

const Step1 = () => {
  const { stepPrevious, stepNext, current, setValues } =
    useContext(WizardContext);

  const handleSubmit = values => {
    setValues(values);
    stepNext();
  };

  return (
    current === 0 && (
      <Formik
        initialValues={{
          name: "max",
          gender: "male",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(),
          gender: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="card-body">
              <h3>Something about you</h3>
              <Input
                label="Name"
                tooltip="Please include your full name"
                name="name"
              />
              <SpecialSelect
                name="gender"
                label="Gender"
                options={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
              />
            </div>
            <div className="card-footer">
              <div className="btn-group">
                <Button isOutlined onClick={() => stepPrevious()}>
                  Previous
                </Button>
                <Button type="submit">Next</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
};

const Step2 = () => {
  const { stepPrevious, stepNext, current, setValues } =
    useContext(WizardContext);

  const handleSubmit = values => {
    setValues(values);
    stepNext();
  };

  return (
    current === 1 && (
      <Formik
        initialValues={{
          address: "",
          phone: "",
        }}
        validationSchema={Yup.object({
          address: Yup.string().required(),
          phone: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="card-body">
              <h3>Tell us a little bit more stuffs</h3>
              <p className="text-muted">
                You can't proceed unless you complete this.
              </p>
              <Textarea label="Full street address" name="address" />
              <Input label="Phone" name="phone" />
            </div>
            <div className="card-footer">
              <div className="btn-group">
                <Button isOutlined onClick={() => stepPrevious()}>
                  Previous
                </Button>
                <Button type="submit">Next</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
};

const Step3 = () => {
  const { stepPrevious, current, setValues, getValues, completeLastStep } =
    useContext(WizardContext);

  const handleSubmit = useCallback(
    values => {
      return new Promise(res => {
        setValues(values);
        setTimeout(() => {
          completeLastStep();
          res();
        }, 2000);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValues]
  );

  return (
    current === 2 && (
      <Formik
        initialValues={{
          password: "secret",
        }}
        validationSchema={Yup.object({
          password: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="card-body">
              <h3>Give me your password and we're good to go.</h3>
              <Input label="Password" name="password" />
            </div>
            <div className="card-footer">
              <div className="btn-group">
                <Button isOutlined onClick={() => stepPrevious()}>
                  Previous
                </Button>
                <Button type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
};

const WizardExample2 = () => {
  return (
    <div className="card">
      <FormWizard
        totalSteps={3}
        nav={
          <div className="card-header">
            <div className="card-header-title">Complete this form</div>
            <FormWizardHeader isInCard>
              <FormWizardTab step={0}>Step 1</FormWizardTab>
              <FormWizardTab step={1}>Step 2</FormWizardTab>
              <FormWizardTab step={2}>Step 3</FormWizardTab>
            </FormWizardHeader>
          </div>
        }
      >
        <Step1 />
        <Step2 />
        <Step3 />
      </FormWizard>
    </div>
  );
};

export default WizardExample2;
