import TextAreaField from "app/venues/createEditPages/TextAreaField";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import SingleSelectField from "shared/components/formFields/SingleSelectField";
import TextField from "shared/components/formFields/TextField";
import * as Yup from "yup";
import api from "shared/api";
import { Redirect } from "react-router-dom";
import LoadingSpinner from "shared/components/LoadingSpinner";
import FormLeavePrompt from "shared/components/FormLeavePrompt";
import ImageUploadField from "shared/components/ImageUploadField";

const OfferForm = ({ offer = null, renderTitleComponent }) => {
  const [redirectPath, setRedirectPath] = useState(null);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <Formik
      initialValues={{
        description: offer?.description || "",
        icon_url: offer?.icon_url || "",
        image_url: offer?.image_url || "",
        ingredients: offer?.ingredients || "",
        list_cta_name: offer?.list_cta_name || "",
        max_round_count: offer?.max_round_count || "",
        name: offer?.name || "",
        type: offer?.type || "",
        redemption_info: offer?.redemption_info || "",
        redemption_terms:
          offer?.redemption_terms ||
          "18+ only whilst stocks and offer lasts. Please drink responsibly and visit drinkaware.co.uk.",
      }}
      onSubmit={payload =>
        new Promise(resolve => {
          const apiRequest = offer
            ? api.put(`/offers/${offer.id}`, payload)
            : api.post("/offers", payload);

          apiRequest
            .then(({ data }) => {
              setRedirectPath(`/offers/${data.data.id}`);
            })
            .finally(resolve);
        })
      }
      validationSchema={Yup.object().shape({
        description: Yup.string().when("type", {
          is: "free_drink",
          then: Yup.string().required(
            "Description is required for free drinks."
          ),
        }),
        icon_url: Yup.string()
          .url()
          .when("type", {
            is: "free_drink",
            then: Yup.string()
              .url()
              .required("All free drinks require an icon."),
          }),
        image_url: Yup.string()
          .url()
          .when("type", {
            is: "free_drink",
            then: Yup.string()
              .url()
              .required("All free drinks require an image."),
          }),
        ingredients: Yup.string(),
        list_cta_name: Yup.string(),
        max_round_count: Yup.number(),
        name: Yup.string().required("Name is required for all offers."),
        type: Yup.string()
          .oneOf(["free_drink", "custom"])
          .required("You must select an offer type."),
        redemption_info: Yup.string(),
        redemption_terms: Yup.string(),
      })}
    >
      {({ isSubmitting, values }) => (
        <>
          <Form>
            {renderTitleComponent?.({ isSubmitting })}
            <BootstrapCard title="Offer type">
              <div className="row">
                <div className="col-12">
                  <SingleSelectField
                    disabled={Boolean(offer)}
                    name="type"
                    options={[
                      { id: "custom", name: "Follow-up Offer" },
                      { id: "free_drink", name: "Free Drink" },
                    ]}
                  />
                </div>
              </div>
            </BootstrapCard>
            {values.type === "free_drink" && (
              <BootstrapCard title="Free Drink">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextField
                      className="form-group"
                      label="Name"
                      name="name"
                      placeholder="Grind Espresso Martini"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextField
                      className="form-group"
                      label="Short Name"
                      name="list_cta_name"
                      placeholder="Espresso Martini"
                    />
                  </div>
                  <div className="col-12">
                    <TextAreaField
                      className="form-group"
                      label="Description"
                      name="description"
                      placeholder="This is a delicious drink for those who want to both wake up and get twisted."
                    />
                    <TextField
                      className="form-group"
                      label="Ingredients"
                      name="ingredients"
                      placeholder="Espresso, Hopes, Dreams, Liquor"
                    />
                    <TextField
                      className="form-group"
                      label="Redemption Terms"
                      name="redemption_terms"
                      placeholder="You must be 18+ to drank"
                    />
                    <TextField
                      className="form-group"
                      label="Redemption Info"
                      name="redemption_info"
                      placeholder="Redemption Info to display below the drink name on the redemption screen."
                    />
                  </div>
                </div>
              </BootstrapCard>
            )}
            {values.type === "custom" && (
              <BootstrapCard title="Custom Offer">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextField
                      className="form-group"
                      label="Name"
                      name="name"
                      placeholder="20% Off Errthang"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextField
                      className="form-group"
                      label="Short Name"
                      name="list_cta_name"
                      placeholder="20% Off"
                    />
                  </div>
                  <div className="col-12 col-md-8">
                    <TextAreaField
                      className="form-group"
                      label="Description"
                      name="description"
                      placeholder="This is a delicious drink for those who want to both wake up and get twisted."
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <TextField
                      className="form-group"
                      label="Max round count"
                      name="max_round_count"
                      placeholder="4"
                      type="number"
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      className="form-group"
                      label="Redemption Terms"
                      name="redemption_terms"
                      placeholder="You must be 18+ to drank"
                    />
                    <TextField
                      className="form-group"
                      label="Redemption Info"
                      name="redemption_info"
                      placeholder="Redemption Info to display below the drink name on the redemption screen."
                    />
                  </div>
                </div>
              </BootstrapCard>
            )}
            {values.type && (
              <>
                <BootstrapCard title="Media">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                      <ImageUploadField
                        name="image_url"
                        label="Offer image"
                        alt="Main Cover"
                        isSingle={true}
                        resize={{ width: 1284, height: 1284 }}
                        imageStyle={{ width: 200, height: 200 }}
                        rounded
                      />
                    </div>
                    <hr className="d-md-none my-5" />
                    <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                      <ImageUploadField
                        name="icon_url"
                        label="Icon image"
                        alt="CTA Icon"
                        isSingle={true}
                        resize={{ width: 120, height: 120 }}
                        imageStyle={{ width: 40, height: 40 }}
                        rounded
                      />
                    </div>
                  </div>
                </BootstrapCard>
                <div className="nav row align-items-center justify-content-end pb-5">
                  <div className="col-auto">
                    <button
                      className="btn btn-md btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <LoadingSpinner /> : "Save"}
                    </button>
                  </div>
                </div>
              </>
            )}
            <FormLeavePrompt />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default OfferForm;
