import React from "react";

const RewardCodeListItem = ({ code }) => (
  <tr>
    <td>
      <h4 className="mb-1">{`${code.code}`}</h4>
    </td>
    <td>
      <h4 className="mb-1">{`${code.url}`}</h4>
    </td>
    <td>
      {code.redeemed_at && <h4 className="mb-1">{`${code.redeemed_at}`}</h4>}
    </td>
  </tr>
);

export default RewardCodeListItem;
