import React from "react";
import Helmet from "react-helmet";
import LoadingSpinner from "shared/components/LoadingSpinner";
import PageHeader from "../../shared/components/PageHeader";
import GuideForm from "./createUpdate/GuideForm";

const GuideCreatePage = () => (
  <div>
    <Helmet>
      <title>Create Guide</title>
    </Helmet>
    <GuideForm
      renderTitleComponent={({ isSubmitting }) => (
        <PageHeader
          pretitle="dusk admin"
          renderAdditionalComponents={() => (
            <div className="col-12 col-md-auto">
              <button
                className="btn btn-primary lift"
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? <LoadingSpinner /> : "Save"}
              </button>
            </div>
          )}
        >
          Create a Guide
        </PageHeader>
      )}
    />
  </div>
);

export default GuideCreatePage;
