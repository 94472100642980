import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const TableSearchField = ({ currentQuery, onQueryChange }) => {
  // currentQuery is to support loading into a search query
  const [searchTerm, setSearchTerm] = useState(currentQuery || "");

  // Debounce the term update so it searches after user stops typing for 500ms
  const reportQueryChange = useMemo(
    () => debounce(query => onQueryChange(query), 500),
    [onQueryChange]
  );

  // Report search term changes
  useEffect(() => {
    if (searchTerm === currentQuery) {
      return;
    }
    reportQueryChange(searchTerm);
  }, [currentQuery, reportQueryChange, searchTerm]);

  return (
    <div className="input-group input-group-flush input-group-merge input-group-reverse">
      <input
        className="form-control list-search"
        onChange={e => setSearchTerm(e.target.value)}
        placeholder="Search"
        type="search"
        value={searchTerm}
      />
      <span className="input-group-text">
        <i className="fe fe-search" />
      </span>
    </div>
  );
};

export default TableSearchField;
