import React from "react";
import { useField } from "formik";
import cx from "classnames";

import InputField from "./InputField";

const Input = ({ type = "text", className: _className, ...props }) => {
  const [field, meta] = useField(props.id || props.name);
  const className = cx(
    `form-control`,
    {
      "is-invalid": meta && meta.touched && meta.error,
    },
    _className
  );
  return (
    <InputField {...{ ...props, meta }}>
      <input className={className} type={type} {...field} />
    </InputField>
  );
};

export default Input;
