import React from "react";
import Helmet from "react-helmet";
import CampaignForm from "./createUpdate/CampaignForm";
import FormPageHeader from "shared/components/FormPageHeader";

const CampaignCreatePage = () => (
  <div>
    <Helmet>
      <title>Create Campaign</title>
    </Helmet>
    <CampaignForm
      renderTitleComponent={({ isSubmitting }) => (
        <FormPageHeader isSubmitting={isSubmitting} pretitle="campaigns">
          Create Campaign
        </FormPageHeader>
      )}
    />
  </div>
);

export default CampaignCreatePage;
