import React from "react";
import { Link } from "react-router-dom";
import GenericListItemWrapper from "../../../shared/components/GenericListItemWrapper";

const RewardCategoryListItem = ({ category }) => (
  <GenericListItemWrapper>
    {/* Avatar Holder?? */}
    <div className="col-auto">
      <img
        alt={category.name}
        src={category.image_url}
        style={{
          width: 50,
          height: 50,
          borderRadius: 25,
          borderColor: category.colour,
          borderWidth: 3,
          borderStyle: "solid",
        }}
      />
    </div>

    <div className="col-5 ml-n2">
      <h4 className="mb-1">{`${category.name}`}</h4>
      <p className="small text-muted mb-0">{`Last Updated: ${category.updated_at}`}</p>
    </div>

    <div className="col-auto ml-auto">
      <Link
        className="btn btn-outline-primary btn-sm"
        to={{ pathname: `/reward-categories/${category.id}/edit` }}
      >
        Edit
      </Link>
    </div>
  </GenericListItemWrapper>
);

export default RewardCategoryListItem;
