import React from "react";
import { components } from "react-select";
import cx from "classnames";

import styles from "./SelectCategoryOption.module.scss";

const SelectCategorySingleValue = ({ data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className="d-flex flex-row align-items-center">
        {data.raw?.icon_url && (
          <div className={cx(styles.iconContainer, "me-2")}>
            <img
              alt={data.label}
              className={styles.icon}
              src={data.raw.icon_url}
            />
          </div>
        )}
        <span>{data.label}</span>
      </div>
    </components.SingleValue>
  );
};

export default SelectCategorySingleValue;
