import React from "react";
import { Route, Switch } from "react-router-dom";
import CampaignListPage from "./campaigns/CampaignListPage";
import CampaignCreatePage from "./campaigns/CampaignCreatePage";
import CampaignEditPage from "./campaigns/CampaignEditPage";
import CampaignDetailPage from "./campaigns/CampaignDetailPage";

const OfferSection = () => (
  <Switch>
    <Route path="/campaigns/create" component={CampaignCreatePage} />
    <Route exact path="/campaigns/:id" component={CampaignDetailPage} />
    <Route exact path="/campaigns/:id/edit" component={CampaignEditPage} />
    <Route component={CampaignListPage} />
  </Switch>
);

export default OfferSection;
