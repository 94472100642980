import TextAreaField from "app/venues/createEditPages/TextAreaField";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import api from "shared/api";
import BootstrapCard from "shared/components/BootstrapCard";
import DateTimeField from "shared/components/formFields/DateTimeField";
import MultiSelectTextField from "shared/components/formFields/MultiSelectTextField";
import SwitchField from "shared/components/formFields/SwitchField";
import TextField from "shared/components/formFields/TextField";
import VenueSelectionCard from "shared/components/VenueSelectionCard";
import ImageUploadField from "shared/components/ImageUploadField";
import { Link, Redirect } from "react-router-dom";
import FormLeavePrompt from "shared/components/FormLeavePrompt";
import DeleteModal from "shared/modals/DeleteModal";
import { useHistory } from "react-router-dom";
import MultipleSelectField from "shared/components/formFields/MultipleSelectField";
import FormSpinner from "shared/components/FormSpinner";

const GuideForm = ({ initialGuide, renderTitleComponent }) => {
  const guide = useMemo(() => {
    const asIsProperties = [
      "name",
      "description",
      "image_url",
      "is_test",
      "published_at",
      "venues",
      "tags",
    ];

    return {
      ...asIsProperties.reduce((acc, key) => {
        if (initialGuide?.[key] !== null && initialGuide?.[key] !== undefined) {
          acc[key] = initialGuide[key];
        }
        return acc;
      }, {}),
    };
  }, [initialGuide]);

  const [chains, setChains] = useState(null);
  const [collections, setCollections] = useState(null);
  const [venues, setVenues] = useState(initialGuide?.venues || []);
  const [redirect, setRedirect] = useState(null);
  const [tags, setTags] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);

  const history = useHistory();

  const isCampaignGuide = useMemo(
    () => Boolean(initialGuide?.campaign),
    [initialGuide]
  );

  const venueLookup = useMemo(
    () =>
      venues.reduce((acc, venue) => {
        acc[venue.id] = venue;
        return acc;
      }, {}),
    [venues]
  );

  // Load Venues
  useEffect(() => {
    if (!guide?.venues?.length) {
      return;
    }

    api
      .get("/venues", {
        params: { guide_id: guide.id, count: 1000 },
      })
      .then(({ data }) => setVenues(data.data));
  }, [guide]);

  // Load Chains
  useEffect(() => {
    if (chains) {
      return;
    }

    api
      .get("/venue-chains", { params: { count: 250 } })
      .then(({ data }) => setChains(data.data));
  });

  // Load Collections
  useEffect(() => {
    if (collections) {
      return;
    }

    api
      .get("/venue-collections", { params: { count: 250 } })
      .then(({ data }) => setCollections(data.data));
  });

  // Load tags
  useEffect(() => {
    if (tags) {
      return;
    }
    api.get("/guides/tags").then(({ data }) => {
      setTags(data.data);
    });
  }, [tags]);

  const onSave = useCallback(
    values =>
      new Promise(resolve => {
        const saveRequest = initialGuide?.id
          ? api.put(`/guides/${initialGuide.id}`, values)
          : api.post("/guides", values);

        saveRequest.then(() => setRedirect("/guides")).finally(resolve);
      }),
    [initialGuide]
  );

  const deleteGuide = useCallback(() => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    api
      .delete(`/guides/${initialGuide.id}`)
      .then(() => {
        history.push("/guides");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [initialGuide, history, isDeleting]);

  if (redirect) {
    return <Redirect to="/guides" />;
  }

  if (!tags) {
    return <FormSpinner />;
  }

  return (
    <Formik
      initialValues={{
        name: guide.name || "",
        description: guide.description || "",
        image_url: guide.image_url || "",
        published_at: guide.published_at || "",
        venues: guide.venues || [],
        is_test: guide.is_test || false,
        tags: guide.tags || [],
      }}
      onSubmit={onSave}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        image_url: Yup.string().required().url(),
      })}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        const venueIds = new Set(values.venues.map(({ id }) => id));

        return (
          <Form>
            {renderTitleComponent?.({ isSubmitting })}
            {isCampaignGuide && (
              <div className="alert alert-dark">
                This guide is linked to the campaign{" "}
                <Link to={`/campaigns/${initialGuide.campaign.id}`}>
                  {initialGuide.campaign.name}
                </Link>
                .
              </div>
            )}
            <div className="row">
              <div className="col-12 col-lg-4">
                <BootstrapCard>
                  <TextField
                    className="form-group"
                    label="Title"
                    name="name"
                    placeholder="Think of something good"
                  />
                  <TextAreaField
                    className="form-group"
                    label="Summary"
                    name="description"
                    placeholder="Description of this wonderful Guide"
                    style={{
                      minHeight: "120px",
                    }}
                    tooltip="This description supports markdown formatting. <a href='https://www.notion.so/duskapp/Markdown-Text-formatting-in-app-a199beef80c04ef795efadcef2220585' target='_blank'>Read documentation here.</a>"
                  />

                  <ImageUploadField
                    name="image_url"
                    label="Image"
                    alt="Cover"
                    isSingle={true}
                    resize={{ width: 1284, height: 1284 }}
                    imageRatio="15x9"
                    rounded
                  />
                  <DateTimeField
                    className={"form-group"}
                    disabled={isCampaignGuide}
                    label="Publish Date"
                    name="published_at"
                    tooltip={
                      isCampaignGuide
                        ? "You are unable to update this field when the guide is linked to a campaign"
                        : undefined
                    }
                  />
                  <SwitchField
                    className="d-flex justify-content-between form-group"
                    disabled={isCampaignGuide}
                    label="Test Guide"
                    name="is_test"
                    tooltip={
                      isCampaignGuide
                        ? "You are unable to update this field when the guide is linked to a campaign"
                        : undefined
                    }
                  />
                  <button
                    className="btn btn-outline-danger col-12"
                    type="button"
                    onClick={() => setIsShowingDeleteModal(true)}
                  >
                    <span className="fe fe-trash-2" /> Delete guide
                  </button>
                  <MultipleSelectField
                    className="form-group pt-2"
                    label="Tags (internal use only)"
                    name="tags"
                    isCreatable
                    options={tags.map(tag => ({ id: tag, name: tag }))}
                  />
                  <button
                    className="btn btn-outline-danger w-100 mt-3"
                    onClick={() => {
                      if (values.venues) {
                        setFieldValue(
                          "venues",
                          values.venues.map(venue => ({
                            ...venue,
                            description: " ",
                          }))
                        );
                      }
                    }}
                    type="button"
                  >
                    Remove descriptions
                  </button>
                </BootstrapCard>
              </div>
              <div className="col-12 col-lg-8">
                {chains &&
                  collections &&
                  (!isCampaignGuide || !initialGuide?.campaign.guide_sync) && (
                    <VenueSelectionCard
                      chains={chains}
                      collections={collections}
                      onVenuesSelected={selectedVenues => {
                        const newVenues = selectedVenues.filter(
                          ({ id }) => !venueIds.has(id)
                        );
                        setVenues([...newVenues, ...venues]);
                        setFieldValue("venues", [
                          ...newVenues.map(v => ({
                            id: v.id,
                            name: v.name,
                            description: v.description,
                            tags: v.categories.map(({ name }) => name),
                          })),
                          ...values.venues,
                        ]);
                      }}
                    />
                  )}
                <div>
                  {values.venues.map((venue, idx) => {
                    const fullVenue = venueLookup[venue.id];

                    const cityAreas = fullVenue?.city_areas
                      ?.map(({ name }) => name)
                      ?.join(", ");
                    const cityName = fullVenue?.city?.name;

                    return (
                      <BootstrapCard
                        key={venue.id}
                        renderAdditionalHeaderContent={() =>
                          (!isCampaignGuide ||
                            !initialGuide?.campaign.guide_sync) && (
                            <div className="col-auto">
                              <span
                                class="h2 fe fe-x-circle"
                                onClick={() =>
                                  setFieldValue(
                                    "venues",
                                    values.venues.filter(
                                      (_, venueIdx) => venueIdx !== idx
                                    )
                                  )
                                }
                                role="button"
                              />
                            </div>
                          )
                        }
                        title={
                          <>
                            <span>
                              {venue.name}{" "}
                              <span className="fw-normal">({venue.id})</span>
                            </span>
                            {fullVenue && (
                              <>
                                <br />
                                <span className="fw-normal small text-muted">
                                  {[cityAreas, cityName]
                                    .filter(Boolean)
                                    .join(" - ")}
                                </span>
                              </>
                            )}
                          </>
                        }
                      >
                        <MultiSelectTextField
                          className="form-group"
                          label="Tags"
                          name={`venues.${idx}.tags`}
                        />
                        <TextAreaField
                          label="Summary"
                          name={`venues.${idx}.description`}
                          style={{ minHeight: "120px" }}
                        />
                      </BootstrapCard>
                    );
                  })}
                </div>
              </div>
            </div>
            <FormLeavePrompt />
            <DeleteModal
              isDeleting={isDeleting}
              isOpen={isShowingDeleteModal}
              item="Guide"
              handleAction={deleteGuide}
              handleClose={() => setIsShowingDeleteModal(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default GuideForm;
