import React, { useState } from "react";
import MultiSelectTextField from "shared/components/formFields/MultiSelectTextField";

import IconNationalRail from "assets/images/iconNationalRail.svg";
import IconLondonUnderground from "assets/images/iconUnderground.svg";

import cx from "classnames";

const stationTypes = [
  {
    type: "rail",
    label: "Rail",
    icon: IconNationalRail,
  },
  {
    type: "underground",
    label: "Underground",
    icon: IconLondonUnderground,
  },
];

const MultiValueLabel = props => (
  <div
    className={cx(props.innerProps.className, "d-flex align-items-center")}
    key={props.data.id || props.data.name}
  >
    <img
      alt={props.data.name}
      className="me-2"
      src={
        props.data.type === "underground"
          ? IconLondonUnderground
          : IconNationalRail
      }
      style={{ width: "12px", height: "12px" }}
    />
    {props.data.name}
  </div>
);

const StationsField = ({ name }) => {
  const [newStationType, setNewStationType] = useState("rail");

  return (
    <div>
      <div className="form-group">
        <label className="form-label">Add new stations as...</label>
        {stationTypes.map(stationType => (
          <div className="form-check" key={stationType.type}>
            <input
              checked={stationType.type === newStationType}
              className="form-check-input"
              id={`station-${stationType.type}`}
              type="radio"
              onChange={() => setNewStationType(stationType.type)}
              name="newStationType"
              value={stationType.type}
            />
            <label
              className="form-check-label d-flex align-items-center"
              htmlFor={`station-${stationType.type}`}
            >
              <img
                alt={stationType.label}
                className="me-2"
                src={stationType.icon}
                style={{ width: "16px", height: "16px" }}
              />
              {stationType.label}
            </label>
          </div>
        ))}
      </div>
      <hr />
      <MultiSelectTextField
        className="form-group"
        components={{ MultiValueLabel }}
        createOption={val => ({
          label: val,
          name: val,
          new: true,
          value: val,
          type: newStationType,
        })}
        name={name}
      />
    </div>
  );
};

export default StationsField;
