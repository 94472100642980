import React, { useCallback } from "react";
import api from "shared/api";
import AsyncSelect from "react-select/async";
import { useField } from "formik";
import cx from "classnames";

const GuideSelectField = ({ className, label, name }) => {
  const [field, meta, { setValue }] = useField(name);

  const loadOptions = useCallback(
    query =>
      new Promise((resolve, reject) => {
        api.get("/guides", { params: { query, sort: "updated_at" } }).then(
          ({ data }) =>
            resolve(
              data.data.map(guide => ({
                label: guide.name,
                value: guide.id,
                guide: guide,
              }))
            ),
          reject
        );
      }),
    []
  );

  return (
    <div className={className}>
      {Boolean(label) && <label className="form-label">{label}</label>}
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        onChange={({ guide }) => setValue(guide)}
        className={cx(meta?.touched && meta?.error && "is-invalid")}
        value={
          field.value
            ? {
                label: field.value.name,
                value: field.value.id,
                guide: field.value,
              }
            : undefined
        }
      />
    </div>
  );
};

export default GuideSelectField;
