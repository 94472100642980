import React, { useCallback, useState } from "react";
import api from "shared/api";
import CardModal from "shared/components/CardModal";
import LoadingSpinner from "shared/components/LoadingSpinner";

const UpdateVenueOfferPauseStatusModal = ({
  offerId,
  onClose,
  onUpdated,
  show,
  venueId,
  targetPauseStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    api
      .put(`/venues/${venueId}/offers/${offerId}`, {
        is_paused: targetPauseStatus,
      })
      .then(({ data }) => onUpdated(data.data))
      .finally(() => setIsLoading(false));
  }, [isLoading, offerId, onUpdated, targetPauseStatus, venueId]);

  return (
    <CardModal
      onClose={onClose}
      show={show}
      title={targetPauseStatus ? "Pause Offer" : "Resume Offer"}
    >
      {() => (
        <>
          <div className="card-body">
            <p>
              Please confirm you'd like to{" "}
              {targetPauseStatus ? "pause" : "resume"} this venue's offer.
            </p>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-white" onClick={onClose} type="button">
              Cancel
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={onConfirm}
              type="button"
            >
              {isLoading ? <LoadingSpinner /> : "Confirm"}
            </button>
          </div>
        </>
      )}
    </CardModal>
  );
};

export default UpdateVenueOfferPauseStatusModal;
