import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../shared/api";
import DeleteModal from "../../../shared/modals/DeleteModal";
import DropdownButton from "shared/components/DropdownButton";

const VenueCollectionQuickActionButton = ({ venue, handleDelete }) => {
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const [modal, showModal] = useState(false);

  const deleteCollection = useCallback(() => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    api
      .delete(`/venue-collections/${venue.id}`)
      .then(() => {
        history.push("/venue-collections");
        showModal(false);
        handleDelete();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [venue, history, isDeleting, handleDelete]);

  return (
    <>
      <DropdownButton type="ellipses">
        <button
          className="dropdown-item"
          onClick={() => history.push(`/venue-collections/${venue.id}/edit`)}
          type="button"
        >
          Edit Collection
        </button>
        <button
          className="dropdown-item"
          onClick={() => showModal(true)}
          type="button"
        >
          Delete
        </button>
      </DropdownButton>
      {modal && (
        <DeleteModal
          isOpen={modal}
          item="Venue Collection"
          handleAction={deleteCollection}
          handleClose={() => showModal(false)}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};

export default VenueCollectionQuickActionButton;
