import React from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";
import SettingListItem from "shared/components/SettingListItem";

const GenerateLoginCodeActionItem = ({ isLoading, code, onClick }) => (
  <SettingListItem
    icon="refresh-cw"
    title="Generate Login Code"
    subtitle="Generate a code a user can use to log in instead of the one emailed to them."
  >
    {code && <p className="fw-bold my-0">{code}</p>}
    {!code && (
      <button className="btn btn-sm btn-white" onClick={onClick}>
        {isLoading && <LoadingSpinner className="mt-1" />}
        {!isLoading && "Generate"}
      </button>
    )}
  </SettingListItem>
);

export default GenerateLoginCodeActionItem;
