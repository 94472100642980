import React from "react";
import cx from "classnames";
import Gravatar from "react-gravatar";
import _ from "lodash";
import DropdownButton from "shared/components/DropdownButton";

const AdminUserTableRow = ({ user, onDelete }) => {
  const isConfirmed = user.invite_status === "confirmed";
  return (
    <tr>
      <td>
        <div className="avatar avatar-xs align-middle me-2">
          <Gravatar email={user.email} className="avatar-img rounded-circle" />
        </div>
        {isConfirmed ? (
          <span className="item-name text-reset ps-2">{`${user.first_name} ${user.last_name}`}</span>
        ) : (
          <span className="item-name text-muted ps-2">
            {user.email.split("@")[0]}
          </span>
        )}
      </td>
      <td>
        <span>{user.email}</span>
      </td>
      <td>
        <span
          className={cx(
            "badge",
            isConfirmed ? "bg-success-soft" : "bg-warning-soft"
          )}
        >
          {_.startCase(isConfirmed ? user.invite_status : "Unconfirmed")}
        </span>
      </td>
      <td>
        <DropdownButton type="ellipses">
          <button className="dropdown-item" onClick={onDelete} type="button">
            Remove user
          </button>
        </DropdownButton>
      </td>
    </tr>
  );
};

export default AdminUserTableRow;
