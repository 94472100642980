import React from "react";
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import Icon from "./Icon";

const SidebarNavigationLink = ({
  children,
  icon,
  iconVariant,
  url,
  onNavigation,
}) => {
  const location = useLocation();

  const isActive =
    url === "/" ? location.pathname === url : location.pathname.startsWith(url);

  return (
    <li className="nav-item" onClick={onNavigation}>
      <Link className={cx("nav-link", isActive && "active")} to={url}>
        <Icon name={icon} variant={iconVariant} />
        {children}
      </Link>
    </li>
  );
};

export default SidebarNavigationLink;
