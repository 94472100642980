import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({
  children,
  backButton,
  backButtonUrl,
  pretitle,
  renderAdditionalComponents,
  renderTabs,
  removeBottomMargin = false,
  className = "",
  style,
}) => {
  return (
    <div
      className={`header mt-md-5 ${
        removeBottomMargin ? "mb-0" : ""
      } ${className}`}
      style={style}
    >
      <div className="header-body">
        <div className="row align-items-center">
          <div className="col">
            {backButton && backButtonUrl && (
              <Link to={backButtonUrl}>
                <p className="mkit-pretitle" role="button">
                  <i className="fe fe-arrow-left me-3" />
                  {backButton}
                </p>
              </Link>
            )}
            {pretitle && <h6 className="header-pretitle">{pretitle}</h6>}
            <h1 className="header-title">{children}</h1>
          </div>
          {renderAdditionalComponents?.() || null}
        </div>
        {renderTabs?.() || null}
      </div>
    </div>
  );
};

export default PageHeader;
