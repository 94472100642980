import React, { useCallback, useState } from "react";
import cx from "classnames";
import styles from "./CustomerActionCard.module.scss";
import BootstrapCard from "shared/components/BootstrapCard";
import LoadingSpinner from "shared/components/LoadingSpinner";

const CustomerActionCard = ({ text, icon, disabled, onClick, isLoading }) => {
  const [successText, setSuccessText] = useState(null);
  const [successIcon, setSuccessIcon] = useState(null);

  const handleClick = useCallback(() => {
    onClick().then(() => {
      // Replace icon and text with success message temporarily
      setSuccessText("Reset!");
      setSuccessIcon("check");
      setTimeout(() => {
        setSuccessText(null);
        setSuccessIcon(null);
      }, 1000);
    });
  }, [onClick]);

  return (
    <BootstrapCard
      className="card text-bg-primary"
      style={{ height: "112px" }}
      bodyClassName="py-3"
      onClick={!disabled ? handleClick : null}
    >
      {disabled && <div className={cx(["card", styles.overlay])} />}
      <div className="row align-items-center gx-0">
        {isLoading ? (
          <LoadingSpinner className="my-3" />
        ) : (
          <span
            className={`mb-2 h1 fe fe-${successIcon ? successIcon : icon}`}
          />
        )}
        <h4>{successText ? successText : text}</h4>
      </div>
    </BootstrapCard>
  );
};

export default CustomerActionCard;
