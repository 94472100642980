import React, { Fragment, useState, useRef, useEffect } from "react";

import Portal from "./Portal";

const Modal = ({
  open,
  onClose,
  children,
  header,
  footer,
  type,
  dismissable = true,
}) => {
  const [active, setActive] = useState(false);
  const backdrop = useRef(null);

  useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const clickHandler = e => e.target === current && onClose();

    if (current && dismissable) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector("#root").setAttribute("inert", "true");
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
    };
  }, [open, onClose, dismissable]);

  return (
    <Fragment>
      {(open || active) && (
        <Portal>
          <div className="modal d-block" ref={backdrop}>
            <div
              className="modal-dialog modal-dialog-centered mkit-modal"
              role="document"
            >
              <div className="modal-content shadow">
                {header && (
                  <div
                    className={`modal-header ${type ? `bg-${type}-soft` : ""}`}
                  >
                    <h3 className="mkit-h3 m-0">{header}</h3>
                    <button className="close" onClick={onClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                <div className="modal-body">{children}</div>
                {footer && <div className="modal-footer">{footer}</div>}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </Fragment>
  );
};

export default Modal;
