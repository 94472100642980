import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomerDetailPage from "./customers/CustomerDetailPage";
import CustomerListPage from "./customers/CustomerListPage";

const CustomerSection = () => (
  <Switch>
    <Route path="/customers/:id" component={CustomerDetailPage} />
    <Route component={CustomerListPage} />
  </Switch>
);

export default CustomerSection;
