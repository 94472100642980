import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../shared/api";
import PageHeader from "../../shared/components/PageHeader";
import FormWrapper from "../../shared/components/FormWrapper";
import DocumentDrop from "./createUpdate/DocumentDrop";
import RewardCodeTableContent from "./listPage/RewardCodeTableContent";
import RewardCodeTableHeader from "./listPage/RewardCodeTableHeader";
import GenericTable from "../../shared/components/GenericTable";
import PagePagination from "../../shared/components/PagePagination";

import { extractAPIErrorString } from "../../shared/utils/extractErrors";
import { useDispatch } from "react-redux";

import { addToServiceFeedback } from "../serviceFeedback/actions";

const RewardCodeCreatePage = () => {
  const [existingCodes, setExistingCodes] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [reloadRewardCodes, setReloadRewardCodes] = useState(0);
  const dispatch = useDispatch();

  function onRewardCodesAdded() {
    setReloadRewardCodes(reloadRewardCodes + 1);
  }

  const { id } = useParams();

  const count = 50;

  useEffect(() => {
    api
      .get(`/rewards/${id}/codes`, {
        params: { page: page, count },
      })
      .then(
        ({ data }) => {
          setExistingCodes(data.data);
          if (data.data.length !== 0) {
            setMaxPage(Math.ceil(data.meta.total_count / count));
          }
        },
        extractAPIErrorString(error => {
          dispatch(
            addToServiceFeedback({
              type: "warning",
              message: error,
            })
          );
        })
      )
      .catch(err => {
        console.error("Error: ", err);
      });
  }, [page, reloadRewardCodes, id, dispatch]);

  return (
    <FormWrapper>
      <PageHeader
        backButton="Back to reward"
        backButtonUrl={`/rewards/${id}/edit`}
        pretitle="dusk admin"
      >
        Upload Reward Codes
      </PageHeader>
      <DocumentDrop
        label="Reward code CSV"
        name="reward_code_file"
        imageType="reward_category"
        onRewardCodesAdded={onRewardCodesAdded}
      />
      {existingCodes ? (
        <div>
          <h1 className="mt-5">Existing codes</h1>
          <GenericTable
            data={existingCodes}
            isLoading={false}
            renderHeaderRow={() => <RewardCodeTableHeader />}
            renderItem={code => (
              <RewardCodeTableContent code={code} key={code.id} />
            )}
          />
          {maxPage > 1 && (
            <PagePagination page={page} maxPage={maxPage} setPage={setPage} />
          )}
        </div>
      ) : (
        <div className="text-center mt-5 text-muted">
          <h3>Fetching existing codes</h3>
          <div className="spinner-border spinner-border-lg" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </FormWrapper>
  );
};

export default RewardCodeCreatePage;
