import React from "react";
import { Route, Switch } from "react-router-dom";
import VenueCollectionCreatePage from "./venueCollection/VenueCollectionCreatePage";
import VenueCollectionEditPage from "./venueCollection/VenueCollectionEditPage";
import VenueCollectionListPage from "./venueCollection/VenueCollectionListPage";

const VenueCollectionSection = () => (
  <Switch>
    <Route
      path="/venue-collections/create"
      component={VenueCollectionCreatePage}
    />
    <Route
      path="/venue-collections/:id/edit"
      component={VenueCollectionEditPage}
    />
    <Route component={VenueCollectionListPage} />
  </Switch>
);

export default VenueCollectionSection;
