import React, { useCallback, useMemo, useState } from "react";
import CardModal from "./CardModal";
import Dropzone from "react-dropzone";
import { dataURLForFile } from "shared/utilities";
import api from "shared/api";
import LoadingSpinner from "./LoadingSpinner";
import cx from "classnames";
import styles from "./ImageUploadModal.module.scss";
import RemovableMediaPreview from "./RemovableMediaPreview";

const ImageUploadModal = ({ isSingle, onClose, onConfirm, resize, show }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [inProgressCount, setInProgressCount] = useState(0);

  const onDrop = useCallback(
    acceptedFiles => {
      console.log(acceptedFiles);
      Promise.all(acceptedFiles.map(dataURLForFile)).then(dataStrings => {
        setInProgressCount(dataStrings.length);

        const uploadPayload = {
          images: dataStrings.map((img, idx) => ({
            encoded_image: img,
            filename: acceptedFiles[idx].name,
          })),
        };

        if (resize) {
          uploadPayload.resize = resize;
        }

        api
          .post("/media/upload-images", uploadPayload)
          .then(({ data }) => {
            setUploadedImages(currentImages => [
              ...(isSingle ? [] : currentImages),
              ...data.data,
            ]);
          })
          .finally(() => {
            setInProgressCount(count => count - dataStrings.length);
          });
      });
    },
    [isSingle, resize]
  );

  const displayTiles = useMemo(() => {
    const tileArray = [...uploadedImages];
    for (let idx = 0; idx < inProgressCount; idx++) {
      tileArray.push(false);
    }
    return tileArray;
  }, [uploadedImages, inProgressCount]);

  return (
    <CardModal onClose={onClose} show={show} title="Upload Image">
      {() => (
        <>
          <div className="card-body">
            {displayTiles.length > 0 && (
              <div className="row">
                {displayTiles.map((imageUrl, tileIdx) => (
                  <div
                    className="col-4 col-md-3 col-lg-2"
                    key={`tile-${imageUrl || tileIdx}`}
                  >
                    {imageUrl && (
                      <RemovableMediaPreview
                        className="mb-3"
                        imageUrl={imageUrl}
                        onRemove={() =>
                          setUploadedImages(
                            uploadedImages.filter(
                              (_value, idx) => idx !== tileIdx
                            )
                          )
                        }
                      />
                    )}
                    {!imageUrl && (
                      <div
                        className={cx([
                          "border border-light mb-3",
                          styles.loadingSquare,
                        ])}
                      >
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <Dropzone
              accept="image/png, image/jpg, image/jpeg"
              onDrop={onDrop}
              {...(isSingle ? { multiple: false, maxFiles: 1 } : {})}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="dropzone dropzone-single dz-clickable"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="dz-default dz-message">
                      <button className="dz-button" type="button">
                        Drop image here, or click for file selector.
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-white" onClick={onClose} type="button">
              Cancel
            </button>
            <button
              className="btn btn-primary ms-3"
              disabled={uploadedImages.length === 0}
              onClick={() => {
                onConfirm(uploadedImages);
                setUploadedImages([]);
                setInProgressCount(0);
              }}
              type="button"
            >
              Confirm
            </button>
          </div>
        </>
      )}
    </CardModal>
  );
};

export default ImageUploadModal;
