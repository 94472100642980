import React from "react";
import styles from "./InfoCardThirdPartyList.module.scss";
import cx from "classnames";

const InfoCardThirdPartyList = ({ items }) => (
  <div className="d-grid gap-2">
    {items.map((item, idx) => (
      <button
        onClick={item.onClick}
        className={cx("btn", styles[item.buttonClass] || "btn-primary")}
        key={idx}
      >
        <span className={`fe ${item.icon} pe-2`} />
        {item.label}
      </button>
    ))}
  </div>
);

export default InfoCardThirdPartyList;
