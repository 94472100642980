import { useField, useFormikContext } from "formik";
import React, { useMemo } from "react";
import Select from "react-select";
import cx from "classnames";
import InputField from "./InputField";

const SpecialSelect = ({ className, options, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const onBlur = () => {
    setFieldTouched(props.name, true);
  };

  const optionLookup = useMemo(
    () =>
      (options || []).reduce(
        (acc, option) => (acc[String(option.value)] = option) && acc,
        {}
      ),
    [options]
  );

  const fieldValue = useMemo(() => {
    if (!options) {
      return null;
    }

    // If we have an array of values, convert it to what the select component
    // needs.
    if (Array.isArray(field.value)) {
      return field.value.map(multiVal =>
        typeof multiVal === "object" ? multiVal : optionLookup[String(multiVal)]
      );
    }

    // If it's an object, we assume it's in the correct format and return as-is
    if (typeof field.value === "object") {
      return field.value;
    }

    // Otherwise we check if the value can be found in our options.
    return optionLookup[String(field.value)] || "";
  }, [field.value, options, optionLookup]);

  const onChange = val => {
    if (!val) {
      setFieldValue(props.name, []);
      return;
    }

    if (Array.isArray(val)) {
      setFieldValue(
        props.name,
        val.map(innerVal => {
          if (typeof innerVal === "object") {
            return innerVal.value;
          } else {
            return innerVal;
          }
        })
      );
    } else {
      if (props.name === "available_cities") {
        setFieldValue(props.name, []);
      } else {
        setFieldValue(props.name, val.value);
      }
    }
  };

  return (
    <InputField {...{ ...props, meta }}>
      <Select
        defaultValue={null}
        className={cx(
          className,
          meta && meta.touched && meta.error && "is-invalid"
        )}
        value={fieldValue}
        options={options}
        onBlur={onBlur}
        onChange={onChange}
        {...props}
        styles={{ menu: provided => ({ ...provided, zIndex: 1000 }) }}
      />
    </InputField>
  );
};

export default SpecialSelect;
