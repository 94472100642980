import React from "react";
import moment from "moment";
import BootstrapCard from "shared/components/BootstrapCard";
import InfoCardKeyValueList from "./InfoCardKeyValueList";
import InfoCardValueList from "./InfoCardValueList";
import InfoCardThirdPartyList from "./InfoCardThirdPartyList";

const CustomerInfoCard = ({ customer }) => {
  return (
    <BootstrapCard>
      <h3 className="text-truncate">
        {customer.first_name} {customer.last_name}
      </h3>
      <InfoCardValueList items={[customer.email, customer.id]} />
      <InfoCardThirdPartyList
        items={[
          {
            label: "Mixpanel",
            onClick: () =>
              window.open(
                `https://mixpanel.com/project/2082171/view/135703/app/profile#distinct_id=${customer.id}`
              ),
            buttonClass: "btnIndigo",
            icon: "fe-more-horizontal",
          },
          {
            label: "Intercom",
            onClick: () =>
              window.open(
                `https://app.intercom.com/a/apps/qrkcrzo6/users/segments/all-users?searchTerm=${customer.email}`
              ),
            buttonClass: "",
            icon: "fe-message-circle",
          },
          {
            label: "Vero",
            onClick: () =>
              window.open(
                `https://app.getvero.com/customers?search=${customer.email}`
              ),
            buttonClass: "btnOrange",
            icon: "fe-send",
          },
        ]}
      />
      <InfoCardKeyValueList
        items={[
          {
            label: "Lifetime Points",
            value: customer.lifetime_reward_points,
          },
          {
            label: "Reward Points",
            value: customer.reward_points,
          },
          {
            label: "Referral Code",
            value: customer.referral_code,
          },
          { label: "Gender", value: customer.gender },
          {
            label: "Date of Birth",
            value: moment(customer.date_of_birth).format("Do MMM YYYY"),
          },
          {
            label: "Joined",
            value: moment(customer.created_at).format("Do MMM YYYY"),
          },
          {
            label: "Last Offer Reset",
            value: customer.last_reset_at
              ? moment(customer.last_reset_at).format("HH:mm, Do MMM YYYY")
              : "N/A",
          },
        ]}
      />
    </BootstrapCard>
  );
};

export default CustomerInfoCard;
