import { useField } from "formik";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

const MultiSelectTextField = ({
  className,
  inputClassName,
  label,
  name,
  createOption = val => val,
  mapValue = val =>
    typeof val === "string"
      ? {
          label: val,
          value: val,
          new: true,
        }
      : val,
  ...props
}) => {
  const [field, , { setValue }] = useField(name);
  const [inputValue, setInputValue] = useState("");
  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      <CreatableSelect
        className={inputClassName}
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        onChange={(_, meta) => {
          if (meta.action === "remove-value") {
            setValue(
              meta.removedValue.new
                ? field.value.filter(
                    val => mapValue(val).value !== meta.removedValue.value
                  )
                : field.value.map(val =>
                    val === meta.removedValue ? { ...val, removed: true } : val
                  )
            );
          }
        }}
        onInputChange={input => setInputValue(input)}
        onKeyDown={event => {
          if (!inputValue) {
            return;
          }

          if (["Enter", "Tab"].indexOf(event.key) !== -1) {
            // Prevent Duplicates!
            if (
              !field.value
                .map(mapValue)
                .find(({ label: innerLabel }) => innerLabel === inputValue)
            ) {
              setValue([...field.value, createOption(inputValue)]);
            }
            setInputValue("");
            event.preventDefault();
          }
        }}
        value={field.value.map(mapValue).filter(val => !val?.removed)}
        {...props}
      />
    </div>
  );
};

export default MultiSelectTextField;
