import React, { useEffect } from "react";
import CopyButton from "./CopyButton";
import _ from "lodash";

const Toast = ({ id, type, title, message, error, onClose }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose(id);
    }, 5000);
  }, [onClose, id]);

  return (
    <div
      className="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className={`toast-header bg-${type}-soft`}>
        <i
          className={`fe ${
            type === "danger" ? "fe-alert-circle" : "fe-check-circle"
          } text-${type} me-2 fw-bold`}
        />
        <strong class="me-auto">{title || _.startCase(type)}</strong>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={() => onClose(id)}
        />
      </div>
      <div class="toast-body">
        {Array.isArray(message) ? (
          <ul className="ps-4">
            {message.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          message
        )}
        {error && (
          <CopyButton className="mt-2" text={JSON.stringify(error)}>
            Copy error details
          </CopyButton>
        )}
      </div>
    </div>
  );
};

export default Toast;
