import React from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import ImageUploadField from "shared/components/ImageUploadField";

const MapMarkerIconPair = ({ prefix, title }) => (
  <BootstrapCard title={title}>
    <div className="row">
      <div className="col-6 d-flex flex-column align-items-start">
        <ImageUploadField
          name={`${prefix}_image_url`}
          label="Normal"
          alt="Normal Marker"
          isSingle={true}
          resize={{ width: 120, height: 120 }}
          imageSize={{ width: 40, height: 40 }}
        />
      </div>
      <div className="col-6 d-flex flex-column align-items-start">
        <ImageUploadField
          name={`${prefix}_focused_image_url`}
          label="Focused"
          alt="Focused Marker"
          isSingle={true}
          resize={{ width: 180, height: 180 }}
          imageSize={{ width: 60, height: 60 }}
        />
      </div>
    </div>
  </BootstrapCard>
);

export default MapMarkerIconPair;
