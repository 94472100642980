import React, { useContext } from "react";
import FormWizard, { WizardContext } from "shared/components/FormWizard";
// import FormWizardHeader, {
//   FormWizardTab
// } from "shared/components/FormWizard/FormWizardHeader";
import Button from "shared/components/Button";
import WizardExample2 from "./WizardExample2";

const FormWizardBody1 = () => {
  const { stepNext, current } = useContext(WizardContext);
  return (
    <div className="card">
      <div className="card-body">
        <div>
          <h2>{`Section ${current + 1}`}</h2>
          <p>This page has quite a lot of things going on</p>
        </div>
        <div className="d-flex justify-content-end">
          <Button onClick={() => stepNext(true)}>Go to the next page...</Button>
        </div>
      </div>
    </div>
  );
};

// const FormWizardBody2 = () => {
//   const { stepPrevious, stepNext } = useContext(WizardContext);
//   return (
//     <div className="card-body">
//       <div>
//         <h2>This is a completed form.</h2>
//         <p>You can navigate through all the steps</p>
//       </div>
//       <div>
//         <div className="btn-group">
//           <Button onClick={() => stepPrevious()}>Previous</Button>
//           <Button onClick={() => stepNext(true)}>Next</Button>
//         </div>
//       </div>
//     </div>
//   );
// };

const WizardLibrary = () => {
  return (
    <section className="mb-5">
      <h2>The Wizard</h2>
      <p className="text-muted">Outside of the card</p>
      <FormWizard totalSteps={4}>
        <FormWizardBody1 />
      </FormWizard>
      <p className="text-muted">And here we put it inside the card</p>
      <WizardExample2 />
    </section>
  );
};

export default WizardLibrary;
