import TextAreaField from "app/venues/createEditPages/TextAreaField";
import React from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import DailyRedemptionLimitsField from "shared/components/formFields/DailyRedemptionLimitsField";
import DateTimeField from "shared/components/formFields/DateTimeField";
import SwitchField from "shared/components/formFields/SwitchField";
import TextField from "shared/components/formFields/TextField";

const CampaignDetailForm = () => (
  <>
    <BootstrapCard title="Details">
      <div className="row">
        <div className="col-12">
          <TextField className="form-group" label="Campaign name" name="name" />
        </div>
        <div className="col-12">
          <TextAreaField
            className="form-group"
            label="Description"
            name="description"
          />
        </div>
        <div className="col-12 col-md-6">
          <DateTimeField
            className="form-group"
            label="Scheduled start date"
            name="published_from"
          />
        </div>
        <div className="col-12 col-md-6">
          <DateTimeField
            className="form-group"
            label="Expiry date"
            name="published_until"
          />
        </div>
        <div className="col-12">
          <SwitchField
            className="form-group"
            label="Require Card Linking"
            name="offers_require_card_link"
          />
        </div>
      </div>
    </BootstrapCard>

    <BootstrapCard title="Limits">
      <div className="row">
        <div className="col-12 col-md-6">
          <DailyRedemptionLimitsField
            name="daily_redemption_limits"
            baseTooltip="Total number of drinks that can be redeemed each day. If empty, there is no limit on redemptions per day."
            dailyTooltip="Apply limits to specific days of the week. If day is not entered, it will refer to daily limit."
          />
        </div>
        <div className="col-12 col-md-6">
          <TextField
            className="form-group"
            label="Total drink limit"
            name="total_redemption_limit"
            type="number"
            tooltip="Total number of drinks that can be redeemed for this campaign. Offer will be display as 'run out' in-app once limit is reached."
          />
        </div>
      </div>
    </BootstrapCard>
  </>
);

export default CampaignDetailForm;
