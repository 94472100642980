import React from "react";
import Helmet from "react-helmet";
import FormPageHeader from "shared/components/FormPageHeader";
import OfferForm from "./createEditPages/OfferForm";

const OfferCreatePage = () => {
  return (
    <div>
      <Helmet>
        <title>Create Offer</title>
      </Helmet>

      <OfferForm
        renderTitleComponent={({ isSubmitting }) => (
          <FormPageHeader isSubmitting={isSubmitting} pretitle="offers">
            Create Offer
          </FormPageHeader>
        )}
      />
    </div>
  );
};

export default OfferCreatePage;
