import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api";
import FormSpinner from "./FormSpinner";
import FormWrapper from "./FormWrapper";
import PageHeader from "./PageHeader";
import { useDispatch } from "react-redux";
import { addToServiceFeedback } from "../../app/serviceFeedback/actions";
import {
  extractAPIErrorString,
  extractAPIErrorStringNoCB,
} from "../utils/extractErrors";

const GenericEntityEditPage = ({
  apiBasePath,
  relevantKeys,
  entityName,
  transformEntityForForm = e => e,
  HeaderComponent,
  FormComponent,
}) => {
  const [entity, setEntity] = useState(null);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    if (entity && entity.id === parseInt(id, 10)) {
      return;
    }

    api.get(`${apiBasePath}/${id}`).then(
      ({ data }) => {
        setEntity(data.data);
      },
      extractAPIErrorString(error => {
        dispatch(
          addToServiceFeedback({
            type: "warning",
            message: error,
          })
        );
      })
    );
  }, [apiBasePath, id, entity, dispatch]);

  const transformedEntity = useMemo(
    () => (entity ? transformEntityForForm(entity) : null),
    [entity, transformEntityForForm]
  );

  const onFormSubmit = (formData, { setSubmitting }) => {
    setSubmitting(true);

    const apiPayload = {};

    // Put only the keys the API cares about into the update payload.
    // The API is a meanie and will cancel us if we provide too many properties
    // #cancelculture
    Object.keys(formData)
      .filter(key => relevantKeys.indexOf(key) !== -1)
      .forEach(key => (apiPayload[key] = formData[key]));

    return new Promise(async resolve => {
      api
        .put(`${apiBasePath}/${id}`, apiPayload)
        .then(
          ({ data }) => {
            dispatch(
              addToServiceFeedback({
                type: "success",
                message: `${entityName} has been successfully updated`,
              })
            );
            setEntity(data.data);
            resolve();
          },
          err => {
            dispatch(
              addToServiceFeedback({
                type: "warning",
                message: extractAPIErrorStringNoCB(err),
              })
            );
            resolve();
          }
        )
        .finally(() => setSubmitting(false));
    });
  };

  return (
    <FormWrapper>
      {HeaderComponent ? (
        <HeaderComponent e={entity} />
      ) : (
        <PageHeader pretitle="dusk admin">Edit {entityName}</PageHeader>
      )}
      {!entity && <FormSpinner />}
      {entity && (
        <FormComponent
          initialValues={transformedEntity}
          onFormSubmit={onFormSubmit}
        />
      )}
    </FormWrapper>
  );
};

export default GenericEntityEditPage;
