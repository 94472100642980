import React from "react";
import cx from "classnames";

import styles from "./ContentContainer.module.scss";

const ContentContainer = ({ children }) => (
  <div className={cx("main-content", styles.container)}>
    <div className={cx("container", styles.innerContainer)}>{children}</div>
  </div>
);

export default ContentContainer;
