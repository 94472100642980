import { createActions } from "redux-actions";
import {
  ADD_TO_SERVICE_FEEDBACK,
  REMOVE_FROM_SERVICE_FEEDBACK,
} from "./actionTypes";

const { addToServiceFeedback, removeFromServiceFeedback } = createActions(
  ADD_TO_SERVICE_FEEDBACK,
  REMOVE_FROM_SERVICE_FEEDBACK
);

export { addToServiceFeedback, removeFromServiceFeedback };
