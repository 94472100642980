import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from "use-query-params";

import HomePage from "../HomePage";
import ContentContainer from "./ContentContainer";
import NavigationSidebar from "./NavigationSidebar";
import OuterContainer from "./OuterContainer";
import { useSelector } from "react-redux";
import AuthManager from "./AuthManager";
import AuthPage from "../auth/AuthPage";
import CustomerSection from "../CustomerSection";
import AdminUserSection from "../AdminUserSection";
import CitySection from "../CitySection";
import GuidesSection from "../GuidesSection";
import RewardCategorySection from "../RewardCategorySection";
import RewardBrandSection from "../RewardBrandSection";
import RewardSection from "../RewardSection";
import RewardCollectionSection from "../RewardCollectionSection";
import RewardCodeSection from "../RewardCodeSection";
import ChangePassword from "../profile/ChangePassword";
import UserProfilePage from "../profile/UserProfilePage";
import ServiceFeedbackLayer from "../serviceFeedback/ServiceFeedbackLayer";
import VenueSection from "../VenueSection";
import OfferSection from "../OfferSection";
import CardListPage from "../cards/CardListPage";
import ComponentLibrary from "../ComponentLibrary";
import ErrorBoundary from "shared/components/ErrorBoundary";
import CampaignSection from "../CampaignSection";
import VenueCollectionSection from "app/VenueCollectionSection";
import VenueChainSection from "app/VenueChainSection";
import VenueCategoryListPage from "app/venueCategories/VenueCategoryListPage";

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

const AppRouter = () => {
  const { isLoggedIn } = useSelector(s => s.auth);
  return (
    <QueryParamProvider
      ReactRouterRoute={Route}
      stringifyOptions={queryStringifyOptions}
    >
      <OuterContainer>
        <AuthManager />
        {isLoggedIn && <NavigationSidebar />}
        <ContentContainer>
          <ErrorBoundary>
            {!isLoggedIn && <AuthPage />}
            {isLoggedIn && (
              <Switch>
                <Route path="/customers" component={CustomerSection} />
                <Route path="/cities" component={CitySection} />
                <Route path="/guides" component={GuidesSection} />
                <Route path="/venues" component={VenueSection} />
                <Route path="/offers" component={OfferSection} />
                <Route path="/campaigns" component={CampaignSection} />
                <Route
                  path="/venue-categories"
                  component={VenueCategoryListPage}
                />
                <Route
                  path="/venue-collections"
                  component={VenueCollectionSection}
                />
                <Route path="/venue-chains" component={VenueChainSection} />
                <Route
                  path="/reward-categories"
                  component={RewardCategorySection}
                />
                <Route path="/reward-brands" component={RewardBrandSection} />
                <Route path="/rewards" component={RewardSection} />
                <Route
                  path="/reward-collections"
                  component={RewardCollectionSection}
                />
                <Route path="/reward-codes" component={RewardCodeSection} />

                <Route path="/admin-users" component={AdminUserSection} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/profile" component={UserProfilePage} />
                <Route path="/cards" component={CardListPage} />
                <Route path="/component-library" component={ComponentLibrary} />

                <Route path="/" exact component={HomePage} />
              </Switch>
            )}
          </ErrorBoundary>
          <ServiceFeedbackLayer />
        </ContentContainer>
      </OuterContainer>
    </QueryParamProvider>
  );
};

export default AppRouter;
