import { useField } from "formik";
import React, { useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RemovableMediaPreview from "shared/components/RemovableMediaPreview";

const SortableMedia = ({ name }) => {
  const [{ value: mediaUrls }, , { setValue }] = useField(name);

  const onDragEnd = useCallback(
    ({ source, destination }) => {
      const newMediaUrls = [...(mediaUrls || [])];
      const removed = newMediaUrls.splice(source.index, 1);
      newMediaUrls.splice(destination.index, 0, ...removed);
      setValue(newMediaUrls);
    },
    [mediaUrls, setValue]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable direction="horizontal" droppableId="sortable-media">
        {provided => (
          <div
            className="row mb-3"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {(mediaUrls || []).map((imageUrl, imageIdx) => (
              <Draggable draggableId={imageUrl} index={imageIdx} key={imageUrl}>
                {draggableProvided => (
                  <div
                    className="col-4 col-lg-3"
                    key={imageUrl}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <RemovableMediaPreview
                      className="mb-3"
                      imageUrl={imageUrl}
                      onRemove={() =>
                        setValue(
                          mediaUrls.filter((_url, idx) => imageIdx !== idx)
                        )
                      }
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableMedia;
