import { useField } from "formik";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import cx from "classnames";
import InputMask from "react-input-mask";

const MaskedTextField = ({
  className,
  inputClassName,
  label,
  mask,
  type = "text",
  ...props
}) => {
  const [field, meta] = useField(props.name);

  const id = useMemo(() => props.id || uuidv4(), [props.id]);

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
      )}
      <InputMask
        className={cx(
          "form-control",
          meta?.touched && meta?.error && "is-invalid",
          inputClassName
        )}
        id={id}
        mask={mask}
        type={type}
        {...field}
        {...props}
      />
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default MaskedTextField;
