import React from "react";
import cx from "classnames";

const MediaItem = ({ alt, className, mediaUrl, style }) => {
  const extension = mediaUrl.split(".").pop();

  if (["mp4"].indexOf(extension) !== -1) {
    return (
      <video
        className={cx("w-100 rounded", className)}
        muted
        onClick={({ target }) => {
          console.log(target);
          target.paused ? target.play() : target.pause();
        }}
        playsInline
        src={mediaUrl}
        style={{ objectFit: "cover", position: "absolute", height: "100%" }}
      />
    );
  }

  return (
    <img
      alt={alt}
      className={cx("w-100 rounded", className)}
      src={mediaUrl}
      style={style}
    />
  );
};

export default MediaItem;
