import React from "react";
import { findIndex, uniq } from "lodash";
import moment from "moment";

export const dataURLForFile = f =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = evt => {
      resolve(evt.target.result);
    };

    reader.onerror = err => {
      reject(err);
    };

    reader.readAsDataURL(f);
  });

export const renderTime = (rawTime, type = "datetime") => {
  if (!rawTime) return null;
  switch (type) {
    case "date":
      return moment(rawTime, moment.ISO_8601).format(`DD/MM/YYYY`);
    case "time":
      return moment(rawTime, moment.ISO_8601).format(`HH:mm`);
    case "datetime":
      return (
        <span>
          {moment(rawTime, moment.ISO_8601).format(`DD/MM/YYYY`)}
          <br />
          {moment(rawTime, moment.ISO_8601).format(`HH:mm`)}
        </span>
      );
    default:
      return rawTime;
  }
};

export const formatDateTime = dtString =>
  moment(dtString).format("h:mma, Do MMM YYYY");

export const processPageMeta = ({ current_page, total_count, count }) => {
  const pageCount = Math.ceil(total_count / count) || 0;
  return {
    pageSize: count,
    pageIndex: current_page - 1,
    pageCount,
  };
};

// unpublished, scheduled, live, expired
export const getCampaignStatus = data => {
  const { published_from, published_until, deleted_at } = data;
  if (deleted_at) {
    return "archived";
  }
  if (!published_from) {
    return "unpublished";
  }
  if (moment() < moment(published_from)) {
    return "scheduled";
  }
  if (moment() >= moment(published_from) && !published_until) {
    return "live";
  }
  if (
    moment() >= moment(published_from) &&
    moment() <= moment(published_until)
  ) {
    return "live";
  }
  if (moment() >= moment(published_until)) {
    return "expired";
  }
  // This point onwards is urh..!
  if (!published_until && !published_from) {
    return "unknown";
  }
  return "strange..";
};

/**
 *
 * @param {number[]} existingArray
 * @param {object[]} newArray
 */
export const sortDataToEdit = (existingArray, newArray) => {
  const combined = uniq([...newArray.map(each => each.id), ...existingArray]);
  const forDelete = [];
  const forInsert = [];
  combined.forEach(each => {
    // check if should be deleted
    if (findIndex(newArray, o => o.id === each) === -1) {
      forDelete.push(each);
    } else {
      // check if should be inserted
      if (findIndex(existingArray, o => o === each) === -1) {
        forInsert.push(each);
      }
    }
  });
  return {
    forDelete,
    forInsert,
  };
};

export const rgbaToObject = rgba => {
  const split = rgba.replace("rgba(", "").replace(")", "").split(",");
  if (split.length !== 4) return false;
  return {
    r: split[0],
    g: split[1],
    b: split[2],
    a: split[3],
  };
};

export const rgbaObjectToString = rgba => {
  if (!rgba) return false;
  const { r, g, b, a } = rgba;
  return `rgba(${[r, g, b, a].join(",")})`;
};

export const getOfferStatus = ({ deleted_at, is_paused }) => {
  if (!deleted_at && !is_paused) {
    return "live";
  } else if (!deleted_at && is_paused) {
    return "unpublished";
  } else if (deleted_at) {
    return "historic";
  }
  return "unknown";
};

export const getVenueStatus = ({ deleted_at, published_at }) => {
  if (!deleted_at && published_at) {
    if (moment(published_at) < moment()) {
      return "live";
    }
    return "scheduled";
  } else if (!deleted_at && !published_at) {
    return "unpublished";
  } else if (deleted_at) {
    return "archived";
  }
  return "unknown";
};

export const compareCities = (initialInput, finalInput) => {
  let existingIds = initialInput.reduce((ids, area) => {
    ids.push(area.id);
    ids.push(...area.children.map(({ id }) => id));
    return ids;
  }, []);
  // find deletions
  // find insertions
  const insertions = [];
  finalInput.forEach(area => {
    if (!area.id) {
      insertions.push({
        parent_id: null,
        name: area.name,
        children: area.children.map(subArea => subArea.name),
      });
    } else {
      existingIds = existingIds.filter(o => o !== area.id);
      area.children.forEach(subArea => {
        if (!subArea.id) {
          insertions.push({
            parent_id: area.id,
            name: subArea.name,
          });
        } else {
          existingIds = existingIds.filter(o => o !== subArea.id);
        }
      });
    }
  });

  return {
    forDelete: existingIds,
    forInsert: insertions,
  };
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const dayFromIdx = idx =>
  [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ][idx];
