import React from "react";
import Helmet from "react-helmet";
import GenericEntityEditPage from "../../shared/components/GenericEntityEditPage";
import RewardForm from "./createUpdate/RewardForm";
import PageHeader from "../../shared/components/PageHeader";
import Button from "../../shared/components/Button";
import { Link } from "react-router-dom";
import StatusBadge from "../../shared/components/StatusBadge";

const RewardHeader = ({ e }) => (
  <PageHeader
    pretitle="dusk admin"
    renderAdditionalComponents={() => (
      <Link to="codes">
        <Button buttonType="primary btn-lg float-right">Manage codes</Button>
      </Link>
    )}
  >
    Edit Reward {e && <StatusBadge {...e} />}
  </PageHeader>
);

const RewardEditPage = () => (
  <>
    <Helmet>
      <title>Edit Reward</title>
    </Helmet>
    <GenericEntityEditPage
      apiBasePath="/rewards"
      adminBasePath="/rewards"
      relevantKeys={[
        "allow_duplicate_codes",
        "is_test",
        "brand_id",
        "category_id",
        "customer_redemption_limit",
        "description",
        "image_url",
        "name",
        "points_cost",
        "read_more",
        "terms",
        "published_at",
        "expires_at",
      ]}
      entityName="Reward"
      transformEntityForForm={e => ({
        id: e.id,
        name: e.name,
        description: e.description,
        image_url: e.image_url,
        read_more: e.read_more || undefined,
        terms: e.terms || undefined,
        points_cost: e.points_cost,
        brand_id: e.brand ? e.brand.id : undefined,
        category_id: e.category ? e.category.id : undefined,
        allow_duplicate_codes: e.allow_duplicate_codes || false,
        is_test: e.is_test || false,
        customer_redemption_limit: e.customer_redemption_limit || undefined,
        published_at: e.published_at,
        expires_at: e.expires_at || undefined,
      })}
      FormComponent={RewardForm}
      HeaderComponent={RewardHeader}
    />
  </>
);

export default RewardEditPage;
