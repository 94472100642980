import React, { useMemo } from "react";
import { useField } from "formik";
import BootstrapCard from "shared/components/BootstrapCard";
import MultipleSelectField from "shared/components/formFields/MultipleSelectField";
import VenueSelectionCard from "shared/components/VenueSelectionCard";
import TextField from "shared/components/formFields/TextField";
import DeleteVenueSetButton from "./DeleteVenueSetButton";
import CampaignVenueSetVenueTable from "./CampaignVenueSetVenueTable";

const CampaignVenueSetField = ({
  name,
  offers,
  chains,
  collections,
  venueLookup,
  onDeleteSet,
  onAddVenues,
}) => {
  const [field, , { setValue }] = useField(name);
  const handleAddVenues = selectedVenues => {
    const newVenues = selectedVenues.filter(
      venue => !field.value.venue_ids.includes(venue.id)
    );
    const venueSet = {
      ...field.value,
      venue_ids: [...field.value.venue_ids, ...newVenues.map(({ id }) => id)],
    };
    setValue(venueSet);
    onAddVenues(newVenues);
  };
  const venues = useMemo(
    () => field.value.venue_ids?.map(id => venueLookup[id]).filter(Boolean),
    [field, venueLookup]
  );
  return (
    <BootstrapCard
      renderTitle={() => (
        <div className="row align-items-center justify-content-between">
          <TextField
            className="col-12 col-md-6 col-lg-5"
            inputClassName="form-control"
            type="text"
            name={`${name}.name`}
            placeholder="Set name"
          />
          <DeleteVenueSetButton handleDelete={onDeleteSet} />
        </div>
      )}
    >
      <MultipleSelectField
        className="form-group"
        label="Offers"
        name={`${name}.offer_ids`}
        options={offers || []}
      />
      <VenueSelectionCard
        chains={chains}
        collections={collections}
        onVenuesSelected={selectedVenues => handleAddVenues(selectedVenues)}
      />
      <CampaignVenueSetVenueTable
        venues={venues}
        onVenueRemove={venue => {
          const venueIds = field.value.venue_ids?.filter(id => id !== venue.id);
          setValue({ ...field.value, venue_ids: venueIds });
        }}
      />
    </BootstrapCard>
  );
};

export default CampaignVenueSetField;
