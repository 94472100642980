import React from "react";

const SettingListItem = ({ children, icon, title, subtitle }) => (
  <div className="list-group-item">
    <div className="row align-items-center">
      <div className="col">
        {title && (
          <h4 className="font-weight-base mb-1">
            {icon && <span className={`fe fe-${icon} me-2`} />}
            {title}
          </h4>
        )}
        {subtitle && <small className="text-muted">{subtitle}</small>}
      </div>
      <div className="col-auto">{children}</div>
    </div>
  </div>
);

export default SettingListItem;
