import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../shared/api";
import GenericList from "../../shared/components/GenericList";
import PageHeader from "../../shared/components/PageHeader";
import RewardCategoryListItem from "./listPage/RewardCategoryListItem";

const RewardCategoryListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/reward-categories")
      .then(({ data }) => {
        setCategories(data.data);

        console.log("Categories: ", data.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto">
            <Link
              to="/reward-categories/create"
              className="btn btn-primary d-block"
            >
              <span className="fe fe-plus me-2" />
              Create a Category
            </Link>
          </div>
        )}
      >
        Reward Categories
      </PageHeader>
      <GenericList
        data={categories}
        isLoading={isLoading}
        renderItem={category => (
          <RewardCategoryListItem category={category} key={category.id} />
        )}
      />
    </div>
  );
};

export default RewardCategoryListPage;
