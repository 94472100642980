import React from "react";
import Helmet from "react-helmet";
import PageHeader from "../../shared/components/PageHeader";
import CityForm from "./createEditPages/CityForm";

const CityCreatePage = () => (
  <div>
    <Helmet>
      <title>Create City</title>
    </Helmet>
    <PageHeader pretitle="cities">Add City</PageHeader>

    <CityForm />
  </div>
);

export default CityCreatePage;
