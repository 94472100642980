import React, { useState } from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";
import SettingListItem from "shared/components/SettingListItem";

const UpdateRewardPointsActionItem = ({
  isLoading,
  onUpdatePoints,
  originalPoints = 0,
}) => {
  const [points, setPoints] = useState(originalPoints);
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <SettingListItem
      icon="star"
      title="Update Reward Points"
      subtitle="Update the user's reward points. Increases will also affect lifetime points."
    >
      {!isEditMode && (
        <button
          className="btn btn-sm btn-white"
          disabled={isLoading}
          onClick={() => setIsEditMode(true)}
        >
          {isLoading ? <LoadingSpinner className="mt-1" /> : "Update"}
        </button>
      )}
      {isEditMode && (
        <div className="input-group">
          <input
            className="form-control form-control-sm"
            type="number"
            placeholder="Points"
            value={points}
            onChange={e => setPoints(e.target.value)}
          />
          <button
            className="btn btn-sm btn-outline-success"
            disabled={isLoading}
            onClick={() => {
              setIsEditMode(false);
              onUpdatePoints(parseInt(points, 10));
            }}
            type="button"
          >
            {isLoading ? (
              <LoadingSpinner className="mt-1" />
            ) : (
              <span className="fe fe-check" />
            )}
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            disabled={isLoading}
            onClick={() => {
              setPoints(originalPoints);
              setIsEditMode(false);
            }}
            type="button"
          >
            <span className="fe fe-x" />
          </button>
        </div>
      )}
    </SettingListItem>
  );
};

export default UpdateRewardPointsActionItem;
