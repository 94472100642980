import { useField } from "formik";
import PropTypes from "prop-types";
import cx from "classnames";
import React, { useCallback, useState } from "react";
import api from "../api";
import { dataURLForFile } from "../utilities";
import { useDropzone } from "react-dropzone";
import { IMAGE_TYPES } from "shared/variables";
import { addToServiceFeedback } from "app/serviceFeedback/actions";
import { extractAPIErrorStringNoCB } from "shared/utils/extractErrors";
import { useDispatch } from "react-redux";

const imageSizes = {
  city: "1284 x 856",
  chain: "1284 x 1284",
  offer: "1284 x 1284",
  venue: "1284 x 1284",
  collection: "1284 x 1284",
  reward: "1284 x 1284",
  reward_brand: "180 x 180",
  reward_category: "600 x 600",
  reward_collection: "60 x 60",
  guide: "1284 x 1284",
  map_marker: "120 x 120 to 180 x 180",
  DEFAULT: "go wild!",
};

const ImageDrop = ({ className, imageType, ...props }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [field, meta, helpers] = useField(props);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    acceptedFiles => {
      const img = acceptedFiles[0];
      dataURLForFile(img)
        .then(dataStr => {
          setIsUploading(true);
          api
            .post("/media/upload-image", {
              byte_image: dataStr,
              image_type: imageType,
            })
            .then(({ data }) => {
              setIsUploading(false);
              helpers.setValue(data.data.image_url);
            })
            .catch(error => {
              console.log("ERRORED HERE", error.message);
              // throw Error(error);
              setIsUploading(false);
              dispatch(
                addToServiceFeedback({
                  type: "danger",
                  message: extractAPIErrorStringNoCB(error),
                })
              );
            });
        })
        .catch(err => {
          console.error("DATA URL Error: ", err);
          setIsUploading(false);
          dispatch(
            addToServiceFeedback({
              type: "danger",
              message: extractAPIErrorStringNoCB(err),
            })
          );
        });
    },
    [setIsUploading, imageType, helpers, dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg,image/png",
  });

  return (
    <div className="row">
      {field.value && (
        <div className="col-md-4">
          <label className="mb-1">Selected image</label>
          <img
            alt="City cover"
            src={field.value}
            style={{ borderRadius: 4, maxWidth: "100%" }}
          />
        </div>
      )}
      <div className="col">
        <div className="form-group">
          <label className="mb-1">{props.label || "Upload an image."}</label>
          <small className="form-text text-muted">
            {`Optimum image size: ${
              imageSizes[imageType] || imageSizes.DEFAULT
            }.`}
          </small>
          <div className="dropzone dropzone-single" {...getRootProps()}>
            <input
              {...getInputProps()}
              accept="image/png, image/jpg, image/jpeg"
            />
            <input
              type="hidden"
              className={cx(meta && meta.touched && meta.error && "is-invalid")}
              {...field}
            />
            {meta && meta.touched && meta.error && (
              <div className="invalid-feedback">{meta.error}</div>
            )}
            <div className="dz-default dz-message">
              {isUploading ? (
                <div className="spinner-border align-self-center" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span className="text-muted">
                  {isDragActive
                    ? "Drop here."
                    : "Drag & drop here, or click to select an image."}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ImageDrop.propTypes = {
  imageType: PropTypes.oneOf(IMAGE_TYPES),
};

export default ImageDrop;
