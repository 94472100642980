import React from "react";
import { components } from "react-select";
import styles from "./SelectCategoryOption.module.scss";
import cx from "classnames";

const SelectCategoryOption = props => (
  <components.Option {...props}>
    <div className="d-flex flex-row align-items-center">
      {props.data.raw?.icon_url && (
        <div className={cx(styles.iconContainer, "me-2")}>
          <img
            alt={props.label}
            className={styles.icon}
            src={props.data.raw.icon_url}
          />
        </div>
      )}
      <span>{props.label}</span>
    </div>
  </components.Option>
);

export default SelectCategoryOption;
