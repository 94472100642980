import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import api from "../../shared/api";
import GenericTable from "../../shared/components/GenericTable";
import PageHeader from "../../shared/components/PageHeader";
import RewardTableHeaderRow from "./listPage/RewardTableHeaderRow";
import RewardTableRow from "./listPage/RewardTableRow";

const RewardListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rewards, setRewards] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/rewards")
      .then(({ data }) => {
        setRewards(data.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <Helmet>
        <title>Rewards</title>
      </Helmet>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto">
            <Link to="/rewards/create" className="btn btn-primary d-block">
              <span className="fe fe-plus me-2" />
              Create a Reward
            </Link>
          </div>
        )}
      >
        Rewards
      </PageHeader>
      <GenericTable
        data={rewards}
        isLoading={isLoading}
        renderHeaderRow={() => <RewardTableHeaderRow />}
        renderItem={reward => (
          <RewardTableRow reward={reward} key={reward.id} />
        )}
      />
    </div>
  );
};

export default RewardListPage;
