import React from "react";
import styles from "./TableHeaderSortable.module.scss";
import cx from "classnames";

const TableHeaderSortable = ({ columns, sortParams, setSortParams }) => {
  const headerCells = columns.map((col, index) => {
    if (!col.hasOwnProperty("sortBy")) {
      return <th key={index}>{col.label}</th>;
    } else if (sortParams.sort === `-${col.sortBy}`) {
      return (
        <th key={index} className="fw-bold text-secondary">
          <div className="d-flex">
            {col.label}
            <span
              className={cx(
                styles["sort-icon"],
                "material-icons-outlined ms-2"
              )}
              onClick={() =>
                setSortParams({
                  ...sortParams,
                  sort: `+${col.sortBy}`,
                })
              }
            >
              arrow_downward
            </span>
          </div>
        </th>
      );
    } else if (sortParams.sort === `+${col.sortBy}`) {
      return (
        <th key={index} className="fw-bold text-secondary">
          <div className="d-flex">
            {col.label}
            <span
              className={cx(
                styles["sort-icon"],
                "material-icons-outlined ms-2"
              )}
              onClick={() => setSortParams({ sort: null })}
            >
              arrow_upward
            </span>
          </div>
        </th>
      );
    } else if (
      !sortParams.sort ||
      !sortParams.sort.match(`/[+-]${col.sortBy}/`)
    ) {
      return (
        <th
          key={index}
          className="fw-bold text-secondary"
          onClick={() => {
            setSortParams({
              ...sortParams,
              sort: `-${col.sortBy}`,
            });
          }}
        >
          <div className="d-flex">
            {col.label}
            <span
              className={cx(
                styles["sort-icon"],
                "material-icons-outlined ms-2"
              )}
            >
              height
            </span>
          </div>
        </th>
      );
    }
  });
  return (
    <thead>
      <tr>{headerCells}</tr>
    </thead>
  );
};

export default TableHeaderSortable;
