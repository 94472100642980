import React, { useCallback, useMemo } from "react";

const TablePaginationFooter = ({ currentPage, lastPage, onPageSelect }) => {
  const directPageOptions = useMemo(() => {
    const lowerPage = Math.max(1, currentPage - 2);
    const upperPage = Math.min(lastPage, currentPage + 2);

    return Array.from(
      { length: upperPage - lowerPage + 1 },
      (_, i) => i + lowerPage
    );
  }, [currentPage, lastPage]);

  const selectPage = useCallback(
    proposedPage => {
      if (
        proposedPage < 1 ||
        proposedPage > lastPage ||
        proposedPage === currentPage
      ) {
        return;
      }

      onPageSelect(proposedPage);
    },
    [currentPage, lastPage, onPageSelect]
  );

  return (
    <div className="card-footer d-flex justify-content-between">
      <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
        <li className="page-item">
          <button
            className="page-link ps-0 pe-4 border-end"
            onClick={() => selectPage(currentPage - 1)}
            type="button"
          >
            <i className="fe fe-arrow-left me-1" />
            Prev
          </button>
        </li>
      </ul>

      <ul className="list-pagination pagination pagination-tabs card-pagination">
        {directPageOptions.map(pageNumber => (
          <li
            className={pageNumber === currentPage ? "active" : null}
            key={pageNumber}
          >
            <button
              className="page"
              onClick={() => selectPage(pageNumber)}
              type="button"
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>

      <ul className="list-pagination-next pagination pagination-tabs card-pagination">
        <li className="page-item">
          <button
            className="page-link ps-4 pe-0 border-start"
            onClick={() => selectPage(currentPage + 1)}
            type="button"
          >
            Next
            <i className="fe fe-arrow-right ms-1" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TablePaginationFooter;
