import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import api from "shared/api";
import CardModal from "shared/components/CardModal";
import DateTimeField from "shared/components/formFields/DateTimeField";
import LoadingSpinner from "shared/components/LoadingSpinner";
import moment from "moment";
import WeeklyHoursField from "shared/components/formFields/WeeklyHoursField";
import DailyRedemptionLimitsField from "shared/components/formFields/DailyRedemptionLimitsField";
import TextField from "shared/components/formFields/TextField";

const UpdateVenueOfferAvailabilityModal = ({
  offer,
  onUpdated,
  onClose,
  show,
  venueId,
}) => {
  return (
    <CardModal
      onClose={onClose}
      show={show}
      title={`Update Offer Availability - ${offer?.name || "N/A"}`}
    >
      {() => (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              available_from: offer?.available_from
                ? moment(offer.available_from).toDate()
                : "",
              available_until: offer?.available_until
                ? moment(offer.available_until).toDate()
                : "",
              redeemable_times: offer?.custom_redemption_times || [],
              daily_redemption_limits: offer?.daily_redemption_limits || {
                base: "",
                days: [],
              },
              total_redemption_limit: offer?.total_redemption_limit || "",
            }}
            onSubmit={({
              available_from,
              available_until,
              redeemable_times,
              daily_redemption_limits,
              total_redemption_limit,
            }) =>
              new Promise(resolve => {
                const updatePayload = {
                  available_from: available_from
                    ? moment(available_from).utc().format()
                    : null,
                  available_until: available_until
                    ? moment(available_until).utc().format()
                    : null,
                  redeemable_times:
                    redeemable_times.length > 0 ? redeemable_times : null,
                  daily_redemption_limits:
                    daily_redemption_limits?.base ||
                    daily_redemption_limits?.days?.length
                      ? daily_redemption_limits
                      : null,
                  total_redemption_limit: total_redemption_limit || null,
                };

                api
                  .put(`/venues/${venueId}/offers/${offer.id}`, updatePayload)
                  .then(
                    ({ data }) => {
                      resolve();
                      onUpdated(data.data);
                    },
                    err => {
                      console.log(err);
                      resolve();
                    }
                  );
              })
            }
            validationSchema={Yup.object().shape({
              available_from: Yup.date().nullable().optional(),
              available_until: Yup.date().nullable().optional(),
              redeemable_times: Yup.array(),
            })}
          >
            {({ isSubmitting, values, errors }) => {
              console.log(errors);
              return (
                <Form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <DateTimeField
                          className="form-group"
                          label="Schedule start"
                          minDate={new Date()}
                          maxDate={values.available_until || null}
                          name="available_from"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <DateTimeField
                          className="form-group"
                          label="Schedule end"
                          minDate={values.available_from || new Date()}
                          name="available_until"
                        />
                      </div>
                      <hr />
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <DailyRedemptionLimitsField
                            name="daily_redemption_limits"
                            baseTooltip="Total number of drinks that can be redeemed each day, for this offer. If empty, there is no limit on redemptions per day"
                            dailyTooltip="Apply limits to specific days of the week. If day is not entered, it will refer to daily limit."
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <TextField
                          className="form-group"
                          label="Total drink limit"
                          name="total_redemption_limit"
                          type="number"
                          tooltip="Total number of drinks that can be redeemed for this offer, while it is on the venue. Offer will be display as 'run out' in-app once limit is reached."
                        />
                      </div>
                      <hr />
                      <div className="col-12">
                        <label className="form-label">
                          Override Redeemable Hours
                        </label>
                        <WeeklyHoursField name="redeemable_times" />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end">
                    <button
                      className="btn btn-white"
                      onClick={onClose}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button className="btn btn-primary ms-3" type="submit">
                      {isSubmitting ? <LoadingSpinner /> : "Confirm"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </CardModal>
  );
};

export default UpdateVenueOfferAvailabilityModal;
