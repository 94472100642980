import React, { useCallback, useState } from "react";
import api from "shared/api";
import CardModal from "shared/components/CardModal";
import LoadingSpinner from "shared/components/LoadingSpinner";

const RemoveOfferFromVenueModal = ({
  offerId,
  onRemoved,
  onClose,
  show,
  venueId,
  title = "Remove Offer",
  description = "Please confirm you'd like to remove this offer from this venue.",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    api
      .delete(`/venues/${venueId}/offers/${offerId}`)
      .then(() => onRemoved())
      .finally(() => setIsLoading(false));
  }, [isLoading, offerId, onRemoved, venueId]);

  return (
    <CardModal onClose={onClose} show={show} title={title}>
      {() => (
        <>
          <div className="card-body">
            <p>{description}</p>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-white" onClick={onClose} type="button">
              Cancel
            </button>
            <button
              className="btn btn-danger ms-3"
              onClick={onConfirm}
              type="button"
            >
              {isLoading ? <LoadingSpinner /> : "Confirm"}
            </button>
          </div>
        </>
      )}
    </CardModal>
  );
};

export default RemoveOfferFromVenueModal;
