import React from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";

const WizardStepper = ({
  isSaving,
  onBackClick,
  onNextClick,
  pageIdx,
  pageCount,
}) => (
  <div className="nav row align-items-center pb-5">
    <div className="col-auto">
      {pageIdx > 0 && !isSaving && (
        <button
          className="btn btn-md btn-white"
          onClick={onBackClick}
          type="button"
        >
          Back
        </button>
      )}
    </div>
    <div className="col text-center">
      <h6 className="text-uppercase text-muted mb-0">
        Step {pageIdx + 1} of {pageCount}
      </h6>
    </div>
    <div className="col-auto">
      {pageIdx < pageCount - 1 && (
        <button
          className="btn btn-md btn-primary"
          onClick={onNextClick}
          type="button"
        >
          Next
        </button>
      )}
      {pageIdx === pageCount - 1 && (
        <button
          className="btn btn-md btn-primary"
          disabled={isSaving}
          type="submit"
        >
          {isSaving ? <LoadingSpinner /> : "Save & Exit"}
        </button>
      )}
    </div>
  </div>
);

export default WizardStepper;
