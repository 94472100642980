import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import constants from "shared/constants";

const GooglePlaceIdSelector = ({ onPlaceSelect }) => (
  <div className="row align-items-center">
    <div className="col">
      <GooglePlacesAutocomplete
        apiKey={constants.googleApiKey}
        apiOptions={{ language: "en", region: "GB" }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["GB"],
          },
        }}
        minLengthAutocomplete={3}
        selectProps={{
          onChange: e => onPlaceSelect(e.value.place_id),
        }}
      />
    </div>
  </div>
);

export default GooglePlaceIdSelector;
