import React from "react";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import PageHeader from "../../shared/components/PageHeader";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import TableHeaderSortable from "shared/components/TableHeaderSortable";

const CustomerListPage = () => {
  const history = useHistory();

  const [sortParams, setSortParams] = useQueryParams({
    sort: withDefault(StringParam, null),
  });

  return (
    <div>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <PageHeader pretitle="dusk admin">Customers</PageHeader>
      <QueryStringListPageContents
        apiPath="/customers"
        sortParams={sortParams}
        setSortParams={setSortParams}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Name", sortBy: "first_name" },
              { label: "Email", sortBy: "email" },
              { label: "Last Seen", sortBy: "last_seen_at" },
              { label: "Registered", sortBy: "created_at" },
            ]}
            setSortParams={setSortParams}
            sortParams={sortParams}
          />
        )}
        renderRow={customer => (
          <tr
            key={customer.id}
            role="button"
            onClick={e => {
              if (e.target.className === "rowlink") {
                return;
              }
              history.push(`/customers/${customer.id}`);
            }}
          >
            <td>
              <Link className="rowlink" to={`/customers/${customer.id}`} />
              {[customer.first_name, customer.last_name]
                .filter(Boolean)
                .join(" ")}
              {customer.is_test_user && (
                <span className="badge bg-light ms-2">Test</span>
              )}
            </td>
            <td>{customer.email}</td>
            <td>
              {customer.last_seen_at
                ? moment(customer.last_seen_at).fromNow()
                : "N/A"}
            </td>
            <td>{moment(customer.created_at).format("Do MMM YYYY")}</td>
          </tr>
        )}
      />
    </div>
  );
};

export default CustomerListPage;
