import React from "react";
import cx from "classnames";

const TableImagePlacheolder = ({ className }) => (
  <div
    className={cx(
      "bg-light d-flex align-items-center justify-content-center table-row-img",
      className
    )}
  >
    <p className="my-0 small text-muted fw-bold">N/A</p>
  </div>
);

export default TableImagePlacheolder;
