import { useParams } from "react-router-dom";
import cx from "classnames";
import React, { useCallback, useState } from "react";
import api from "../../../shared/api";
import { dataURLForFile } from "../../../shared/utilities";
import { useDropzone } from "react-dropzone";
import { extractAPIErrorString } from "../../../shared/utils/extractErrors";

const DocumentDrop = ({ className, onRewardCodesAdded, ...props }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const { id } = useParams();

  const onDrop = useCallback(
    acceptedFiles => {
      const upload_file = acceptedFiles[0];
      setIsUploading(true);
      dataURLForFile(upload_file)
        .then(dataStr => {
          api
            .post(`/rewards/${id}/codes`, { csv: dataStr })
            .then(() => {
              setIsUploading(false);
              onRewardCodesAdded();
            })
            .catch(
              extractAPIErrorString(errStr => {
                setError(errStr);
              })
            );
        })
        .catch(
          extractAPIErrorString(errStr => {
            setError(errStr);
          })
        );
    },
    [id, onRewardCodesAdded]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="row">
      <div className="col">
        <div className="form-group">
          <label className="mb-1 mkit-pretitle">
            {props.label || "Upload a csv file."}
          </label>
          <div className="dropzone dropzone-single" {...getRootProps()}>
            <input {...getInputProps()} />
            <input type="hidden" className={cx(error && "is-invalid")} />
            {error && <div className="invalid-feedback">{error}</div>}
            <div className="dz-default dz-message">
              {isUploading ? (
                <div className="spinner-border align-self-center" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span className="text-muted">
                  {isDragActive
                    ? "Drop here."
                    : "Drag & drop here, or click to select a document"}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDrop;
