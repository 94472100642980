import React, { Fragment } from "react";
import BootstrapCard from "shared/components/BootstrapCard";

const GooglePlaceInfo = ({
  googlePlaceData,
  onPrefillClick,
  showPrefillOption,
}) => (
  <BootstrapCard bodyClassName="d-flex flex-column">
    {googlePlaceData.media_urls.length > 0 && (
      <img
        alt={googlePlaceData.name}
        className="mb-3"
        src={googlePlaceData.media_urls[0]}
        style={{
          borderRadius: 4,
          maxHeight: "100px",
        }}
      />
    )}
    <div>
      <h3>{googlePlaceData.name}</h3>
      {googlePlaceData.address && (
        <p className="small">
          {[
            googlePlaceData.address.line1,
            googlePlaceData.address.city,
            googlePlaceData.address.postcode,
          ]
            .filter(Boolean)
            .map((str, idx) => (
              <Fragment key={idx}>
                {idx > 0 && <br />}
                {str}
              </Fragment>
            ))}
        </p>
      )}
      {showPrefillOption && (
        <button
          className="btn btn-sm btn-primary"
          onClick={onPrefillClick}
          type="button"
        >
          Prefill Venue Data
        </button>
      )}
    </div>
  </BootstrapCard>
);

export default GooglePlaceInfo;
