import React from "react";
import cx from "classnames";
import { components } from "react-select";
import _ from "lodash";

const SelectVenueOption = props => {
  const subtitle = [
    _.get(props, "data.venue.city_areas.0.name"),
    _.get(props, "data.venue.city.name"),
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <components.Option {...props}>
      <div className={cx("d-flex flex-column align-items-start")}>
        <span>{props.label}</span>
        {subtitle && <span className="small text-muted">{subtitle}</span>}
      </div>
    </components.Option>
  );
};

export default SelectVenueOption;
