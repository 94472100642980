import React from "react";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import GenericListPageContents from "./GenericListPageContents";

const QueryStringListPageContents = props => {
  const [listParams, setListParams] = useQueryParams({
    per_page: withDefault(NumberParam, 25),
    page: withDefault(NumberParam, 1),
    q: withDefault(StringParam, ""),
  });

  return (
    <GenericListPageContents
      paginationParams={listParams}
      setPaginationParams={setListParams}
      {...props}
    />
  );
};

export default QueryStringListPageContents;
