import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import api from "shared/api";
import CampaignForm from "./createUpdate/CampaignForm";

import FormSpinner from "shared/components/FormSpinner";
import FormPageHeader from "shared/components/FormPageHeader";

const CampaignEditPage = () => {
  const { id: campaignId } = useParams();

  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    if (campaign && campaign.id === parseInt(campaignId, 10)) {
      return;
    }

    api
      .get(`/campaigns/${campaignId}`)
      .then(({ data }) => setCampaign(data.data));
  }, [campaign, campaignId]);

  if (!campaign) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Edit Campaign | ${campaign.name}`}</title>
      </Helmet>
      <CampaignForm
        initialCampaign={campaign}
        renderTitleComponent={({ isSubmitting }) => (
          <FormPageHeader isSubmitting={isSubmitting} pretitle="campaigns">
            Edit {campaign.name}
          </FormPageHeader>
        )}
      />
    </div>
  );
};

export default CampaignEditPage;
