import React, { useState } from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import AddOfferToVenueModal from "./AddOfferToVenueModal";
import RemoveOfferFromVenueModal from "./RemoveOfferFromVenueModal";
import UpdateVenueOfferAvailabilityModal from "./UpdateVenueOfferAvailabilityModal";
import UpdateVenueOfferPauseStatusModal from "./UpdateVenueOfferPauseStatusModal";
import VenueOfferRow from "./VenueOfferRow";

const VenueOfferListBlock = ({
  onOfferAdded,
  onOfferRemoved,
  onOfferUpdated,
  venue,
}) => {
  const [isShowingAddOfferModal, setIsShowingAddOfferModal] = useState(false);
  const [isShowingRemoveModal, setIsShowingRemoveModal] = useState(null);
  const [isShowingUpdateModal, setIsShowingUpdateModal] = useState(null);
  const [updatingPauseStatusOffer, setUpdatingPauseStatusOffer] =
    useState(null);

  return (
    <>
      <BootstrapCard
        renderAdditionalHeaderContent={() => (
          <div className="col-auto">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setIsShowingAddOfferModal(true)}
              type="button"
            >
              Add Offer
            </button>
          </div>
        )}
        title="Offers"
      >
        <div className="list-group list-group-flush my-n3">
          {venue.offers.map(offer => (
            <VenueOfferRow
              key={offer.id}
              offer={offer}
              onDeleteClick={() => setIsShowingRemoveModal(offer.id)}
              onUpdateAvailabilityClick={() => setIsShowingUpdateModal(offer)}
              onUpdatePauseStatusClick={() =>
                setUpdatingPauseStatusOffer(offer)
              }
            />
          ))}
        </div>
      </BootstrapCard>

      {/* Add Offer Modal */}
      <AddOfferToVenueModal
        onAdded={offers => {
          setIsShowingAddOfferModal(false);
          onOfferAdded(offers);
        }}
        onClose={() => setIsShowingAddOfferModal(false)}
        show={isShowingAddOfferModal}
        venueId={venue.id}
      />

      {/* Remove Offer Modal */}
      <RemoveOfferFromVenueModal
        offerId={isShowingRemoveModal}
        onClose={() => setIsShowingRemoveModal(null)}
        onRemoved={() => {
          setIsShowingRemoveModal(null);
          onOfferRemoved(isShowingRemoveModal);
        }}
        show={Boolean(isShowingRemoveModal)}
        venueId={venue.id}
      />

      {/* Update Offer Availability Modal */}
      <UpdateVenueOfferAvailabilityModal
        offer={isShowingUpdateModal}
        onUpdated={updatedOffer => {
          setIsShowingUpdateModal(null);
          onOfferUpdated(updatedOffer);
        }}
        onClose={() => setIsShowingUpdateModal(null)}
        show={Boolean(isShowingUpdateModal)}
        venueId={venue.id}
      />

      {/* Pause / Resume Offer Modal */}
      <UpdateVenueOfferPauseStatusModal
        offerId={updatingPauseStatusOffer?.id}
        onClose={() => setUpdatingPauseStatusOffer(null)}
        onUpdated={updatedOffer => {
          setUpdatingPauseStatusOffer(null);
          onOfferUpdated(updatedOffer);
        }}
        show={Boolean(updatingPauseStatusOffer)}
        venueId={venue.id}
        targetPauseStatus={!updatingPauseStatusOffer?.is_paused}
      />
    </>
  );
};

export default VenueOfferListBlock;
