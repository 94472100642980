import React from "react";
import cx from "classnames";
import { Field, useField } from "formik";

const Checkbox = ({ children, className, ...props }) => {
  const [, meta] = useField(props);

  return (
    <div className={cx("form-check mb-3", className)}>
      <Field
        type="checkbox"
        className={cx(
          "form-check-input",
          meta && meta.touched && meta.error && "is-invalid"
        )}
        {...props}
      />
      {props.label && (
        <label className="form-check-label" htmlFor={props.id || props.name}>
          {props.label}
        </label>
      )}
      {meta && meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default Checkbox;
