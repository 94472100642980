import React from "react";
import PropTypes from "prop-types";
import { MATERIAL_ICON_VARIANTS } from "../variables";

const Icon = ({ name, variant }) => {
  if (name.startsWith("fe-")) {
    return <i className={`fe ${name}`} />;
  } else {
    return (
      <i className={`material-icons${variant ? `-${variant}` : ""}`}>{name}</i>
    );
  }
};

Icon.propTypes = {
  variant: PropTypes.oneOf(MATERIAL_ICON_VARIANTS),
};

export default Icon;
