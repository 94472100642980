import React, { createContext, useCallback, useState } from "react";
import { get } from "lodash";
import FormWizardHeader, { FormWizardTab } from "./FormWizardHeader";

export const WizardContext = createContext();

const WizardContextProvider = ({
  children,
  totalSteps = 2,
  isCompleted = false,
  formValues,
}) => {
  const initialValue = {
    progress: 0,
    lastStep: totalSteps - 1,
    formValues: formValues || null,
    completed: Array.from({ length: totalSteps }, (_, i) => i).reduce(
      (acc, number) => {
        acc[number] = isCompleted;
        return acc;
      },
      {}
    ),
    disabled: Array.from({ length: totalSteps }, (_, i) => i).reduce(
      (acc, number, index) => {
        if (index === 0) {
          acc[number] = false;
        } else {
          acc[number] = !isCompleted;
        }
        return acc;
      },
      {}
    ),
  };
  const [wizardState, setWizardState] = useState(initialValue);
  const reset = () => setWizardState(initialValue);

  const getValues = useCallback(
    arrayOfKeys => {
      return arrayOfKeys.reduce((acc, key) => {
        acc[key] = get(wizardState.formValues, key);
        return acc;
      }, {});
    },
    [wizardState]
  );

  const setValues = useCallback(inputValues => {
    setWizardState(e => ({
      ...e,
      formValues: {
        ...e.formValues,
        ...inputValues,
      },
    }));
  }, []);

  const goTo = useCallback(
    step => {
      if (step > wizardState.lastStep || step < 0 || wizardState.disabled[step])
        return;
      setWizardState(e => ({
        ...e,
        progress: step,
      }));
    },
    [wizardState]
  );

  const completeLastStep = useCallback(() => {
    setWizardState(e => ({
      ...e,
      completed: {
        ...e.completed,
        [e.progress]: true,
      },
    }));
  }, []);

  const stepNext = useCallback(
    (setComplete = true) => {
      if (wizardState.progress >= wizardState.lastStep) return;
      setWizardState(e => ({
        ...e,
        progress: e.progress + 1,
        completed: {
          ...e.completed,
          [e.progress]: setComplete,
        },
        disabled: {
          ...e.disabled,
          [e.progress + 1]: !setComplete,
        },
      }));
    },
    [wizardState]
  );

  const enableAllSteps = useCallback(() => {
    setWizardState(e => ({
      ...e,
      disabled: Object.keys(e.disabled).reduce((acc, step) => {
        acc[step] = false;
        return acc;
      }, {}),
    }));
  }, []);

  const stepPrevious = useCallback(() => {
    if (wizardState.progress === 0) return;
    setWizardState(e => ({
      ...e,
      progress: e.progress - 1,
    }));
  }, [wizardState]);

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        current: wizardState.progress,
        completion: wizardState.completed,
        disabled: wizardState.disabled,
        getValues,
        setValues,
        formValues: wizardState.formValues,
        stepNext,
        stepPrevious,
        goTo,
        completeLastStep,
        reset,
        enableAllSteps,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

const FormWizard = ({ children, totalSteps, nav, isCompleted, formValues }) => {
  return (
    <WizardContextProvider
      totalSteps={totalSteps}
      isCompleted={isCompleted}
      formValues={formValues}
    >
      {!nav && (
        <FormWizardHeader>
          {Array.from({ length: totalSteps }, (_, i) => i).map(step => (
            <FormWizardTab key={step} step={step}>{`Step ${
              step + 1
            }`}</FormWizardTab>
          ))}
        </FormWizardHeader>
      )}
      {nav}
      <div className="mt-5 pt-3">{children}</div>
    </WizardContextProvider>
  );
};

export default FormWizard;
