import React, { useState } from "react";

import FormSubmitButton from "../../shared/components/FormSubmitButton";
import { Form, Formik } from "formik";
import formValidation from "../../shared/formValidation";
import Textbox from "../../shared/components/Textbox";

import * as Yup from "yup";
import api from "../../shared/api";
import { Redirect } from "react-router-dom";

const ForgotPassword = () => {
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(null);

  const onFormSubmit = ({ email }, { setSubmitting }) => {
    setSubmitting(true);
    setError(false);

    api
      .post("/auth/forgot-password", { email })
      .then(({ data }) => {
        setSubmitting(false);
        setRedirect({
          pathname: "/reset-forgot-password",
          state: {
            email: email,
          },
        });
      })
      .catch(err => {
        setSubmitting(false);
        if (err && err.response) {
          setError(err.response.data.error);
        } else {
          setError("There was an issue");
        }
        console.log(err);
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <h3 className="text-center mkit-h3">Password reset</h3>
      <p className="text-center mkit-p-14">
        Enter your email to get a password reset link.
      </p>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={onFormSubmit}
        validationSchema={Yup.object().shape({
          email: formValidation.email,
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <Textbox label="Email" name="email" placeholder="jane@dusk.app" />
            <FormSubmitButton
              className="btn-block"
              isSubmitting={isSubmitting}
              type="submit"
              buttonType="outline-dark"
            >
              {isSubmitting ? (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Send Email"
              )}
            </FormSubmitButton>
            {error && <label className="my-3 text-danger">{error}</label>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
