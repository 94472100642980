import React, { useCallback, useState } from "react";
import PageHeader from "../../shared/components/PageHeader";
import moment from "moment";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import { Helmet } from "react-helmet";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import TableHeaderSortable from "shared/components/TableHeaderSortable";

import IconAmex from "assets/images/iconPaymentAmex.svg";
import IconMastercard from "assets/images/iconPaymentMastercard.svg";
import IconVisa from "assets/images/iconPaymentVisa.svg";
import { Link } from "react-router-dom";
import DropdownButton from "shared/components/DropdownButton";
import DeleteModal from "shared/modals/DeleteModal";
import api from "shared/api";

const paymentIconLookup = {
  amex: IconAmex,
  americanExpress: IconAmex,
  mastercard: IconMastercard,
  visa: IconVisa,
};

const CardListPage = () => {
  const [sortParams, setSortParams] = useQueryParams({
    sort: withDefault(StringParam, null),
  });
  const [deletingCardId, setDeletingCardId] = useState(null);
  const [isDeletingCard, setIsDeletingCard] = useState(false);
  const [resetKey, setResetKey] = useState(undefined);

  const onDelete = useCallback(() => {
    setIsDeletingCard(true);

    api.delete(`/fidel-cards/${deletingCardId}`).then(
      () => {
        setIsDeletingCard(false);
        setDeletingCardId(null);
        setResetKey(deletingCardId);
      },
      () => {
        // TODO: Error handling...
      }
    );

    //setDeletingCardId(null)
  }, [deletingCardId]);

  return (
    <div>
      <Helmet>
        <title>Cards</title>
      </Helmet>
      <PageHeader pretitle="dusk admin">Cards</PageHeader>
      <QueryStringListPageContents
        apiPath="/fidel-cards"
        sortParams={sortParams}
        setSortParams={setSortParams}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Card", sortBy: "scheme" },
              { label: "Last 4" },
              { label: "Expiry", sortBy: "expiry_date" },
              { label: "Name" },
              { label: "Email" },
              { label: "Added", sortBy: "created_at" },
              { label: "" },
            ]}
            setSortParams={setSortParams}
            sortParams={sortParams}
          />
        )}
        renderRow={card => (
          <tr key={card.id}>
            <td>
              <img alt={card.scheme} src={paymentIconLookup[card.scheme]} />
            </td>
            <td>{card.last_numbers}</td>
            <td>{moment(card.expiry_date).format("MM/YY")}</td>
            <td>
              {[card.customer.first_name, card.customer.last_name]
                .filter(Boolean)
                .join(" ")}
            </td>
            <td>
              <Link to={`/customers/${card.customer.id}`}>
                {card.customer.email}
              </Link>
            </td>
            <td>{moment(card.created_at).format("Do MMM YYYY")}</td>
            <td onClick={e => e.stopPropagation()}>
              <DropdownButton type="ellipses">
                <button
                  className="dropdown-item"
                  onClick={() => navigator.clipboard.writeText(card.id)}
                  type="button"
                >
                  Copy ID
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => setDeletingCardId(card.id)}
                  type="button"
                >
                  Delete Card
                </button>
              </DropdownButton>
            </td>
          </tr>
        )}
        resetKey={resetKey}
      />
      <DeleteModal
        isDeleting={isDeletingCard}
        isOpen={Boolean(deletingCardId)}
        item="Card"
        handleClose={() => setDeletingCardId(null)}
        handleAction={onDelete}
      />
    </div>
  );
};

export default CardListPage;
