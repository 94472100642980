import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import PageHeader from "shared/components/PageHeader";
import { badgesForCategory } from "./shared";
import cx from "classnames";
import VenueCategoryFormModal from "./VenueCategoryFormModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import api from "shared/api";
import YetAnotherTable from "shared/components/YetAnotherTable";
import Loading from "shared/components/Loading";
import LoadingSpinner from "shared/components/LoadingSpinner";
import DeleteModal from "shared/modals/DeleteModal";
import moment from "moment";

const VenueCategoryListPage = () => {
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [updatingCategory, setUpdatingCategory] = useState(null);
  const [isOrderDirty, setIsOrderDirty] = useState(false);
  const [isSavingOrder, setIsSavingOrder] = useState(false);
  const [categories, setCategories] = useState(null);
  const [updatingCategoryFilterStatus, setUpdatingCategoryFilterStatus] =
    useState(null);

  const [deletingCategory, setDeletingCategory] = useState(null);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);

  // When we finish dragging a category, reorder locally and give the user the
  // option to save the order
  const onDragEnd = useCallback(
    ({ source, destination }) => {
      if (source && destination && source.index !== destination.index) {
        const newCategories = [...categories];
        const movingCategory = newCategories.splice(source.index, 1)[0];
        newCategories.splice(destination.index, 0, movingCategory);
        setCategories(newCategories);

        setIsOrderDirty(true);
      }
    },
    [categories]
  );

  const updateCategoryFilterableStatus = useCallback(
    (categoryId, newStatus) => {
      setUpdatingCategoryFilterStatus(categoryId);
      api
        .put(`/venue-categories/${categoryId}`, { is_filterable: newStatus })
        .then(({ data }) => {
          setCategories(
            categories.map(category =>
              category.id === data.data.id ? data.data : category
            )
          );
        })
        .finally(() => setUpdatingCategoryFilterStatus(null));
    },
    [categories]
  );

  useEffect(() => {
    if (categories) {
      return;
    }

    api
      .get("/venue-categories", {
        params: {
          count: 100,
        },
      })
      .then(({ data }) => setCategories(data.data));
  }, [categories]);

  const onSaveOrder = useCallback(() => {
    setIsSavingOrder(true);

    api
      .put("/venue-categories", {
        category_ids: categories.map(({ id }) => id),
      })
      .then(() => {
        setIsOrderDirty(false);
      })
      .finally(() => setIsSavingOrder(false));
  }, [categories]);

  const onDeleteOrder = useCallback(
    categoryId => {
      if (isDeletingCategory) {
        return;
      }

      setIsDeletingCategory(true);

      api
        .delete(`/venue-categories/${categoryId}`)
        .then(() => {
          setDeletingCategory(null);

          setCategories(categories.filter(({ id }) => id !== categoryId));
        })
        .finally(() => {
          setIsDeletingCategory(false);
        });
    },
    [categories, isDeletingCategory]
  );

  return (
    <div>
      <Helmet>
        <title>Venue Categories</title>
      </Helmet>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto mt-3 mt-md-none">
            {isOrderDirty && (
              <button
                className="btn btn-primary lift me-4"
                onClick={onSaveOrder}
                type="button"
              >
                {isSavingOrder ? <LoadingSpinner /> : "Save Order"}
              </button>
            )}
            <button
              className="btn btn-primary lift"
              onClick={() => setIsCreatingCategory(true)}
              type="button"
            >
              Create Category
            </button>
          </div>
        )}
      >
        Venue Categories
      </PageHeader>
      {categories ? (
        <YetAnotherTable
          data={categories}
          renderBody={children => (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable direction="vertical" droppableId="venue-categories">
                {provided => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {children}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          )}
          renderHeaderRow={() => (
            <thead>
              <tr>
                <th />
                <th>ID</th>
                <th>Name</th>
                <th>Venues</th>
                <th>Filterable</th>
                <th>Created</th>
                <th />
              </tr>
            </thead>
          )}
          renderRow={(category, idx) => (
            <Draggable
              draggableId={`category-${category.id}`}
              index={idx}
              key={category.id}
            >
              {(provided, { isDragging }) => (
                <tr
                  key={category.id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className={cx("bg-white", isDragging && "d-table")}
                >
                  <td>
                    <span
                      className="fe fe-menu"
                      role="button"
                      {...provided.dragHandleProps}
                    />
                  </td>
                  <td>{category.id}</td>
                  <td className="d-flex flex-row align-items-center">
                    {category.icon_url && (
                      <div
                        className="me-3"
                        style={{
                          backgroundColor: "black",
                          borderRadius: "4px",
                          padding: "2px",
                        }}
                      >
                        <img
                          alt={category.name}
                          src={category.icon_url}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                    )}
                    <span>{category.name}</span>
                    {badgesForCategory(category).map((badge, badgeIdx) => (
                      <span
                        className={cx(`badge bg-${badge.variant}`, "ms-2")}
                        key={badgeIdx}
                      >
                        {badge.label}
                      </span>
                    ))}
                  </td>
                  <td>{category.venue_count || 0}</td>
                  <td>
                    {updatingCategoryFilterStatus === category.id ? (
                      <LoadingSpinner />
                    ) : (
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled={
                            category.is_campaign && !category.display_from
                          }
                          checked={category.is_filterable}
                          onChange={e =>
                            updateCategoryFilterableStatus(
                              category.id,
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    )}
                  </td>
                  <td>{moment(category.created_at).format("Do MMM YYYY")}</td>
                  <td>
                    <div className="d-flex justify-content-end align-items-center">
                      <h3 className="mb-0">
                        <span
                          className="fe fe-edit"
                          role="button"
                          onClick={() => setUpdatingCategory(category)}
                        />
                        {["Free Drink", "Reward Bar"].indexOf(category.name) ===
                          -1 && (
                          <span
                            className="fe fe-archive ms-3"
                            role="button"
                            onClick={() => setDeletingCategory(category)}
                          />
                        )}
                      </h3>
                    </div>
                  </td>
                </tr>
              )}
            </Draggable>
          )}
        />
      ) : (
        <Loading height={100} />
      )}
      <VenueCategoryFormModal
        onClose={() => setIsCreatingCategory(false)}
        onConfirm={newCategory => {
          const newCategories = [newCategory, ...categories];
          newCategories.sort((a, b) => a.order - b.order);
          setCategories(newCategories);
          setIsCreatingCategory(false);
        }}
        show={isCreatingCategory}
      />
      <VenueCategoryFormModal
        category={updatingCategory}
        onClose={() => setUpdatingCategory(null)}
        onConfirm={updatedCategory => {
          setCategories(
            categories.map(category =>
              category.id === updatedCategory.id ? updatedCategory : category
            )
          );
          setUpdatingCategory(null);
        }}
        show={Boolean(updatingCategory)}
      />
      <DeleteModal
        isDeleting={isDeletingCategory}
        isOpen={Boolean(deletingCategory)}
        item="Category"
        handleAction={() => onDeleteOrder(deletingCategory.id)}
        handleClose={() => {
          setDeletingCategory(null);
        }}
      />
    </div>
  );
};

export default VenueCategoryListPage;
