import React, { Fragment, useState } from "react";
import SidebarNavigationLink from "../../shared/components/SidebarNavigationLink";

const NavigationSidebarSubmenu = ({ title, menuItems = [], ...props }) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <Fragment>
      <hr
        className={`navbar-divider my-3 ${
          title === "Core" ? "d-none d-md-block" : ""
        }`}
      />
      <h6 className="navbar-heading m-0" onClick={() => setOpen(!isOpen)}>
        {title}
      </h6>
      {isOpen && (
        <ul className="navbar-nav">
          {menuItems.map(menuItem => (
            <SidebarNavigationLink
              icon={menuItem.icon}
              iconVariant={menuItem.iconVariant}
              url={menuItem.url}
              key={menuItem.url}
              {...props}
            >
              {menuItem.title}
            </SidebarNavigationLink>
          ))}
        </ul>
      )}
    </Fragment>
  );
};

export default NavigationSidebarSubmenu;
