import React from "react";

import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import api from "shared/api";
import Loading from "shared/components/Loading";

import VenueCollectionForm from "./createEditPages/VenueCollectionForm";
import { Helmet } from "react-helmet";
import PageHeader from "shared/components/PageHeader";

const VenueCollectionEditPage = () => {
  const { id } = useParams();
  const [collection, setCollection] = useState(null);

  useEffect(() => {
    api.get(`/venue-collections/${id}`).then(({ data }) => {
      setCollection(data.data);
    });
  }, [id]);

  if (!collection) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Edit Venue Collection - {collection.name}</title>
      </Helmet>

      <PageHeader title="venue collections">Edit Venue Collection</PageHeader>

      <VenueCollectionForm initialCollection={collection} />
    </div>
  );
};

export default VenueCollectionEditPage;
