import React from "react";
import GenericEntityEditPage from "../../shared/components/GenericEntityEditPage";
import RewardCollectionForm from "./createUpdate/RewardCollectionForm";

const RewardCollectionEditPage = () => (
  <GenericEntityEditPage
    apiBasePath="/reward-collections"
    adminBasePath="/reward-collections"
    relevantKeys={["title", "subtitle", "icon_url"]}
    entityName="Reward Collection"
    FormComponent={RewardCollectionForm}
  />
);

export default RewardCollectionEditPage;
