import React from "react";
import { Route, Switch } from "react-router-dom";
import RewardCollectionContentsPage from "./rewardCollection/RewardCollectionContentsPage";
import RewardCollectionCreatePage from "./rewardCollection/RewardCollectionCreatePage";
import RewardCollectionEditPage from "./rewardCollection/RewardCollectionEditPage";
//import RewardCategoryCreatePage from "./rewardCollection/RewardCollectionCreatePage";
import RewardCollectionListPage from "./rewardCollection/RewardCollectionListPage";

const RewardCollectionSection = () => (
  <Switch>
    <Route
      path="/reward-collections/create"
      component={RewardCollectionCreatePage}
    />
    <Route
      path="/reward-collections/:id/edit"
      component={RewardCollectionEditPage}
    />
    <Route
      path="/reward-collections/:id"
      component={RewardCollectionContentsPage}
    />
    <Route component={RewardCollectionListPage} />
  </Switch>
);

export default RewardCollectionSection;
