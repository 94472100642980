import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";
import PageHeader from "../../shared/components/PageHeader";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import TableHeaderSortable from "shared/components/TableHeaderSortable";

import QueryStringListPageContents from "shared/components/QueryStringListPageContents";

const CityListPage = () => {
  const [sortParams, setSortParams] = useQueryParams({
    sort: withDefault(StringParam, null),
  });

  return (
    <div>
      <Helmet>
        <title>Cities</title>
      </Helmet>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto">
            <Link to="/cities/create" className="btn btn-primary d-block lift">
              Add a City
            </Link>
          </div>
        )}
      >
        Cities
      </PageHeader>
      <QueryStringListPageContents
        apiPath="/cities"
        sortParams={sortParams}
        setSortParams={setSortParams}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Name", sortBy: "name" },
              { label: "Venues", sortBy: "venue_count" },
              { label: "Areas", sortBy: "subarea_count" },
              { label: "Stations", sortBy: "station_count" },
              { label: "Created", sortBy: "created_at" },
              { label: "Actions" },
            ]}
            setSortParams={setSortParams}
            sortParams={sortParams}
          />
        )}
        renderRow={city => (
          <tr key={city.id} role="button">
            <td>{city.name}</td>
            <td>{city.venue_count}</td>
            <td>{city.subarea_count}</td>
            <td>{city.station_count}</td>
            <td>{moment(city.created_at).format("h:mma, Do MMM YYYY")}</td>
            <td>
              <Link
                className="btn btn-sm btn-white"
                to={`/cities/${city.id}/edit`}
              >
                Edit
              </Link>
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default CityListPage;
