import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import CardModal from "shared/components/CardModal";
import DateTimeField from "shared/components/formFields/DateTimeField";
import SwitchField from "shared/components/formFields/SwitchField";
import TextField from "shared/components/formFields/TextField";
import * as Yup from "yup";
import moment from "moment";
import LoadingSpinner from "shared/components/LoadingSpinner";
import api from "shared/api";
import ColourField from "shared/components/formFields/ColourField";

import styles from "./VenueCategoryFormModal.module.scss";
import ImageUploadField from "shared/components/ImageUploadField";

const VenueCategoryFormModal = ({
  category,
  isCampaign,
  onClose,
  onConfirm,
  show,
}) => {
  const isAutomaticCategory = useMemo(
    () => ["Free Drink", "Reward Bar"].indexOf(category?.name) > -1,
    [category]
  );
  const hideDisplayDates = useMemo(
    () => isCampaign || category?.is_campaign || isAutomaticCategory,
    [category, isAutomaticCategory, isCampaign]
  );
  const onSubmit = useCallback(
    ({
      name,
      icon_url,
      is_filterable,
      display_from,
      display_until,
      tint_color,
    }) =>
      new Promise(resolve => {
        const payload = {
          name,
          icon_url,
          is_filterable,
          tint_color,
        };

        if (isCampaign) {
          payload.is_campaign = isCampaign;
        }

        if (!hideDisplayDates) {
          payload.display_from = display_from
            ? moment(display_from).utc().format()
            : null;
          payload.display_until = display_until
            ? moment(display_until).utc().format()
            : null;
        }

        const apiRequest = category
          ? api.put(`/venue-categories/${category.id}`, payload)
          : api.post("/venue-categories", payload);

        apiRequest.then(({ data }) => onConfirm(data.data)).finally(resolve);
      }),
    [category, hideDisplayDates, isCampaign, onConfirm]
  );
  return (
    <CardModal
      onClose={onClose}
      show={show}
      title={category ? `Update ${category.name}` : "Create Venue Category"}
    >
      {() => (
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: category?.name || "",
            icon_url: category?.icon_url || "",
            is_filterable:
              typeof category?.is_filterable === "undefined"
                ? true
                : category?.is_filterable,
            display_from: category?.display_from
              ? moment(category.display_from).toDate()
              : "",
            display_until: category?.display_until
              ? moment(category.display_until).toDate()
              : "",
            tint_color: category?.tint_color || undefined,
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is mandatory."),
            icon_url: Yup.string()
              .url("Icon URL must be a... URL")
              .required("Category must have icons."),
            is_filterable: Yup.boolean(),
            display_from: Yup.date().nullable().optional(),
            display_until: Yup.date().nullable().optional(),
          })}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextField
                      className="form-group"
                      disabled={isAutomaticCategory}
                      label="Name"
                      name="name"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <SwitchField
                      className="form-group"
                      label="Filterable in-app"
                      name="is_filterable"
                    />
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="d-flex flex-column align-items-start">
                        <ImageUploadField
                          name="icon_url"
                          label="Icon"
                          alt="Category Icon"
                          isSingle={true}
                          resize={{ width: 90, height: 90 }}
                          imageStyle={{ width: 20, height: 20 }}
                          imageContainerClass={styles.categoryIconContainer}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <ColourField label="Tint Colour" name="tint_color" />
                  </div>
                  {!hideDisplayDates && (
                    <>
                      <div className="col-12 col-md-6">
                        <DateTimeField
                          className="form-group"
                          label="Display From (optional)"
                          minDate={new Date()}
                          name="display_form"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <DateTimeField
                          className="form-group"
                          label="Display Until (optional)"
                          minDate={values.available_from || new Date()}
                          name="display_from"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <button
                  className="btn btn-white"
                  onClick={onClose}
                  type="button"
                >
                  Cancel
                </button>
                <button className="btn btn-primary ms-3" type="submit">
                  {isSubmitting ? <LoadingSpinner /> : "Confirm"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </CardModal>
  );
};

export default VenueCategoryFormModal;
