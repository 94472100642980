import React from "react";
import cx from "classnames";

const GenericList = ({ data, isLoading, renderItem }) => {
  return (
    <div className="card mt-4">
      <div
        className={cx(
          "card-body",
          ((!data && isLoading) || (data && data.length === 0)) && "text-center"
        )}
      >
        {!data && isLoading && (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {data && data.length > 0 && (
          <div className="list-group list-group-flush my-n3">
            {data.map(renderItem)}
          </div>
        )}
        {data && data.length === 0 && (
          <p className="text-muted mb-0">There's nothing to see here. #Empty</p>
        )}
        {/* TODO: Empty State... */}
      </div>
    </div>
  );
};
export default GenericList;
