import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import api from "../../shared/api";
import PageHeader from "../../shared/components/PageHeader";
import FormWrapper from "../../shared/components/FormWrapper";
import CategoryForm from "./createUpdate/CategoryForm";

const RewardCategoryCreatePage = () => {
  const [redirect, setRedirect] = useState(null);

  const onFormSubmit = (formData, { setSubmitting }) => {
    console.log(formData);
    setSubmitting(true);

    api
      .post("/reward-categories", formData)
      .then(() => {
        setSubmitting(false);
        setRedirect(true);
      })
      .catch(err => {
        setSubmitting(false);
        console.error("Error: ", err);
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/reward-categories" }} />;
  }

  return (
    <FormWrapper>
      <PageHeader pretitle="dusk admin">Create a Reward Category</PageHeader>
      <CategoryForm onFormSubmit={onFormSubmit} />
    </FormWrapper>
  );
};

export default RewardCategoryCreatePage;
