import React from "react";
import cx from "classnames";

import styles from "./OfferPreview.module.scss";

const OfferPreview = ({ offer }) => (
  <div className={cx(styles.container, "d-flex flex-row align-items-center")}>
    <img alt={offer.name} className={styles.image} src={offer.icon_url} />
    <div className="d-flex flex-column align-items-start">
      <h4 className={cx(styles.name, "my-0")}>
        Free {offer.list_cta_name || offer.name}
      </h4>
      <small className={cx(styles.status, "my-0")}>Available Now</small>
    </div>
  </div>
);

export default OfferPreview;
