import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import formValidation from "../../../shared/formValidation";
import Textbox from "../../../shared/components/Textbox";
import ImageDrop from "../../../shared/components/ImageDrop";
import FormSubmitButton from "../../../shared/components/FormSubmitButton";
import Textarea from "../../../shared/components/Textarea";
import SpecialSelect from "../../../shared/components/SpecialSelect";
import api from "../../../shared/api";
import FormSpinner from "../../../shared/components/FormSpinner";
import DeleteButton from "../../../shared/components/DeleteButton";
import Checkbox from "../../../shared/components/Checkbox";
import FormLeavePrompt from "shared/components/FormLeavePrompt";
import DateTimeField from "shared/components/formFields/DateTimeField";

const dependencyList = [
  { key: "brands", endpoint: "/reward-brands" },
  { key: "categories", endpoint: "/reward-categories" },
];

const RewardForm = ({
  onFormSubmit,
  initialValues = {
    brand_id: "",
    category_id: "",
    customer_redemption_limit: "",
    description: "",
    allow_duplicate_codes: false,
    is_test: false,
    image_url: "",
    name: "",
    points_cost: "",
    read_more: "",
    terms: "",
    published_at: null,
    expires_at: null,
  },
}) => {
  const [dependencies, setDependencies] = useState(null);

  useEffect(() => {
    if (dependencies) {
      return;
    }
    // Fetch all dependencies required for the form
    Promise.all(
      dependencyList.map(dependency => api.get(dependency.endpoint))
    ).then(results => {
      const dependencyDict = {};
      results.forEach((result, idx) => {
        dependencyDict[dependencyList[idx].key] = result.data.data;
      });

      setDependencies(dependencyDict);
    });
  }, [dependencies, setDependencies]);

  if (!dependencies) {
    return <FormSpinner />;
  }

  const { brands, categories } = dependencies;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={Yup.object().shape({
        allow_duplicate_codes: Yup.boolean(),
        is_test: Yup.boolean(),
        brand_id: Yup.number()
          .required("Brand is required.")
          .typeError("Brand is required."),
        category_id: Yup.number()
          .required("Category is required.")
          .typeError("Category is required."),
        customer_redemption_limit: Yup.number().min(1).nullable(),
        description: Yup.string().required("Reward description is required."),
        image_url: formValidation.imageURL,
        name: formValidation.name,
        points_cost: Yup.number()
          .min(0, "Cost must be at least 0.")
          .required("Cost is required."),
        read_more: Yup.string().nullable(),
        terms: Yup.string().nullable(),
      })}
    >
      {({ isSubmitting, values, errors }) => {
        console.log("Errors: ", errors);

        return (
          <Form>
            <Textbox label="Name" placeholder="Free Jane" name="name" />
            <div className="row">
              <div className="col col-md-6">
                <SpecialSelect
                  label="Brand"
                  name="brand_id"
                  options={brands.map(brand => ({
                    value: brand.id,
                    label: brand.name,
                  }))}
                />
              </div>
              <div className="col col-md-6">
                <SpecialSelect
                  label="Category"
                  name="category_id"
                  options={categories.map(category => ({
                    value: category.id,
                    label: category.name,
                  }))}
                />
              </div>
            </div>
            <ImageDrop
              label="Cover image"
              name="image_url"
              imageType="reward"
            />
            <Textarea
              label="Initial description"
              placeholder="This reward is great..."
              name="description"
              rows="6"
              tooltip="This description supports markdown formatting. <a href='https://www.notion.so/duskapp/Markdown-Text-formatting-in-app-a199beef80c04ef795efadcef2220585' target='_blank'>Read documentation here.</a>"
            />
            <Textarea
              label="Extended description"
              placeholder="More chat after the read more..."
              name="read_more"
              rows="6"
            />
            <Textarea
              label="Terms and Conditions"
              placeholder="T&amp;Cs to redeem the reward"
              name="terms"
              rows="6"
            />
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <Textbox
                  label="Points cost"
                  name="points_cost"
                  placeholder="500"
                  type="number"
                />
              </div>
              <div className="col-12 col-md-6">
                <Textbox
                  label="Customer limit"
                  name="customer_redemption_limit"
                  type="number"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <DateTimeField label="Available from" name="published_at" />
              </div>
              <div className="col-12 col-md-6">
                <DateTimeField label="Expires at" name="expires_at" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <Checkbox
                  id="allow_duplicate_codes"
                  label="Allows duplicate codes"
                  name="allow_duplicate_codes"
                />
              </div>
              <div className="col-12">
                <Checkbox id="is_test" label="Test reward" name="is_test" />
              </div>
            </div>
            <hr className="my-5" />
            <div className="row pb-5">
              <div className="col text-md-right">
                {values.id && (
                  <DeleteButton endpoint="rewards" id={values.id}>
                    Reward
                  </DeleteButton>
                )}
                <FormSubmitButton isSubmitting={isSubmitting}>
                  {values.id ? "Save" : "Add"} Reward
                </FormSubmitButton>
              </div>
            </div>
            <FormLeavePrompt />
          </Form>
        );
      }}
    </Formik>
  );
};

export default RewardForm;
