import React from "react";
import VenueForm from "./createEditPages/VenueForm";
import Helmet from "react-helmet";
import FormPageHeader from "shared/components/FormPageHeader";

const VenueCreatePage = () => (
  <div>
    <Helmet>
      <title>Create Venue</title>
    </Helmet>

    <VenueForm
      renderTitleComponent={({ isSubmitting }) => (
        <FormPageHeader isSubmitting={isSubmitting} pretitle="venues">
          Create Venue
        </FormPageHeader>
      )}
    />
  </div>
);

export default VenueCreatePage;
