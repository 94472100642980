import { badgesForOffer } from "app/offers/shared";
import React from "react";
import YetAnotherTable from "shared/components/YetAnotherTable";
import cx from "classnames";
import { formatDateTime } from "shared/utilities";
import { Link } from "react-router-dom";

const CampaignOfferListTable = ({ offers }) => (
  <YetAnotherTable
    data={offers}
    isFlush
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Created</th>
        </tr>
      </thead>
    )}
    renderRow={offer => (
      <tr key={offer.id}>
        <td>
          <div className="d-flex align-items-center">
            {offer.image_url && (
              <div className="avatar me-3">
                <img
                  className="avatar-img rounded"
                  src={offer.image_url.replace(
                    "cdn.drinki.com",
                    "cdn.dusk.app"
                  )}
                  alt={offer.name}
                />
              </div>
            )}
            <div>
              <Link to={`/offers/${offer.id}`}>
                <h4 className="fw-normal mb-0">{offer.name}</h4>
              </Link>
              <small className="text-muted">
                {/* For Syntax Highlighting */}
                {
                  Object({ free_drink: "Free Drink", custom: "Offer" })[
                    offer.type
                  ]
                }
              </small>
            </div>
          </div>
        </td>
        <td>
          {badgesForOffer(offer).map((badge, idx) => (
            <span className={cx(`badge bg-${badge.variant}`, "ms-2")} key={idx}>
              {badge.label}
            </span>
          ))}
        </td>
        <td>{formatDateTime(offer.created_at)}</td>
      </tr>
    )}
  />
);

export default CampaignOfferListTable;
