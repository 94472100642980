import React from "react";
import cx from "classnames";

const HeaderTabs = ({
  currentTabId,
  onTabSelected,
  tabs,
  renderAdditionalComponents,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-end">
      <ul className="nav nav-tabs nav-overflow header-tabs">
        {tabs.map(({ id, label }) => (
          <li className="nav-item" key={id}>
            <button
              className={cx("nav-link", currentTabId === id && "active")}
              onClick={() => onTabSelected(id)}
              type="button"
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
      {renderAdditionalComponents?.() || null}
    </div>
  );
};

export default HeaderTabs;
