import React from "react";
import GenericEntityEditPage from "../../shared/components/GenericEntityEditPage";
import RewardBrandForm from "./createUpdate/RewardBrandForm";

const RewardBrandEditPage = () => (
  <GenericEntityEditPage
    apiBasePath="/reward-brands"
    adminBasePath="/reward-brands"
    relevantKeys={["logo_url", "name"]}
    entityName="Reward Brand"
    FormComponent={RewardBrandForm}
  />
);

export default RewardBrandEditPage;
