import React from "react";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";
import { formatDateTime } from "shared/utilities";

const CustomerTransactionListTable = ({ customerId }) => (
  <LocalStateListPageContents
    apiPath={`/customers/${customerId}/transactions?exclude=reward_redemption`}
    perPage={10}
    renderHeader={() => (
      <div className="card-header">
        <h4 className="card-header-title">Transactions</h4>
      </div>
    )}
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Name</th>
          <th>Points</th>
          <th>Date</th>
        </tr>
      </thead>
    )}
    renderRow={transaction => (
      <tr key={transaction.id}>
        <td className="text-truncate" style={{ maxWidth: "235px" }}>
          {transaction.comment}
        </td>
        <td>
          {transaction.points_value > 0
            ? "+" + transaction.points_value
            : transaction.points_value}
        </td>
        <td>{formatDateTime(transaction.created_at)}</td>
      </tr>
    )}
    title="Transactions"
  />
);

export default CustomerTransactionListTable;
