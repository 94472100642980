import { useField } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import cx from "classnames";
import TooltipIcon from "shared/components/TooltipIcon";
import styles from "./TextAreaField.module.scss";

const TextAreaField = ({
  className,
  label,
  tooltip,
  autogrow = false,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const textarea = useRef();

  const autogrowFn = useCallback(element => {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  }, []);

  useEffect(() => {
    if (autogrow) {
      autogrowFn(textarea.current);
    }
  }, [autogrow, autogrowFn]);

  return (
    <div className={className}>
      {label && (
        <label className="form-label d-flex" htmlFor={props.id || props.name}>
          {label}
          {tooltip && <TooltipIcon label={tooltip} className="ms-1" />}
        </label>
      )}
      <textarea
        ref={textarea}
        className={cx(
          "form-control",
          meta?.touched && meta?.error && "is-invalid",
          autogrow && styles.autogrow
        )}
        onInput={event => autogrow && autogrowFn(event.target)}
        {...field}
        {...props}
      />
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default TextAreaField;
