import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../shared/components/PageHeader";
import ButtonsLibrary from "./componentLibrary/Buttons";
import TableLibrary from "./componentLibrary/Table";
import WizardLibrary from "./componentLibrary/Wizard";

const ComponentLibrary = () => {
  return (
    <div>
      <Helmet>
        <title>Components Library</title>
      </Helmet>
      <PageHeader>Component Library</PageHeader>
      <p>Behold, you have stumbled upon our secret recipe.</p>
      <ButtonsLibrary />
      <WizardLibrary />
      <TableLibrary />
    </div>
  );
};

export default ComponentLibrary;
