import React from "react";
import styles from "./RewardCollectionListItem.module.scss";
import GenericListItemWrapper from "../../../shared/components/GenericListItemWrapper";
import { Link } from "react-router-dom";

const RewardCollectionListItem = ({ collection }) => (
  <GenericListItemWrapper>
    <div className="col-auto">
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          alt={collection.name}
          src={collection.icon_url}
        />
      </div>
    </div>

    <div className="col-5 ml-n2">
      <h4 className="mb-1">{collection.name}</h4>
      <p className="small text-muted mb-0">{collection.subtitle}</p>
    </div>

    <div className="col-auto ml-auto">
      <Link
        className="btn btn-outline-primary btn-sm"
        to={{ pathname: `/reward-collections/${collection.id}/edit` }}
      >
        Update Meta
      </Link>
      <Link
        className="btn btn-outline-primary btn-sm ml-2"
        to={{ pathname: `/reward-collections/${collection.id}` }}
      >
        Update Contents
      </Link>
    </div>
  </GenericListItemWrapper>
);

export default RewardCollectionListItem;
