// Subscribe to the redux store so you can add your id token to requests
// automagically.
import api from "../../shared/api";

const apiSubscription = store => {
  let hasIdToken = false;

  const updateAPI = ({ auth: { isLoggedIn, idToken } }) => {
    const storeHasIdToken = Boolean(idToken);

    if (hasIdToken === storeHasIdToken) {
      return;
    }

    if (storeHasIdToken) {
      api.defaults.headers = {
        Authorization: `Bearer ${idToken}`,
      };
    } else {
      api.defaults.headers = {};
    }
  };

  store.subscribe(() => {
    updateAPI(store.getState());
  });
};

export default apiSubscription;
