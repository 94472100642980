import React from "react";
import moment from "moment";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import cx from "classnames";
import PageHeader from "../../shared/components/PageHeader";
import { badgesForVenue } from "./shared";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import TableHeaderSortable from "shared/components/TableHeaderSortable";
import HeaderTabs from "shared/components/HeaderTabs";
import OfferCountBadges from "app/campaigns/shared/OfferCountBadges";

const VenueListPage = () => {
  const history = useHistory();

  const [listParams, setListParams] = useQueryParams({
    sort: withDefault(StringParam, null),
    type: withDefault(StringParam, "all"),
  });

  return (
    <div>
      <Helmet>
        <title>Venues</title>
      </Helmet>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link className="btn btn-primary lift" to="/venues/create">
              Create Venue
            </Link>
          </div>
        )}
        renderTabs={() => (
          <HeaderTabs
            currentTabId={listParams.type}
            onTabSelected={type => setListParams({ type })}
            tabs={[
              {
                id: "all",
                label: "All",
              },
              {
                id: "published",
                label: "Live",
              },
              {
                id: "offers",
                label: "Offers",
              },
              {
                id: "no-offers",
                label: "Listed",
              },
              {
                id: "unpublished",
                label: "Unpublished",
              },
            ]}
          />
        )}
      >
        Venues
      </PageHeader>
      <QueryStringListPageContents
        apiPath="/venues"
        sortParams={listParams}
        setSortParams={setListParams}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Name", sortBy: "name" },
              { label: "Status" },
              { label: "City", sortBy: "city.name" },
              { label: "Areas" },
              { label: "Group", sortBy: "chain.name" },
              { label: "Offers" },
              { label: "Updated", sortBy: "updated_at" },
            ]}
            setSortParams={setListParams}
            sortParams={listParams}
          />
        )}
        renderRow={venue => (
          <tr
            key={venue.id}
            role="button"
            onClick={e => {
              if (e.target.className === "rowlink") {
                return;
              }
              history.push(`/venues/${venue.id}`);
            }}
          >
            <td>
              <Link className="rowlink" to={`/venues/${venue.id}`} />
              {venue.name}
            </td>
            <td>
              {badgesForVenue(venue).map((badge, idx) => (
                <span
                  className={cx(`badge bg-${badge.variant}`, idx > 0 && "ms-2")}
                  key={idx}
                >
                  {badge.label}
                </span>
              ))}
            </td>
            <td>{venue.city?.name || "N/A"}</td>
            <td>{venue.city_areas.map(({ name }) => name).join(", ")}</td>
            <td>{venue.chain?.name || "N/A"}</td>
            <td>
              <OfferCountBadges
                freeDrinkCount={
                  venue.offers.filter(
                    offer => offer.offer_type === "free_drink"
                  ).length
                }
                otherOfferCount={
                  venue.offers.filter(
                    offer => offer.offer_type !== "free_drink"
                  ).length
                }
              />
            </td>
            <td>{moment(venue.updated_at).format("Do MMM YYYY")}</td>
          </tr>
        )}
      />
    </div>
  );
};

export default VenueListPage;
