import React from "react";
import { Route, Switch } from "react-router-dom";
import VenueCreatePage from "./venues/VenueCreatePage";
import VenueDetailPage from "./venues/VenueDetailPage";
import VenueEditPage from "./venues/VenueEditPage";
import VenueListPage from "./venues/VenueListPage";

const VenueSection = () => (
  <Switch>
    <Route path="/venues/create" component={VenueCreatePage} />
    <Route path="/venues/:id/edit" component={VenueEditPage} />
    <Route path="/venues/:id" component={VenueDetailPage} />
    <Route component={VenueListPage} />
  </Switch>
);

export default VenueSection;
