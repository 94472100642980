import React, { useCallback, useMemo, useState } from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import CampaignOfferListTable from "./CampaignOfferListTable";
import CampaignVenueListTable from "./CampaignVenueListTable";
import api from "shared/api";
import LoadingSpinner from "shared/components/LoadingSpinner";
import Tooltip from "shared/components/Tooltip";
import moment from "moment";

const CampaignVenueSetCard = ({
  venueSet,
  offerLookup,
  setCampaignVenueSet,
  showUpdateVenueSetModal,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const offers = useMemo(
    () => venueSet.offer_ids?.map(id => offerLookup[id]),
    [venueSet, offerLookup]
  );

  const toggleIsPaused = useCallback(() => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);
    api
      .put(`/campaign-venue-sets/${venueSet.id}`, {
        is_paused: !venueSet.is_paused,
      })
      .then(({ data }) => {
        setCampaignVenueSet({ ...venueSet, ...data.data });
      })
      .finally(() => setIsUpdating(false));
  }, [isUpdating, venueSet, setCampaignVenueSet]);

  return (
    <BootstrapCard
      renderTitle={() => (
        <div className="d-flex justify-content-between">
          <div>
            {venueSet.name}
            <span className="h4 my-auto ms-3">
              {venueSet.is_paused ? (
                <span className="badge rounded-pill bg-secondary ms-2">
                  Paused
                </span>
              ) : (
                <>
                  {!venueSet.is_paused &&
                    venueSet.published_from !== null &&
                    new Date() <= moment(venueSet.published_from).toDate() && (
                      <span className="badge rounded-pill bg-light ms-2">
                        Scheduled
                      </span>
                    )}
                  {(venueSet.total_redemption_limit ||
                    venueSet.daily_redemption_limits?.base ||
                    venueSet.daily_redemption_limits?.days?.length) && (
                    <span className="badge rounded-pill bg-info ms-2">
                      Limited
                    </span>
                  )}
                  {venueSet.redeemable_times !== null && (
                    <span className="badge rounded-pill bg-warning ms-2">
                      Overriden Times
                    </span>
                  )}
                </>
              )}
            </span>
          </div>
          <div>
            <Tooltip label={venueSet.is_paused ? "Play" : "Pause"}>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => toggleIsPaused()}
              >
                {isUpdating ? (
                  <LoadingSpinner />
                ) : (
                  <span
                    className={`fe fe-${venueSet.is_paused ? "play" : "pause"}`}
                  />
                )}
              </button>
            </Tooltip>
            <Tooltip label="Overrides">
              <button
                className="btn btn-outline-secondary btn-sm ms-3"
                onClick={() => showUpdateVenueSetModal(venueSet)}
              >
                {" "}
                <span className="fe fe-settings" />
              </button>
            </Tooltip>
          </div>
        </div>
      )}
      bodyClassName="p-0"
    >
      <CampaignOfferListTable offers={offers} />
      <hr className="m-0" />
      {venueSet.venue_ids.length > 0 && (
        <CampaignVenueListTable
          offerIds={venueSet.offer_ids}
          venueSetId={venueSet.id}
        />
      )}
    </BootstrapCard>
  );
};

export default CampaignVenueSetCard;
