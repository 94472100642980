import { createStore, applyMiddleware } from "redux";
import reducer from "./reducer";
import logger from "redux-logger";
import thunk from "redux-thunk";
import apiSubscription from "./subscribers/api";

const { NODE_ENV } = process.env;
const isDev = NODE_ENV === "development";

const store = createStore(
  reducer,
  applyMiddleware(thunk, isDev ? logger : s => n => a => n(a))
);

apiSubscription(store);

export default store;
