import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "shared/components/Button";
import DropdownWrapper from "shared/components/DropdownWrapper";
import DropdownButton from "../../shared/components/DropdownButton";
import { DropdownItem } from "../../shared/components/DropdownWrapper";

const ButtonsLibrary = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dropdownMenu = (
    <>
      <DropdownItem>Hello</DropdownItem>
      <DropdownItem>Bye bye</DropdownItem>
    </>
  );

  const secondDropdownMenu = (
    <DropdownItem tag={Link} to="/">
      Go home..
    </DropdownItem>
  );

  return (
    <section className="mb-5">
      <h2>The Button</h2>
      <div className="row">
        <div className="col">
          <p>With dropdown</p>

          <DropdownWrapper className="d-block" dropdownItems={dropdownMenu}>
            <Button variant="primary" isOutlined icon="plus">
              Dropdown button
            </Button>
          </DropdownWrapper>

          <DropdownWrapper
            className="d-block mt-2"
            dropdownItems={secondDropdownMenu}
          >
            <Button variant="primary">Another Dropdown</Button>
          </DropdownWrapper>
        </div>
        <div className="col">
          <p>Action with dropdown</p>
          <div className="btn-group d-block">
            <Button variant="danger" icon="trash" isOutlined>
              Remove
            </Button>
            <DropdownButton variant="danger">{dropdownMenu}</DropdownButton>
          </div>
          <div className="mt-2">
            <div className="btn-group mt-2 btn-group-sm">
              <Button variant="primary" icon="plus">
                Add
              </Button>
              <DropdownButton variant="primary">{dropdownMenu}</DropdownButton>
            </div>
          </div>
          <div className="mt-2">
            <div className="btn-group mt-2 btn-group-sm">
              <Button variant="primary" isOutlined icon="plus">
                Add
              </Button>
              <DropdownButton type="ellipses" variant="primary">
                {dropdownMenu}
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <p>
            Loading
            <span className="form-check">
              <input
                id="toggle-loading"
                className="form-check-input"
                type="checkbox"
                value={isLoading}
                onChange={() => setIsLoading(e => !e)}
              />
              <label htmlFor="toggle-loading" className="form-check-label">
                enable
              </label>
            </span>
          </p>
          <Button
            className="me-2"
            variant="primary"
            icon="plus"
            isLoading={isLoading}
          >
            A button with an icon
          </Button>
          <Button variant="secondary" isLoading={isLoading}>
            Regular text
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ButtonsLibrary;
