import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import PageHeader from "shared/components/PageHeader";
import CityForm from "./createEditPages/CityForm";

const CityEditPage = () => {
  const { id: cityId } = useParams();

  const [city, setCity] = useState(null);

  useEffect(() => {
    if (city && city.id === parseInt(cityId, 10)) {
      return;
    }

    api.get(`/cities/${cityId}`).then(({ data }) => setCity(data.data));
  }, [city, cityId]);

  if (!city) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Edit City | ${city.name}`}</title>
      </Helmet>
      <PageHeader pretitle="cities">Edit {city.name}</PageHeader>

      <CityForm initialCity={city} />
    </div>
  );
};

export default CityEditPage;
