import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import formValidation from "../../../shared/formValidation";
import Textbox from "../../../shared/components/Textbox";
import ImageDrop from "../../../shared/components/ImageDrop";
import FormSubmitButton from "../../../shared/components/FormSubmitButton";
import DeleteButton from "../../../shared/components/DeleteButton";

const RewardBrandForm = ({
  onFormSubmit,
  initialValues = { name: "", logo_url: "" },
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onFormSubmit}
    validationSchema={Yup.object().shape({
      logo_url: formValidation.imageURL,
      name: formValidation.name,
    })}
  >
    {({ isSubmitting, values }) => (
      <Form>
        <Textbox label="Category name" placeholder="Nike" name="name" />
        <ImageDrop
          label="Brand logo"
          name="logo_url"
          imageType="reward_brand"
        />
        <hr className="my-5" />
        <div className="row pb-5">
          <div className="col text-md-right">
            {values.id && (
              <DeleteButton endpoint="reward-brands" id={values.id}>
                Brand
              </DeleteButton>
            )}
            <FormSubmitButton isSubmitting={isSubmitting}>
              {values.id ? "Save" : "Add"} Brand
            </FormSubmitButton>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default RewardBrandForm;
