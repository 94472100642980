import React, { useEffect, useState, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import api from "../../shared/api";
import PageHeader from "../../shared/components/PageHeader";
import GuidesListItem from "./list/GuidesListItem";
import FormSpinner from "../../shared/components/FormSpinner";
import DraggableList from "../../shared/components/DraggableList";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import HeaderTabs from "shared/components/HeaderTabs";
import _ from "lodash";
import DropdownButton from "shared/components/DropdownButton";
import cx from "classnames";

const GuideListPage = () => {
  const [guidesByType, setGuidesByType] = useState({});
  const [listParams, setListParams] = useQueryParams({
    type: withDefault(StringParam, "all"),
    tag: withDefault(StringParam, null),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [guideTags, setGuideTags] = useState([]);

  const guides = guidesByType[listParams.type]
    ? guidesByType[listParams.type][listParams.tag || "all"] || null
    : null;

  const onDragged = useCallback(
    reordered => {
      const oldGuides = [...guides];
      const oldGuidesOrder = oldGuides.map(guide => guide.id);
      const newGuidesOrder = reordered.map(guide => guide.id);
      if (!_.isEqual(oldGuidesOrder, newGuidesOrder)) {
        setGuidesByType({
          ...guidesByType,
          [listParams.type]: {
            ...guidesByType[listParams.type],
            [listParams.tag || "all"]: reordered,
          },
        });
        return new Promise(resolve => {
          api.put("/guides", { guide_ids: newGuidesOrder }).then(
            () => {
              resolve();
            },
            err => {
              // Reset guides to old order
              console.error(err);
              setGuidesByType({
                ...guidesByType,
                [listParams.type]: {
                  ...guidesByType[listParams.type],
                  [listParams.tag || "all"]: oldGuides,
                },
              });
              resolve();
            }
          );
        });
      }
    },
    [guides, guidesByType, listParams]
  );

  // Fetch guides when type changes
  useEffect(() => {
    if (guides) {
      return;
    }
    setIsLoading(true);
    api
      .get("/guides", {
        params: {
          filter: listParams.type,
          tag: listParams.tag,
        },
      })
      .then(({ data }) => {
        setGuidesByType({
          ...guidesByType,
          [listParams.type]: {
            ...guidesByType[listParams.type],
            [listParams.tag || "all"]: data.data,
          },
        });
      })
      .finally(() => setIsLoading(false));
  }, [guides, guidesByType, listParams]);

  // Load guide tags
  useEffect(() => {
    api.get("/guides/tags").then(({ data }) => {
      setGuideTags(data.data);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Guides</title>
      </Helmet>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link to="/guides/create" className="btn btn-primary lift">
              <span className="fe fe-plus me-2" />
              New Guide
            </Link>
          </div>
        )}
        renderTabs={() => (
          <HeaderTabs
            currentTabId={listParams.type}
            onTabSelected={type => setListParams({ type, tag: null })}
            tabs={[
              {
                id: "all",
                label: "All",
              },
              {
                id: "published",
                label: "Live",
              },
              {
                id: "unpublished",
                label: "Unpublished",
              },
              {
                id: "test",
                label: "Tests",
              },
            ]}
            renderAdditionalComponents={() =>
              listParams.type === "all" && (
                <div className="d-flex justify-content-end">
                  <div className="dropdown">
                    <DropdownButton
                      className="btn btn-outline-secondary btn-sm dropdown-toggle"
                      label="Filter by tag"
                    >
                      {guideTags.map((tag, index) => (
                        <li key={index}>
                          <button
                            className={cx(
                              "dropdown-item",
                              listParams.tag === tag && "active"
                            )}
                            onClick={() =>
                              listParams.tag === tag
                                ? setListParams({ tag: null })
                                : setListParams({ tag })
                            }
                          >
                            {tag}
                          </button>
                        </li>
                      ))}
                    </DropdownButton>
                  </div>
                </div>
              )
            }
          />
        )}
      >
        Guides
      </PageHeader>
      {isLoading && <FormSpinner />}
      {guides && !isLoading && (
        <>
          {["all", "published"].includes(listParams.type) && (
            <div
              className={cx(
                "d-flex align-items-center alert",
                listParams.type === "published"
                  ? "alert-success"
                  : "alert-warning"
              )}
              role="alert"
            >
              <span className="fe fe-alert-circle pe-3" />
              <div>
                Guides can be re-ordered by drag and drop in
                {listParams.type === "published"
                  ? " this tab"
                  : ' the "Live" tab'}
              </div>
            </div>
          )}
          <DraggableList
            list={guides}
            onDragged={onDragged}
            ListComponent={GuidesListItem}
            isDragDisabled={listParams.type !== "published"}
          />
        </>
      )}
    </div>
  );
};

export default GuideListPage;
