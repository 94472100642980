import React from "react";
import cx from "classnames";

const BootstrapCard = ({
  bodyClassName,
  children,
  renderAdditionalHeaderContent,
  renderTitle,
  showBody = true,
  title,
  ...props
}) => (
  <div className="card" {...props}>
    {(renderTitle || title) && (
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            {renderTitle?.() || <h4 className="card-header-title">{title}</h4>}
          </div>
          {renderAdditionalHeaderContent?.()}
        </div>
      </div>
    )}
    {showBody && (
      <div className={cx("card-body", bodyClassName)}>{children}</div>
    )}
  </div>
);

export default BootstrapCard;
