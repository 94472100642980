import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "shared/components/PageHeader";
import VenueCollectionForm from "./createEditPages/VenueCollectionForm";

const VenueCollectionCreatePage = () => (
  <div>
    <Helmet>
      <title>Create Venue Collection</title>
    </Helmet>

    <PageHeader title="venue collections">Create Venue Collection</PageHeader>

    <VenueCollectionForm />
  </div>
);

export default VenueCollectionCreatePage;
