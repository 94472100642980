import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshSession } from "../auth/actions";

const AuthManager = () => {
  const { isLoggedIn, isVerifyingSession } = useSelector(s => s.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn || isVerifyingSession) {
      return;
    }

    const refreshToken = localStorage.getItem("REF_TOKEN");
    if (!refreshToken) {
      return;
    }

    dispatch(refreshSession(refreshToken));
  }, [dispatch, isLoggedIn, isVerifyingSession]);
  return null;
};

export default AuthManager;
