import React from "react";
import moment from "moment";
import YetAnotherTable from "shared/components/YetAnotherTable";

const CampaignVenueSetVenueTable = ({ venues, onVenueRemove }) => {
  return (
    <YetAnotherTable
      data={venues}
      isLoading={Boolean(venues.length && !venues[0])}
      renderHeaderRow={() => (
        <thead>
          <tr>
            <th>Name</th>
            <th>Areas</th>
            <th>City</th>
            <th>Group</th>
            <th>Created</th>
            <th />
          </tr>
        </thead>
      )}
      renderRow={venue =>
        venue ? (
          <tr key={venue.id}>
            <td>{venue.name}</td>
            <td>{venue.city_areas?.map(({ name }) => name).join(", ")}</td>
            <td>{venue.city?.name || "N/A"}</td>
            <td>{venue.chain?.name || "N/A"}</td>
            <td>{moment(venue.created_at).format("Do MMM YYYY, HH:mm")}</td>
            <td>
              <i
                className="h2 fe fe-x-circle"
                onClick={() => onVenueRemove(venue)}
                role="button"
              />
            </td>
          </tr>
        ) : null
      }
    />
  );
};

export default CampaignVenueSetVenueTable;
