import React from "react";
import Loading from "./Loading";
import cx from "classnames";

// Simple Table.
// No Pagination, No Data-Fetching, No Sorting.
const YetAnotherTable = ({
  data,
  isLoading,
  title,
  renderBody = children => <tbody>{children}</tbody>,
  renderEmptyState,
  renderFooter,
  renderHeader,
  renderHeaderRow,
  renderRow,
  renderTitleExtras,
  isFlush = false,
}) => (
  <div className={cx("card", isFlush && "card-flush mb-0")}>
    {renderHeader?.() ||
      (title && (
        <div className="card-header">
          <h4 className="card-header-title">{title}</h4>
          {renderTitleExtras?.()}
        </div>
      ))}
    <div style={{ overflowX: "scroll" }}>
      {/* Loading State */}
      {isLoading && <Loading height={100} />}

      {/* Empty State */}
      {!isLoading &&
        (!data || data.length === 0) &&
        (renderEmptyState?.() || (
          <div className="card-body">
            <p className="text-muted my-0">Nothing to see here.</p>
          </div>
        ))}

      {/* Actual Table! */}
      {!isLoading && Boolean(data && data.length > 0) && (
        <table className="table table-sm table-nowrap table-hover card-table">
          {renderHeaderRow()}
          {renderBody(data.map(renderRow))}
        </table>
      )}
    </div>

    {(!isLoading && renderFooter?.()) || null}
  </div>
);

export default YetAnotherTable;
