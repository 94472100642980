import React from "react";
import { Route, Switch } from "react-router-dom";
import CityCreatePage from "./cities/CityCreatePage";
import CityEditPage from "./cities/CityEditPage";
import CityListPage from "./cities/CityListPage";

const CitySection = () => {
  return (
    <Switch>
      <Route path="/cities/create" component={CityCreatePage} />
      <Route path="/cities/:id/edit" component={CityEditPage} />
      <Route component={CityListPage} />
    </Switch>
  );
};

export default CitySection;
