import React from "react";
import cx from "classnames";
import moment from "moment";

export const badgesForOffer = offer => {
  const fromIsFine =
    !offer.available_from || moment(offer.available_from).toDate() < new Date();
  const untilIsFine =
    !offer.available_until ||
    moment(offer.available_until).toDate() > new Date();
  const isAvailable = fromIsFine && untilIsFine && !offer.is_paused;

  return [
    offer.is_test && {
      variant: "dark",
      label: "Test",
    },
    offer.is_paused && {
      variant: "warning",
      label: "Paused",
    },
    isAvailable &&
      offer.venue_count > 0 && {
        variant: "success",
        label: "Active",
      },
    !(offer.is_paused || untilIsFine) && {
      variant: "danger",
      label: "Expired",
    },
    !(offer.is_paused || fromIsFine) && {
      variant: "light",
      label: "Scheduled",
    },
    (offer.type ?? offer.offer_type) === "free_drink" && {
      variant: "freedrink",
      label: "Free Drink",
    },
    (offer.type ?? offer.offer_type) === "custom" && {
      variant: "offer",
      label: "Follow-up Offer",
    },
    offer.venue_count === 0 && {
      variant: "secondary",
      label: "Unpublished",
    },
    offer.custom_redemption_times && {
      variant: "warning",
      label: "Overriding Time",
    },
    (offer.total_redemption_limit ||
      offer.daily_redemption_limits?.base ||
      offer.daily_redemption_limits?.days?.length) && {
      variant: "info",
      label: "Limited",
    },
  ].filter(Boolean);
};

export const renderBadgesForOffer = offer =>
  badgesForOffer(offer).map((badge, idx) => (
    <span className={cx(`badge bg-${badge.variant}`, "ms-2")} key={idx}>
      {badge.label}
    </span>
  ));
