import React from "react";
import { Route, Switch } from "react-router-dom";
import RewardBrandCreatePage from "./rewardBrand/RewardBrandCreatePage";
import RewardBrandEditPage from "./rewardBrand/RewardBrandEditPage";
import RewardBrandListPage from "./rewardBrand/RewardBrandListPage";

const RewardBrandSection = () => (
  <Switch>
    <Route path="/reward-brands/create" component={RewardBrandCreatePage} />
    <Route path="/reward-brands/:id/edit" component={RewardBrandEditPage} />
    <Route component={RewardBrandListPage} />
  </Switch>
);

export default RewardBrandSection;
