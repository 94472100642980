import React, { useMemo } from "react";
import Select from "react-select";

const VenueSelectionGroupSearchSelect = ({
  entities,
  onSelect,
  placeholder,
}) => {
  const options = useMemo(
    () =>
      entities
        .filter(({ venue_count }) => venue_count > 0)
        .map(entity => ({
          label: entity.name,
          value: entity.id,
          count: entity.venue_count,
        })),
    [entities]
  );

  return (
    <Select
      isSearchable={true}
      onChange={({ value }) => onSelect(value)}
      options={options}
      placeholder={placeholder}
      value={null}
    />
  );
};

export default VenueSelectionGroupSearchSelect;
