import React, { useCallback, useState } from "react";
import api from "shared/api";
import BootstrapCard from "./BootstrapCard";
import LoadingSpinner from "./LoadingSpinner";
import VenueSelectionGroupSearchSelect from "./venueSelectionCard/VenueSelectionGroupSearchSelect";
import VenueSelectionSearchTypeSelect from "./venueSelectionCard/VenueSelectionSearchTypeSelect";
import VenueSelectionVenueSearchSelect from "./venueSelectionCard/VenueSelectionVenueSearchSelect";

const VenueSelectionCard = ({ chains, collections, onVenuesSelected }) => {
  const [searchType, setSearchType] = useState("venue");
  const [isFetchingVenues, setIsFetchingVenues] = useState(false);

  const onGroupSelect = useCallback(
    entityId =>
      new Promise(resolve => {
        setIsFetchingVenues(true);
        api
          .get("/venues", {
            params: {
              [`${searchType}_id`]: entityId,
              count: 5000,
            },
          })
          .then(({ data }) => {
            onVenuesSelected(data.data);
            resolve(data.data);
          })
          .finally(() => setIsFetchingVenues(false));
      }),
    [onVenuesSelected, searchType]
  );

  return (
    <BootstrapCard
      title="Add Venues"
      renderAdditionalHeaderContent={() =>
        isFetchingVenues ? (
          <div className="col-auto">
            <LoadingSpinner />
          </div>
        ) : null
      }
    >
      <div className="row">
        <div className="col-auto">
          <VenueSelectionSearchTypeSelect
            onSelect={setSearchType}
            value={searchType}
          />
        </div>
        <div className="col">
          {searchType === "venue" && (
            <VenueSelectionVenueSearchSelect
              onVenueSelect={venue => onVenuesSelected([venue])}
            />
          )}

          {searchType !== "venue" && (
            <VenueSelectionGroupSearchSelect
              entities={searchType === "chain" ? chains : collections}
              onSelect={onGroupSelect}
              placeholder={
                searchType === "chain"
                  ? "Search Groups..."
                  : "Search Collections"
              }
            />
          )}
        </div>
      </div>
    </BootstrapCard>
  );
};

export default VenueSelectionCard;
