import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Toast from "shared/components/Toast";
import { removeFromServiceFeedback } from "./actions";
import { createPortal } from "react-dom";

const ServiceFeedbackLayer = () => {
  const dispatch = useDispatch();
  const toasties = useSelector(state => state.serviceFeedback.toasties);

  const closeToast = useCallback(
    toastId => {
      dispatch(removeFromServiceFeedback(toastId));
    },
    [dispatch]
  );

  return createPortal(
    <div
      className="toast-container position-fixed bottom-0 end-0 p-3"
      style={{ zIndex: 10000 }}
    >
      {toasties.map((toast, idx) => {
        return (
          <Toast
            key={idx}
            id={toast.id}
            type={toast.type}
            title={toast.title}
            message={toast.message}
            error={toast.error}
            onClose={closeToast}
          />
        );
      })}
    </div>,
    document.body
  );
};

export default ServiceFeedbackLayer;
