import { useField } from "formik";
import React from "react";
import ColourField from "shared/components/formFields/ColourField";
import TextField from "shared/components/formFields/TextField";
import ImageUploadField from "shared/components/ImageUploadField";
import ListLabelPreview from "../shared/ListLabelPreview";

const ListLabelField = ({ name }) => {
  const [field] = useField(name);

  return (
    <div>
      <div className="row">
        <div className="col-6 col-lg-2">
          <ColourField label="Colour" name={`${name}.tint_color`} />
        </div>
        <div className="col-6 col-md-4 col-lg-2 d-flex flex-column align-items-start">
          <ImageUploadField
            name={`${name}.icon_url`}
            label="Icon"
            alt="Icon"
            isSingle={true}
            resize={{ width: 100, height: 100 }}
            imageStyle={{ width: 30, height: 30 }}
          />
        </div>
        <div className="col-12 col-md-8 col-lg-8 ">
          <TextField
            className="form-group"
            label="Text"
            name={`${name}.text`}
          />
        </div>
        {["icon_url", "text", "tint_color"].every(key =>
          Boolean(field.value?.[key])
        ) && (
          <div className="col-12 d-flex flex-column align-items-start">
            <label className="form-label">Preview</label>
            <ListLabelPreview label={field.value} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListLabelField;
