import React from "react";
import MapMarkerIconPair from "./MapMarkerIconPair";

const MapMarkerIconSetField = ({ name }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-6 col-xl-3">
        <MapMarkerIconPair prefix={`${name}.dusk`} title="Standard" />
      </div>
      <div className="col-12 col-md-6 col-xl-3">
        <MapMarkerIconPair prefix={`${name}.free_drink`} title="Free Drink" />
      </div>
      <div className="col-12 col-md-6 col-xl-3">
        <MapMarkerIconPair
          prefix={`${name}.free_drink_later`}
          title="Free Drink Later"
        />
      </div>
      <div className="col-12 col-md-6 col-xl-3">
        <MapMarkerIconPair prefix={`${name}.locked`} title="Locked" />
      </div>
    </div>
  );
};

export default MapMarkerIconSetField;
