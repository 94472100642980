import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminUserInvitePage from "./adminUser/AdminUserInvitePage";
import AdminUserListPage from "./adminUser/AdminUserListPage";

const AdminUserSection = () => (
  <Switch>
    <Route path="/admin-users/invite" component={AdminUserInvitePage} />
    <Route component={AdminUserListPage} />
  </Switch>
);

export default AdminUserSection;
