import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const GuidesListItem = ({
  id,
  name,
  image_url,
  venue_count,
  published_at,
  published_until,
  created_at,
  updated_at,
  is_test,
  isDraggable,
}) => {
  // const guide_state = () => {
  //   switch (published_at) {
  //     case null:
  //       return {
  //         "message": 'Draft',
  //         variant: "secondary"
  //       }
  //     case published_at > :
  //       return moment(rawTime, moment.ISO_8601).format(`HH:mm`);
  //     default:
  //       return rawTime;
  //   }
  // };
  const now = new Date();

  return (
    <Link to={{ pathname: `/guides/${id}/edit` }}>
      <div className="card d-flex flex-row my-3 shadow overflow-hidden">
        <div
          className="ratio ratio-15x9"
          style={{ width: "300px", height: "auto" }}
        >
          {
            <img
              alt={name}
              src={
                image_url
                  ? image_url
                  : require("../../../assets/images/placeholder.png")
              }
              style={{ objectFit: "cover" }}
            />
          }
        </div>
        <div className="col-auto py-4 px-3">
          <h4 className="mb-1 d-flex align-items-center">
            {`${name}`}
            {is_test ? (
              <span className="badge bg-light ms-3">Test</span>
            ) : moment(published_at) <= now &&
              (!published_until || moment(published_until) > now) ? (
              <span className="badge bg-success ms-3">Live</span>
            ) : (
              ""
            )}
          </h4>
          <p className="small">{`${venue_count} venues`}</p>
          <p className="small text-muted mb-0">{`Created ${moment(
            created_at,
            "YYYY-MM-DD"
          ).format("DD/MM/YY")}`}</p>
          <p className="small text-muted">{`Last Updated: ${moment(
            updated_at,
            "YYYY-MM-DD"
          ).format("DD/MM/YY h:mm")}`}</p>
          {isDraggable && (
            <div
              className="d-flex align-items-center"
              style={{ position: "absolute", height: "100%", top: 0, right: 0 }}
            >
              <span className="fe fe-menu pe-5" />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default GuidesListItem;
