import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import SingleSelectField from "shared/components/formFields/SingleSelectField";
import TextField from "shared/components/formFields/TextField";
import ImageUploadModal from "shared/components/ImageUploadModal";
import SelectCategoryOption from "shared/components/selectOverrides/SelectCategoryOption";
import SelectCategorySingleValue from "shared/components/selectOverrides/SelectCategorySingleValue";
import SortableMedia from "shared/components/SortableMedia";
import VideoUploadModal from "shared/components/VideoUploadModal";
import BannerField from "./BannerField";
import ListLabelField from "./ListLabelField";
import MapMarkerIconSetField from "./MapMarkerIconSetField";
import SwitchField from "shared/components/formFields/SwitchField";
import GuideSelectField from "shared/components/formFields/GuideSelectField";

const BrandingToggle = ({ name }) => (
  <div className="col-auto">
    <div className="form-check form-switch">
      <Field
        className="form-check-input"
        name={`branding_toggles.${name}`}
        type="checkbox"
      />
    </div>
  </div>
);

const CampaignBrandingForm = ({ categories, onCreateCategoryClick }) => {
  const [isUploadingCategoryIconUrl, setIsUploadingCategoryIconUrl] =
    useState(false);

  const { setFieldValue, values } = useFormikContext();

  // Campaign Media
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  return (
    <>
      <BootstrapCard title="Post Drink Survey">
        <div className="row">
          <div className="col-12">
            <TextField
              className="form-group"
              label="Post Drink Email Stub"
              name="post_drink_email_stub"
              placeholder="Mandrill Stub"
            />
          </div>
        </div>
      </BootstrapCard>

      <BootstrapCard
        title="Category"
        renderAdditionalHeaderContent={() => (
          <div className="col-auto">
            <button
              className="btn btn-sm btn-white"
              onClick={onCreateCategoryClick}
              type="button"
            >
              Create New
            </button>
          </div>
        )}
      >
        <SingleSelectField
          components={{
            Option: SelectCategoryOption,
            SingleValue: SelectCategorySingleValue,
          }}
          label="Select a Category"
          name="category_id"
          options={categories}
        />
      </BootstrapCard>

      <BootstrapCard
        renderAdditionalHeaderContent={() => (
          <BrandingToggle name="list_label" />
        )}
        showBody={Boolean(values.branding_toggles?.list_label)}
        title="List Label"
      >
        <ListLabelField name="list_label" />
      </BootstrapCard>
      <BootstrapCard
        renderAdditionalHeaderContent={() => (
          <BrandingToggle name="venue_detail_banner" />
        )}
        showBody={Boolean(values.branding_toggles?.venue_detail_banner)}
        title="Detail Banner"
      >
        <BannerField name="venue_detail_banner" />
      </BootstrapCard>
      <BootstrapCard
        title="Linked Guide"
        renderAdditionalHeaderContent={() => (
          <BrandingToggle name="linked_guide" />
        )}
        showBody={Boolean(values.branding_toggles?.linked_guide)}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <GuideSelectField label="Select Guide" name="guide" />
          </div>
          <div className="col-12 col-md-6">
            <SwitchField label="Automatically add venues" name="guide_sync" />
          </div>
        </div>
      </BootstrapCard>
      <BootstrapCard
        renderAdditionalHeaderContent={() => (
          <BrandingToggle name="map_marker_icons" />
        )}
        showBody={Boolean(values.branding_toggles?.map_marker_icons)}
        title="Custom Map Markers"
      >
        <MapMarkerIconSetField name="map_marker_icons" />
      </BootstrapCard>

      <BootstrapCard title="Inserted Media">
        <SortableMedia name="media_urls" />
        <button
          className="btn btn-md btn-primary"
          onClick={() => setIsUploadingImages(true)}
          type="button"
        >
          Upload Images
        </button>
        <button
          className="btn btn-md btn-primary ms-2"
          onClick={() => setIsUploadingVideo(true)}
          type="button"
        >
          Upload Video
        </button>
      </BootstrapCard>
      <ImageUploadModal
        isSingle={true}
        onClose={() => setIsUploadingCategoryIconUrl(false)}
        onConfirm={imageUrls => {
          setIsUploadingCategoryIconUrl(false);
          setFieldValue("category.icon_url", imageUrls[0]);
        }}
        resize={{ width: 120, height: 120 }}
        show={isUploadingCategoryIconUrl}
      />
      {/* For Uploading Additional Images */}
      <ImageUploadModal
        onClose={() => setIsUploadingImages(false)}
        onConfirm={imageUrls => {
          setIsUploadingImages(false);
          setFieldValue("media_urls", [...values.media_urls, ...imageUrls]);
        }}
        resize={{ width: 1284, height: 1284 }}
        show={isUploadingImages}
      />
      {/* For Uploading Videos */}
      <VideoUploadModal
        onClose={() => setIsUploadingVideo(false)}
        onConfirm={videoUrl => {
          setIsUploadingVideo(false);
          setFieldValue("media_urls", [...values.media_urls, videoUrl]);
        }}
        show={isUploadingVideo}
      />
    </>
  );
};

export default CampaignBrandingForm;
