import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import GuideForm from "./createUpdate/GuideForm";
import PageHeader from "../../shared/components/PageHeader";
import FormSpinner from "../../shared/components/FormSpinner";
import api from "../../shared/api";
import { useParams } from "react-router-dom";
import LoadingSpinner from "shared/components/LoadingSpinner";

const GuideEditPage = () => {
  const { id: guideId } = useParams();
  const [guide, setGuide] = useState(null);

  useEffect(() => {
    api.get(`/guides/${guideId}`).then(({ data }) => {
      setGuide(data.data);
    });
  }, [guideId]);

  if (!guide) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Edit Guide | ${guide.name}`}</title>
      </Helmet>
      <GuideForm
        initialGuide={guide}
        renderTitleComponent={({ isSubmitting }) => (
          <PageHeader
            pretitle="dusk admin"
            renderAdditionalComponents={() => (
              <div className="col-12 col-md-auto mt-3 mt-md-none">
                <button
                  className="btn btn-primary lift"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? <LoadingSpinner /> : "Save"}
                </button>
              </div>
            )}
          >
            Update {guide.name}
          </PageHeader>
        )}
      />
    </div>
  );
};

export default GuideEditPage;
