import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import WizardTabs from "shared/components/WizardTabs";
import VenueEditorialForm from "./VenueEditorialForm";
import VenueHoursForm from "./VenueHoursForm";
import VenueLocationForm from "./VenueLocationForm";
import VenueMediaForm from "./VenueMediaForm";
import WizardStepper from "./WizardStepper";
import FormLeavePrompt from "shared/components/FormLeavePrompt";
import FormValidationNotification from "shared/components/FormValidationNotification";

import * as Yup from "yup";

const VenueForm = ({ initialVenue, renderTitleComponent }) => {
  const savedVenue = useMemo(() => {
    if (!initialVenue) {
      return null;
    }

    const asIsProperties = [
      "address",
      "base_redemption_times",
      "description",
      "fidel_location_id",
      "google_place_id",
      "location",
      "media_urls",
      "name",
      "phone",
      "opening_times",
      "price_level",
      "daily_redemption_limits",
    ];

    return {
      ...asIsProperties.reduce((acc, key) => {
        if (initialVenue[key] !== null) {
          acc[key] = initialVenue[key];
        }
        return acc;
      }, {}),
      is_wheelchair_accessible: initialVenue.is_wheelchair_accessible ?? false,
      city_id: initialVenue.city?.id,
      chain_id: initialVenue.chain?.id,
      station_ids: (initialVenue.stations || []).map(({ id }) => id),
      city_area_ids: (initialVenue.city_areas || []).map(({ id }) => id),
      category_ids: (initialVenue.categories || []).map(({ id }) => id),
    };
  }, [initialVenue]);

  const [googlePlaceData, setGooglePlaceData] = useState(null);
  const [venue, setVenue] = useState({ ...(savedVenue || {}) });
  const [pageIdx, setPageIdx] = useState(0);

  const [chains, setChains] = useState(null);
  const [categories, setCategories] = useState(null);
  const [cities, setCities] = useState(null);
  const [redirectPath, setRedirectPath] = useState(null);

  // Load Chains
  useEffect(() => {
    if (chains) {
      return;
    }
    api
      .get("/venue-chains", { params: { count: 200 } })
      .then(({ data }) => setChains(data.data));
  }, [chains]);

  // Load Categories
  useEffect(() => {
    if (categories) {
      return;
    }

    api
      .get("/venue-categories", { params: { count: 100 } })
      .then(({ data }) => setCategories(data.data));
  }, [categories]);

  // Load Cities
  useEffect(() => {
    if (cities) {
      return;
    }

    api
      .get("/cities", {
        params: { count: 250, include_stations: "true", include_areas: "true" },
      })
      .then(({ data }) => setCities(data.data));
  }, [cities]);

  useEffect(() => {
    // We only want to run this if there is a city in the address but not
    // a city ID
    if (venue.city_id || !venue.address?.city || !cities) {
      return;
    }

    const addressCity = cities.find(city => city.name === venue.address.city);

    if (addressCity) {
      setVenue({ ...venue, city_id: addressCity.id });
    }
  }, [cities, venue, venue.address?.city, venue.city_id]);

  useEffect(() => {
    if (!cities || !googlePlaceData || !googlePlaceData.city_id) {
      return;
    }

    // Check if we've got a city that matches the city_id we have.
    const cityId = googlePlaceData.city_id;

    const matchingCity = cities.find(({ id }) => cityId === id);

    // If we can't find a city, load it into our cities list.
    if (!matchingCity) {
      api
        .get("/cities", {
          params: {
            count: 250,
            include_stations: "true",
            include_areas: "true",
          },
        })
        .then(({ data }) => setCities(data.data));
    }
  }, [googlePlaceData, cities]);

  const onSave = useCallback(
    partial =>
      new Promise(resolve => {
        const payload = { ...venue, ...partial };
        payload.media_urls = [
          partial.cover_image_url,
          ...partial.additional_media_urls,
        ].filter(Boolean);

        delete payload.cover_image_url;
        delete payload.additional_media_urls;

        if (payload.fidel_location_id === "") {
          payload.fidel_location_id = null;
        }

        setVenue(payload);

        // Data sanitisation, it wants INTS
        // This is for direct int keys
        ["chain_id", "city_id"].forEach(key => {
          if (!payload[key]) {
            return;
          }
          payload[key] = parseInt(payload[key], 10);
        });
        // This is for int arrays
        [("category_ids", "city_area_ids", "station_ids")].forEach(key => {
          if (!payload[key]) {
            return;
          }
          payload[key] = payload[key].map(id => parseInt(id, 10));
        });
        // This is for empty strings
        Object.keys(payload).forEach(k => {
          if (payload[k] === "") {
            payload[k] = null;
          }
        });

        // Choose the correct endpoint depending on create or delete
        const req = initialVenue
          ? api.put(`/venues/${initialVenue.id}`, payload)
          : api.post("/venues", payload);

        // Send it off then take you to the detail page!
        req
          .then(({ data }) => {
            setRedirectPath(`/venues/${data.data.id}`);
          })
          .finally(resolve);
      }),
    [initialVenue, venue]
  );

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (!(categories && chains && cities)) {
    return <FormSpinner />;
  }

  return (
    <Formik
      initialValues={{
        google_place_id: venue.google_place_id || "",
        name: venue.name || "",
        location: venue.location || undefined,
        chain_id: venue.chain_id || "",
        address: venue.address || undefined,
        city_id: venue.city_id || "",
        city_area_ids: venue.city_area_ids || [],
        phone: venue.phone || "",
        station_ids: venue.station_ids || [],

        base_redemption_times: venue.base_redemption_times || [],
        daily_redemption_limits: venue.daily_redemption_limits || null,
        opening_times: venue.opening_times || [],

        category_ids: venue.category_ids || [],
        description: venue.description || "",
        price_level: venue.price_level || "",
        fidel_location_id: venue.fidel_location_id || "",

        cover_image_url: venue.media_urls?.[0] || "",
        additional_media_urls: (venue.media_urls || []).slice(1),
      }}
      onSubmit={onSave}
      validationSchema={Yup.object().shape({
        city_id: Yup.number().required("Please select a City."),
        name: Yup.string().required("Please enter a name."),
      })}
    >
      {({ dirty, isSubmitting, errors, touched }) => (
        <Form>
          {renderTitleComponent?.({ isSubmitting })}
          <FormValidationNotification />
          <WizardTabs
            onTabSelect={setPageIdx}
            options={["Location", "Hours", "Editorial", "Media"]}
            selectedIndex={pageIdx}
          />

          {pageIdx === 0 && (
            <VenueLocationForm
              chains={chains}
              cities={cities}
              venue={venue}
              googlePlaceData={googlePlaceData}
              setGooglePlaceData={setGooglePlaceData}
            />
          )}

          {pageIdx === 1 && (
            <VenueHoursForm venue={venue} googlePlaceData={googlePlaceData} />
          )}

          {pageIdx === 2 && (
            <VenueEditorialForm categories={categories} venue={venue} />
          )}

          {pageIdx === 3 && <VenueMediaForm />}

          <WizardStepper
            isSaving={isSubmitting}
            onBackClick={() => {
              setPageIdx(pageIdx - 1);
            }}
            onNextClick={() => {
              setPageIdx(pageIdx + 1);
            }}
            pageIdx={pageIdx}
            pageCount={4}
          />
          <FormLeavePrompt />
        </Form>
      )}
    </Formik>
  );
};

export default VenueForm;
