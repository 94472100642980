import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Redirect, useParams, useHistory } from "react-router-dom";
import api from "shared/api";
import BootstrapCard from "shared/components/BootstrapCard";
import FormSpinner from "shared/components/FormSpinner";
import PageHeader from "shared/components/PageHeader";
import SettingListItem from "shared/components/SettingListItem";
import SettingListSwitchItem from "shared/components/SettingListSwitchItem";
import DeleteModal from "shared/modals/DeleteModal";
import CustomerCardListTable from "./detailPage/CustomerCardListTable";
import CustomerInfoCard from "./detailPage/CustomerInfoCard";
import CustomerOfferRedemptionListTable from "./detailPage/CustomerOfferRedemptionListTable";
import CustomerRewardRedemptionListTable from "./detailPage/CustomerRewardRedemptionListTable";
import GenerateLoginCodeActionItem from "./detailPage/GenerateLoginCodeActionItem";
import ResetOfferRedemptionLimitActionItem from "./detailPage/ResetOfferRedemptionLimitActionItem";
import UpdateRewardPointsActionItem from "./detailPage/UpdateRewardPointsActionItem";
import CustomerActionCard from "./detailPage/CustomerActionCard";
import { extractAPIErrorString } from "shared/utils/extractErrors";
import UpdateCustomerFieldModal from "./detailPage/UpdateCustomerFieldModal";
import CustomerTransactionListTable from "./detailPage/CustomerTransactionListTable";

const CustomerDetailPage = () => {
  const history = useHistory();
  const { id: customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [isCustomerServiceExpanded, setIsCustomerServiceExpanded] =
    useState(false);
  const [lastRedemption, setLastRedemption] = useState(null);

  const [updatingParam, setUpdatingParam] = useState(null);
  const [editingField, setEditingField] = useState("");
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  // Null as base, False if loading, the code if done.
  const [generatedCode, setGeneratedCode] = useState(null);

  // Deleting...
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (customer && customer.id === customerId) {
      return;
    }

    api.get(`/customers/${customerId}`).then(({ data }) => {
      setCustomer(data.data);
    });

    setLastRedemption(null);
    api
      .get(`/offer-redemptions`, {
        params: {
          customer_id: customerId,
          perPage: 1,
        },
      })
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          setLastRedemption(data.data[0]);
        }
      });
  }, [customerId, customer]);

  const deleteCustomer = useCallback(() => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    api
      .delete(`/customers/${customerId}`)
      .then(() => setRedirect("/customers"))
      .finally(() => setIsDeleting(false));
  }, [customerId, isDeleting]);

  const updateCustomer = useCallback(
    (param, value) => {
      if (updatingParam) {
        return;
      }

      setUpdatingParam(param);
      setUpdateError(null);

      return api
        .put(`/customers/${customerId}`, { [param]: value })
        .then(
          ({ data }) => {
            setCustomer(data.data);
            setUpdateSuccess(true);
          },
          extractAPIErrorString(error => {
            setUpdateError(error);
            setUpdateSuccess(false);
          })
        )
        .finally(() => setUpdatingParam(null));
    },
    [customerId, updatingParam]
  );

  const generateLoginCode = useCallback(() => {
    if (generatedCode !== null) {
      return;
    }

    setGeneratedCode(false);
    api.post(`/customers/${customerId}/login-code`).then(({ data }) => {
      setGeneratedCode(data.data);
    });
  }, [customerId, generatedCode]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (!customer) {
    return <FormSpinner />;
  }

  const customerName = [customer.first_name, customer.last_name]
    .filter(Boolean)
    .join(" ");

  return (
    <div>
      <Helmet>
        <title>{`${customerName} | Customers`}</title>
      </Helmet>
      <PageHeader pretitle="customers">{customerName}</PageHeader>
      <div className="row d-md-none">
        <div className="col-6">
          <CustomerActionCard
            text="Reset offer redemption limit"
            icon="unlock"
            isLoading={updatingParam === "last_reset_at"}
            onClick={() => updateCustomer("last_reset_at", true)}
          />
        </div>
        <div className="col-6">
          <CustomerActionCard
            text="Open last redeemed bar"
            icon="home"
            onClick={() =>
              lastRedemption &&
              history.push(`/venues/${lastRedemption.venue.id}`)
            }
            disabled={!lastRedemption}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8">
          <BootstrapCard
            renderAdditionalHeaderContent={() => (
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-white"
                  onClick={() =>
                    setIsCustomerServiceExpanded(!isCustomerServiceExpanded)
                  }
                >
                  {isCustomerServiceExpanded ? "Hide" : "Show"}
                </button>
              </div>
            )}
            showBody={isCustomerServiceExpanded}
            title="Settings &amp; Actions"
          >
            <div className="list-group list-group-flush my-n3">
              <ResetOfferRedemptionLimitActionItem
                isLoading={updatingParam === "last_reset_at"}
                onClick={() => updateCustomer("last_reset_at", true)}
              />
              <SettingListSwitchItem
                icon="settings"
                isChecked={customer.is_test_user}
                isLoading={updatingParam === "is_test_user"}
                title="Test User"
                subtitle="Allow this customer to see test venues, rewards and use test codes."
                onChange={e => updateCustomer("is_test_user", e)}
              />
              <SettingListSwitchItem
                icon="credit-card"
                isChecked={!customer.offers_require_card_link}
                isLoading={updatingParam === "offers_require_card_link"}
                title="Remove Card Link Requirement"
                subtitle="Remove the need for a customer to link their card to redeem."
                onChange={e => updateCustomer("offers_require_card_link", !e)}
              />
              <GenerateLoginCodeActionItem
                code={generatedCode}
                isLoading={generatedCode === false}
                onClick={generateLoginCode}
              />
              <UpdateRewardPointsActionItem
                isLoading={updatingParam === "reward_points"}
                onUpdatePoints={newPoints =>
                  updateCustomer("reward_points", newPoints)
                }
                originalPoints={customer.reward_points}
              />
              <SettingListItem
                icon="mail"
                title="Update email address"
                subtitle="Change the email address the customer logs in with."
              >
                <button
                  className="btn btn-sm btn-white"
                  onClick={() => setEditingField("email")}
                >
                  Update Email
                </button>
              </SettingListItem>
              <SettingListItem
                icon="share-2"
                title="Update referral code"
                subtitle="Change customer's referral code to invite new users."
              >
                <button
                  className="btn btn-sm btn-white"
                  onClick={() => setEditingField("referral_code")}
                >
                  Update Code
                </button>
              </SettingListItem>
              <SettingListItem
                icon="shield"
                title="Delete Customer"
                subtitle="Anonymise the customer record and invalidate any existing sessions."
              >
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => setIsShowingDeleteModal(true)}
                >
                  Delete
                </button>
              </SettingListItem>
            </div>
          </BootstrapCard>
          <CustomerOfferRedemptionListTable customerId={customerId} />
          <CustomerRewardRedemptionListTable customerId={customerId} />
          <CustomerCardListTable cards={customer.cards} />
          <CustomerTransactionListTable customerId={customerId} />
        </div>
        <div className="col-12 col-lg-4">
          <CustomerInfoCard customer={customer} />
        </div>
      </div>

      {/* Modals! */}
      <DeleteModal
        isDeleting={isDeleting}
        isOpen={isShowingDeleteModal}
        item="Customer"
        handleAction={deleteCustomer}
        handleClose={() => setIsShowingDeleteModal(false)}
      />
      <UpdateCustomerFieldModal
        name={editingField}
        fieldValue={customer[editingField]}
        isOpen={editingField}
        handleClose={() => {
          setEditingField(null);
          setUpdateSuccess(false);
        }}
        handleAction={updateCustomer}
        error={updateError}
        isSuccess={updateSuccess}
      />
    </div>
  );
};

export default CustomerDetailPage;
