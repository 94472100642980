import { useField } from "formik";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import cx from "classnames";
import TooltipIcon from "../TooltipIcon";

const TextField = ({
  className,
  inputClassName,
  label,
  type = "text",
  ...props
}) => {
  const [field, meta] = useField(props.name);

  const id = useMemo(() => props.id || uuidv4(), [props.id]);

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={id}>
          {label}
          {props.tooltip ? <TooltipIcon label={props.tooltip} /> : null}
        </label>
      )}
      <input
        className={cx(
          "form-control",
          inputClassName,
          meta?.touched && meta?.error && "is-invalid"
        )}
        id={id}
        placeholder={label}
        type={type}
        {...field}
        {...props}
      />
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default TextField;
