import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import SettingListItem from "./SettingListItem";

const SettingListSwitchItem = ({
  title,
  subtitle,
  icon,
  isChecked,
  isLoading,
  onChange,
}) => (
  <SettingListItem icon={icon} title={title} subtitle={subtitle}>
    <div className="form-check form-switch">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <input
          className="form-check-input"
          checked={isChecked}
          onChange={e => onChange(e.target.checked)}
          type="checkbox"
        />
      )}
    </div>
  </SettingListItem>
);

export default SettingListSwitchItem;
