import React from "react";
import cx from "classnames";
import { useField } from "formik";
import InputField from "./InputField";

const Textarea = ({ className, containerClass, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <InputField className={containerClass} {...{ ...props, meta }}>
      <textarea
        className={cx(
          "form-control",
          className,
          meta && meta.touched && meta.error && "is-invalid"
        )}
        {...field}
        {...props}
      />
    </InputField>
  );
};

export default Textarea;
