import React from "react";
import cx from "classnames";

const SettingListItemGroup = ({ items }) => (
  <div className="list-group-item">
    {items.map((item, idx) => (
      <div
        className={cx("row align-items-center", idx > 0 && "mt-3")}
        key={idx}
      >
        <div className="col">
          {item.title && (
            <h5 className="font-weight-base mb-1">{item.title}</h5>
          )}
          {item.subtitle && (
            <small className="text-muted">{item.subtitle}</small>
          )}
        </div>
        <div className="col-auto">{item.value}</div>
      </div>
    ))}
  </div>
);

export default SettingListItemGroup;
