import React, { useRef, useState, useCallback, useEffect } from "react";
import { uniqueId } from "lodash";
import cx from "classnames";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import { createPortal } from "react-dom";

const DropdownItem = ({
  children,
  className: _classname,
  tag = "span",
  ...props
}) => {
  const className = cx("dropdown-item", _classname);
  return React.createElement(tag, { children, className, ...props });
};

const DropdownWrapper = ({
  children: _children,
  className,
  dropdownItems,
  placement = "bottom-end",
  offset,
  ...props
}) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [id] = useState(uniqueId());

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: offset
      ? [
          {
            name: "offset",
            options: {
              offset: [10, -10],
            },
          },
        ]
      : undefined,
  });

  const handleClickAway = useCallback(
    e => {
      if (!show) return;
      const { current } = ref;
      if (current && !current.contains(e.target) && show) {
        setShow(false);
      }
    },
    [show]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickAway);
    return () => {
      window.removeEventListener("click", handleClickAway);
    };
  }, [handleClickAway]);

  const handleClick = () => setShow(e => !e);

  const children = React.cloneElement(_children, {
    id,
    className,
    ref: setReferenceElement,
    onClick: handleClick,
    ...props,
  });
  return (
    <div ref={ref}>
      {children}
      {show &&
        createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="card mb-0">{dropdownItems}</div>
          </div>,
          document.querySelector("#portals")
        )}
    </div>
  );
};

DropdownWrapper.propTypes = {
  dropdownItems: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(DropdownItem),
  ]),
};

export { DropdownItem };

export default DropdownWrapper;
