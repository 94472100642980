import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../shared/api";
import GenericList from "../../shared/components/GenericList";
import PageHeader from "../../shared/components/PageHeader";
import RewardCollectionListItem from "./listPage/RewardCollectionListItem";
import { extractAPIErrorString } from "../../shared/utils/extractErrors";
import { useDispatch } from "react-redux";
import { addToServiceFeedback } from "../serviceFeedback/actions";

const RewardCollectionListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/reward-collections")
      .then(
        ({ data }) => {
          setCollections(data.data);
        },
        extractAPIErrorString(error => {
          dispatch(
            addToServiceFeedback({
              type: "warning",
              message: error,
            })
          );
        })
      )
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <div>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto">
            <Link
              to="/reward-collections/create"
              className="btn btn-primary d-block"
            >
              <span className="fe fe-plus me-2" />
              Create a Collection
            </Link>
          </div>
        )}
      >
        Reward Collections
      </PageHeader>
      <GenericList
        data={collections}
        isLoading={isLoading}
        renderItem={collection => (
          <RewardCollectionListItem
            collection={collection}
            key={collection.id}
          />
        )}
      />
    </div>
  );
};

export default RewardCollectionListPage;
