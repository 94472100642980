import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import api from "shared/api";
import CardModal from "shared/components/CardModal";
import DateTimeField from "shared/components/formFields/DateTimeField";
import LoadingSpinner from "shared/components/LoadingSpinner";
import moment from "moment";
import WeeklyHoursField from "shared/components/formFields/WeeklyHoursField";
import DailyRedemptionLimitsField from "shared/components/formFields/DailyRedemptionLimitsField";
import TextField from "shared/components/formFields/TextField";

const UpdateVenueSetAvailabilityModal = ({
  venueSet,
  onUpdated,
  onClose,
  show,
}) => {
  return (
    <CardModal
      onClose={onClose}
      show={show}
      title={`Campaign Set Overrides - ${venueSet?.name || "N/A"}`}
    >
      {() => (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              published_from: venueSet?.published_from
                ? moment(venueSet.published_from).toDate()
                : "",
              published_until: venueSet?.published_until
                ? moment(venueSet.published_until).toDate()
                : "",
              daily_redemption_limits: venueSet?.daily_redemption_limits || {
                base: "",
                days: [],
              },
              total_redemption_limit: venueSet?.total_redemption_limit || "",
              redeemable_times: venueSet?.redeemable_times || [],
            }}
            onSubmit={({
              published_from,
              published_until,
              redeemable_times,
              daily_redemption_limits,
              total_redemption_limit,
            }) =>
              new Promise(resolve => {
                const updatePayload = {
                  published_from: published_from
                    ? moment(published_from).utc().format()
                    : null,
                  published_until: published_until
                    ? moment(published_until).utc().format()
                    : null,
                  redeemable_times: redeemable_times,
                  daily_redemption_limits:
                    daily_redemption_limits?.base ||
                    daily_redemption_limits?.days?.length
                      ? daily_redemption_limits
                      : null,
                  total_redemption_limit: total_redemption_limit || null,
                };

                api
                  .put(`/campaign-venue-sets/${venueSet.id}`, updatePayload)
                  .then(
                    ({ data }) => {
                      resolve();
                      onUpdated({ ...venueSet, ...data.data });
                    },
                    err => {
                      console.log(err);
                      resolve();
                    }
                  );
              })
            }
            validationSchema={Yup.object().shape({
              published_from: Yup.date().nullable().optional(),
              published_until: Yup.date().nullable().optional(),
              daily_redemption_limits: Yup.object(),
              total_redemption_limit: Yup.number(),
              redeemable_times: Yup.array(),
            })}
          >
            {({ isSubmitting, values, errors }) => {
              return (
                <Form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <DateTimeField
                          className="form-group"
                          label="Schedule start"
                          minDate={new Date()}
                          maxDate={values.published_until || null}
                          name="published_from"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <DateTimeField
                          className="form-group"
                          label="Schedule end"
                          minDate={values.published_from || new Date()}
                          name="published_until"
                        />
                      </div>
                      <hr />
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <DailyRedemptionLimitsField
                            name="daily_redemption_limits"
                            baseTooltip="Total number of drinks that can be redeemed each day. If empty, there is no limit on redemptions per day."
                            dailyTooltip="Apply limits to specific days of the week. If day is not entered, it will refer to daily limit."
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <TextField
                          className="form-group"
                          label="Total drink limit"
                          name="total_redemption_limit"
                          type="number"
                          tooltip="Total number of drinks that can be redeemed for this campaign set. Offer will be display as 'run out' in-app once limit is reached."
                        />
                      </div>
                      <hr />
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label">
                            Override Redeemable Hours
                          </label>
                          <WeeklyHoursField name="redeemable_times" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end">
                    <button
                      className="btn btn-white"
                      onClick={onClose}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button className="btn btn-primary ms-3" type="submit">
                      {isSubmitting ? <LoadingSpinner /> : "Confirm"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </CardModal>
  );
};

export default UpdateVenueSetAvailabilityModal;
