import React from "react";
import cx from "classnames";

import styles from "./AuthPage.module.scss";
import { Route, Switch } from "react-router-dom";
import Login from "./Login";
import ConfirmAccount from "./ConfirmAccount";
import { useSelector } from "react-redux";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";

const AuthPage = () => {
  const { isVerifyingSession } = useSelector(s => s.auth);

  return (
    <div className="row align-items-center justify-content-center d-flex flex-fill">
      <div className="col-12 col-md-5 col-xl-4 my-5">
        <div className="card">
          <div className={cx(styles.card, "card-body")}>
            <img
              alt="DUSK"
              className={`${styles.logo} m-auto`}
              src={require("../../assets/images/dusk-logo.png")}
              width={200}
            />
            {isVerifyingSession ? (
              <>
                <h3 className={cx(styles.welcome, "text-center")}>
                  Verifying session...
                </h3>
                <div className="spinner-border align-self-center" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : (
              <div>
                <Switch>
                  <Route path="/confirm-account">
                    <ConfirmAccount />
                  </Route>
                  <Route path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route path="/reset-forgot-password">
                    <ResetPassword />
                  </Route>
                  <Route>
                    <Login />
                  </Route>
                </Switch>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
