import React from "react";

const OfferCountBadges = ({ freeDrinkCount, otherOfferCount }) => (
  <div className="d-flex d-flex-row">
    {[
      {
        count: freeDrinkCount,
        type: "Free Drink",
        icon: "local_bar",
      },
      {
        count: otherOfferCount,
        type: "Follow-up Offer",
        icon: "sell",
      },
    ]
      .filter(offer => offer.count)
      .map((offer, idx) => (
        <span
          className="badge rounded-pill bg-secondary-soft ms-2 px-auto d-flex align-items-center text-dark"
          key={idx}
        >
          <span className="material-icons small">{offer.icon}</span>
          {offer.count}
        </span>
      ))}
  </div>
);

export default OfferCountBadges;
