import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import Select from "react-select";
import api from "../../shared/api";
import Button from "../../shared/components/Button";
import FormSpinner from "../../shared/components/FormSpinner";
import FormWrapper from "../../shared/components/FormWrapper";
import PageHeader from "../../shared/components/PageHeader";
import RewardCollectionRewardList from "./createUpdate/RewardCollectionRewardList";

const RewardCollectionContentsPage = () => {
  const [redirect, setRedirect] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [orderedRewardIDs, setOrderedRewardIDs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    api.get(`/reward-collections/${id}/rewards`).then(({ data }) => {
      setOrderedRewardIDs(data.data.map(colReward => colReward.id));
    });
  }, [id]);

  useEffect(() => {
    if (rewards) {
      return;
    }

    api.get("/rewards").then(({ data }) => {
      setRewards(data.data);
    });
  }, [rewards, setRewards]);

  const rewardLookup = useMemo(() => {
    if (!rewards) {
      return null;
    }

    return rewards.reduce((acc, reward) => {
      acc[reward.id] = reward;
      return acc;
    }, {});
  }, [rewards]);

  const availableRewardOptions = useMemo(() => {
    if (!(rewards && orderedRewardIDs)) {
      return null;
    }

    return rewards
      .filter(reward => orderedRewardIDs.indexOf(reward.id) === -1)
      .map(reward => ({
        label: reward.name,
        value: reward.id,
      }));
  }, [rewards, orderedRewardIDs]);

  const onSelectChange = ({ value: rewardID }) => {
    console.log("Select Change: ", rewardID);
    const newOrderedIDs = [...orderedRewardIDs];
    newOrderedIDs.splice(0, 0, rewardID);
    setOrderedRewardIDs(newOrderedIDs);
  };

  const onRemove = idx => {
    const newOrderedIDs = [...orderedRewardIDs];
    newOrderedIDs.splice(idx, 1);
    setOrderedRewardIDs(newOrderedIDs);
  };

  // Re-order the ID list.
  const onReorder = (sourceIdx, destinationIdx) => {
    const newOrderedIDs = [...orderedRewardIDs];

    const removed = newOrderedIDs.splice(sourceIdx, 1);
    newOrderedIDs.splice(destinationIdx, 0, ...removed);

    setOrderedRewardIDs(newOrderedIDs);
  };

  const onSubmit = () => {
    if (orderedRewardIDs.length === 0) {
      return;
    }

    setIsLoading(true);
    api
      .put(`/reward-collections/${id}/rewards`, {
        rewards: orderedRewardIDs,
      })
      .then(({ data }) => {
        setRedirect(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/reward-collections" }} />;
  }

  return (
    <FormWrapper>
      <PageHeader pretitle="dusk admin">
        Update Reward Collection Contents
      </PageHeader>

      {rewardLookup && orderedRewardIDs ? (
        <>
          {availableRewardOptions && (
            <div className="form-group">
              <label>Add a Reward</label>
              <Select
                defaultValue={null}
                value={null}
                onChange={onSelectChange}
                options={availableRewardOptions}
              />
            </div>
          )}
          <RewardCollectionRewardList
            onReorder={onReorder}
            onRemove={onRemove}
            rewards={orderedRewardIDs.map(rewardID => rewardLookup[rewardID])}
          />
          <hr className="my-5" />
          <div className="row pb-5">
            <div className="col">
              <Button isLoading={isLoading} onClick={onSubmit}>
                Save Contents
              </Button>
            </div>
          </div>
        </>
      ) : (
        <FormSpinner />
      )}
    </FormWrapper>
  );
};

export default RewardCollectionContentsPage;
