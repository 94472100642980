import React from "react";
import styles from "./BannerPreview.module.scss";
import cx from "classnames";

const BannerPreview = ({ banner }) => (
  <div className="d-flex flex-column align-items-start">
    <div
      className={cx(styles.container, "text-center")}
      style={{
        backgroundColor: banner.background_color,
        borderColor: banner.border_color,
      }}
    >
      <p
        className={cx(styles.title, "my-0")}
        style={{ color: banner.text_color }}
      >
        {banner.title_text}
      </p>
      <p className={cx(styles.body)} style={{ color: banner.text_color }}>
        {banner.body_text}
      </p>
    </div>
  </div>
);

export default BannerPreview;
