import { Form, Formik } from "formik";
import { GoogleApiWrapper } from "google-maps-react";
import React, { useState } from "react";
import * as Yup from "yup";
import BootstrapCard from "shared/components/BootstrapCard";
import MapField from "shared/components/formFields/MapField";
import TextField from "shared/components/formFields/TextField";
import LoadingSpinner from "shared/components/LoadingSpinner";
import constants from "shared/constants";
import CityAreasField from "./CityAreasField";
import StationsField from "./StationsField";
import api from "shared/api";
import { Redirect } from "react-router-dom";

const CityForm = ({ initialCity = null }) => {
  const [redirectPath, setRedirectPath] = useState(null);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <Formik
      initialValues={{
        name: initialCity?.name || "",
        location: initialCity?.location || { longitude: "", latitude: "" },
        areas:
          initialCity?.areas?.map(area => ({
            ...area,
            children: area.children.map(child => ({
              ...child,
              value: child.id,
            })),
          })) || [],
        stations:
          initialCity?.stations?.map(station => ({
            ...station,
            value: station.id,
          })) || [],
      }}
      onSubmit={({ name, location, areas, stations }) =>
        new Promise(resolve => {
          const payload = {
            name,
            location: {
              longitude: parseFloat(location.longitude),
              latitude: parseFloat(location.latitude),
            },
          };

          if (initialCity) {
            payload.add_areas = [];
            payload.remove_areas = [];
            payload.add_stations = [];
            payload.remove_stations = [];

            areas.forEach(area => {
              // New parent areas
              if (!area.id) {
                payload.add_areas.push(area);
                return;
              }

              // Removed parent areas
              if (area.removed) {
                payload.remove_areas.push(area.id);
                return;
              }

              // New & removed child areas.
              area.children.forEach(child => {
                if (!child.id) {
                  payload.add_areas.push({
                    name: child.name,
                    parent_id: area.id,
                  });
                  return;
                }

                if (child.removed) {
                  payload.remove_areas.push(child.id);
                }
              });
            });

            stations.forEach(station => {
              if (!station.id) {
                payload.add_stations.push(station);
                return;
              }

              if (station.removed) {
                payload.remove_stations.push(station.id);
              }
            });
          } else {
            payload.areas = areas;
            payload.stations = stations;
          }

          const apiRequest = initialCity
            ? api.put(`/cities/${initialCity.id}`, payload)
            : api.post("/cities", { name, location, areas, stations });

          apiRequest
            .then(({ data }) => {
              console.log(data.data);
              setRedirectPath(`/cities`);
            })
            .finally(resolve);
        })
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required("A city needs a name."),
        location: Yup.object({
          latitude: Yup.number().required("Latitude is required."),
          longitude: Yup.number().required("Longitude is required."),
        }),
        areas: Yup.array(
          Yup.object({
            name: Yup.string().required("All city areas need a name."),
          })
        ),
        stations: Yup.array(
          Yup.object({
            name: Yup.string().required("All stations need a name."),
          })
        ),
      })}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <BootstrapCard title="Location">
            <div className="row">
              <div className="col-12 col-md-6">
                <TextField
                  className="form-group"
                  label="City name"
                  name="name"
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                <TextField
                  className="form-group"
                  label="Latitude"
                  name="location.latitude"
                />
              </div>
              <div className="col-6">
                <TextField
                  className="form-group"
                  label="Longitude"
                  name="location.longitude"
                />
              </div>
              <div className="col-12">
                <MapField
                  className="form-group"
                  label="City Location (Centre)"
                  name="location"
                />
              </div>
            </div>
          </BootstrapCard>

          <BootstrapCard
            title="City areas"
            renderAdditionalHeaderContent={() => (
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    setFieldValue("areas", [
                      ...values.areas,
                      { name: "", children: [] },
                    ])
                  }
                  type="button"
                >
                  New Area
                </button>
              </div>
            )}
          >
            <CityAreasField name="areas" />
          </BootstrapCard>

          <BootstrapCard title="Stations">
            <StationsField name="stations" />
          </BootstrapCard>

          <div className="nav row align-items-center justify-content-end pb-5">
            <div className="col-auto">
              <button
                className="btn btn-md btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? <LoadingSpinner /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GoogleApiWrapper({ apiKey: constants.googleApiKey })(CityForm);
