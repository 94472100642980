import { useField } from "formik";
import React from "react";
import cx from "classnames";

const PriceLevelField = ({ className, label, name, ...props }) => {
  const [field, , { setValue }] = useField(name);

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={props.id || name}>
          {label}
        </label>
      )}
      <div>
        {[1, 2, 3, 4, 5].map(num => (
          <span
            className={cx(
              "p-1",
              parseInt(field.value || 0, 10) >= num ? "fw-bold" : "text-muted",
              num > 1 && "ms-2"
            )}
            key={num}
            onClick={() => setValue(num)}
            role="button"
          >
            £
          </span>
        ))}
        {parseInt(field.value || 0, 10) === 5 && (
          <span className="ms-3">🥵</span>
        )}
      </div>
    </div>
  );
};

export default PriceLevelField;
