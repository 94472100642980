import React, { useState, useEffect, Fragment } from "react";
import cx from "classnames";
import PagePagination from "./PagePagination";

const GenericTable = ({
  data,
  isLoading,
  renderItem,
  renderHeader,
  renderHeaderRow,
  paginated = false,
}) => {
  const [page, setPage] = useState(
    paginated && data && data.length > 30 ? 1 : null
  );
  const [displayedData, setDisplayedData] = useState(data);

  useEffect(() => {
    setPage(paginated && data && data.length > 30 ? 1 : null);
  }, [data, paginated]);

  useEffect(() => {
    if (!page) {
      setDisplayedData(data);
    } else {
      setDisplayedData(
        data.filter(
          (item, index) => index >= (page - 1) * 20 && index <= page * 20 - 1
        )
      );
    }
  }, [page, data]);

  return (
    <Fragment>
      <div className="card">
        {renderHeader && <div className="card-header">{renderHeader()}</div>}
        {!data ? (
          <div className={cx("card-body", "text-center")}>
            {isLoading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            {/* TODO: Empty State... */}
          </div>
        ) : (
          <table className="table table-sm table-hover card-table">
            <thead>{renderHeaderRow()}</thead>
            <tbody className="list font-size-base">
              {displayedData && displayedData.map(renderItem)}
            </tbody>
            {data.length === 0 && (
              <tbody className="list font-size-base text-muted ">
                <tr>
                  <td>There's nothing to see here. #Empty</td>
                </tr>
              </tbody>
            )}
          </table>
        )}
      </div>

      {page && (
        <PagePagination
          page={page}
          maxPage={data.length / 20}
          setPage={setPage}
        />
      )}
    </Fragment>
  );
};

export default GenericTable;
