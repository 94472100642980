import React, { useMemo, useCallback } from "react";
import OpeningHoursCardRow from "./OpeningHoursCardRow";
import BootstrapCard from "shared/components/BootstrapCard";
import CopyButton from "shared/components/CopyButton";

const OpeningHoursCard = ({ title, times, closedText }) => {
  const days = useMemo(
    () => ({
      0: "Mon",
      1: "Tue",
      2: "Wed",
      3: "Thu",
      4: "Fri",
      5: "Sat",
      6: "Sun",
    }),
    []
  );

  const formattedHours = useMemo(() => {
    // Reduce to array of unique objects with key day_idx and value an array of times
    let distinct = (times || []).reduce((acc, item) => {
      const values = acc[item.day_idx];
      acc[item.day_idx] = values
        ? [...values, { start: item.start, end: item.end }]
        : [{ start: item.start, end: item.end }];
      return acc;
    }, {});
    // Map each key value to an object with keys day_idx and times
    distinct = Object.entries(distinct).map(([day_idx, dayTimes]) => ({
      day_idx: Number(day_idx),
      times: dayTimes,
    }));
    // Check if there is a day_idx for each day in days
    let complete = Object.entries(days).map(
      ([key, _]) =>
        distinct.find(d => d.day_idx === Number(key)) || {
          day_idx: Number(key),
          times: [],
        }
    );
    // Combine days that have matching times
    let combined = [];
    complete.forEach(day => {
      if (
        combined.length > 0 &&
        combined.at(-1).days.at(-1) === day.day_idx - 1 &&
        combined.at(-1).times.length === day.times.length &&
        combined
          .at(-1)
          .times.every(t =>
            day.times?.some(t2 => t.start === t2.start && t.end === t2.end)
          )
      ) {
        combined.at(-1).days.push(day.day_idx);
      } else {
        combined.push({ days: [day.day_idx], times: day.times });
      }
    });
    return combined;
  }, [times, days]);

  const getDays = useCallback(
    h => {
      return h.days.length > 1
        ? `${days[h.days[0]]} - ${days[h.days.at(-1)]}`
        : days[h.days[0]];
    },
    [days]
  );

  const stringFormattedHours = useMemo(() => {
    return formattedHours
      .map(h =>
        h.times.length
          ? `${getDays(h)} ${h.times
              .map(t => `${t.start} - ${t.end}`)
              .join(", ")}`
          : `${getDays(h)} ${closedText}`
      )
      .join("\n");
  }, [formattedHours, closedText, getDays]);

  return (
    <BootstrapCard
      bodyClassName="mb-n3"
      title={title}
      renderAdditionalHeaderContent={() => (
        <div className="col d-flex justify-content-end">
          <CopyButton text={stringFormattedHours} />
        </div>
      )}
    >
      <div className="list-group list-group-flush pt-2">
        <div className="list-group-item py-2">
          {formattedHours?.map((h, hIndex) =>
            h.times.length ? (
              <OpeningHoursCardRow
                key={hIndex}
                days={getDays(h)}
                hours={h.times.map(t => `${t.start} - ${t.end}`).join(", ")}
              />
            ) : (
              <OpeningHoursCardRow
                key={hIndex}
                days={getDays(h)}
                hours={closedText}
              />
            )
          )}
        </div>
      </div>
    </BootstrapCard>
  );
};

export default OpeningHoursCard;
