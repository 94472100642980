import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import PageHeader from "./PageHeader";

const FormPageHeader = ({ children, isSubmitting, pretitle }) => (
  <PageHeader
    pretitle={pretitle}
    renderAdditionalComponents={() => (
      <div className="col-12 col-md-auto mt-3 mt-md-none">
        <button
          className="btn btn-primary lift"
          disabled={isSubmitting}
          type="submit"
        >
          {isSubmitting ? <LoadingSpinner /> : "Save"}
        </button>
      </div>
    )}
  >
    {children}
  </PageHeader>
);

export default FormPageHeader;
