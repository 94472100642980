import React from "react";
import { Link } from "react-router-dom";
import StatusBadge from "../../../shared/components/StatusBadge";

const RewardTableRow = ({ reward }) => (
  <tr>
    <td>
      <div className="avatar avatar-xs align-middle me-2">
        <img
          alt={reward.name}
          className="avatar-img rounded-circle"
          src={reward.image_url}
        />
      </div>
      <span className="item-name text-reset">{reward.name}</span>
      <StatusBadge {...reward} />
    </td>
    <td>
      {reward.brand ? (
        <>
          <div className="avatar avatar-xs align-middle me-2">
            <img
              alt={reward.brand.name}
              className="avatar-img rounded-circle"
              src={reward.brand.logo_url}
            />
          </div>
          <span className="item-name text-reset">{reward.brand.name}</span>
        </>
      ) : (
        "None"
      )}
    </td>
    <td>
      {reward.category ? (
        <>
          <div className="avatar avatar-xs align-middle me-2">
            <img
              alt={reward.category.name}
              className="avatar-img rounded-circle"
              src={reward.category.image_url}
            />
          </div>
          <span className="item-name text-reset">{reward.category.name}</span>
        </>
      ) : (
        "None."
      )}
    </td>
    <td>{reward.created_at}</td>
    <td>
      <Link
        className="btn btn-outline-primary btn-sm"
        to={`/rewards/${reward.id}/edit`}
      >
        Edit
      </Link>
    </td>
  </tr>
);

export default RewardTableRow;
