import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { uniqueId } from "lodash";
import Button from "./Button";
import { DropdownItem } from "./DropdownWrapper";

const DropdownButton = ({
  type = "chevron",
  className,
  variant,
  tag: Tag = Button,
  disableAutoClose = false,
  children,
  label,
  ...props
}) => {
  const toggle = useRef(null);
  const menu = useRef(null);
  const [show, setShow] = useState(false);
  const [id] = useState(uniqueId());

  const handleClickAway = useCallback(
    e => {
      if (!show) return;
      if (
        show &&
        toggle.current &&
        !toggle.current.contains(e.target) &&
        menu.current &&
        !menu.current.contains(e.target)
      ) {
        setShow(false);
      }
    },
    [show]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickAway);
    return () => {
      window.removeEventListener("click", handleClickAway);
    };
  }, [handleClickAway]);

  const handleClose = () => !disableAutoClose && setShow(false);

  return (
    <>
      <span
        ref={toggle}
        onClick={e => {
          setShow(!show);
          e.stopPropagation();
          e.preventDefault();
        }}
        className={cx(
          {
            "dropdown-toggle-split": type === "chevron",
            "dropdown-ellipses": type === "ellipses",
          },
          "dropdown-toggle",
          className
        )}
        variant={variant}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={show}
        aria-labelledby={id}
        role="button"
        {...props}
      >
        {type === "ellipses" ? (
          <i className="fe fe-more-vertical" />
        ) : (
          <span className="sr-only">{label || "Toggle Dropdown"}</span>
        )}
      </span>
      <div
        ref={menu}
        className={cx(
          "dropdown-menu dropdown-menu-end",
          show ? "show" : undefined
        )}
        aria-labelledby={id}
        onClick={handleClose}
      >
        {children}
      </div>
    </>
  );
};

DropdownButton.propTypes = {
  type: PropTypes.oneOf(["chevron", "ellipses"]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(DropdownItem),
  ]),
};

export default DropdownButton;
