import { addToServiceFeedback } from "app/serviceFeedback/actions";
import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

const FormValidationNotification = () => {
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext();
  const dispatch = useDispatch();

  const rows = useMemo(() => {
    const fn = (acc, [key, value], prefix) => {
      if (typeof value === "string") {
        acc.push(`${prefix || ""}${key}: ${value}`);
        return acc;
      }

      if (Array.isArray(value)) {
        for (let idx = 0; idx < value.length; idx++) {
          const element = value[idx];
          fn(acc, [`${idx}`, element], `${prefix || ""}${key}.`);
        }
        return acc;
      }

      if (typeof value === "object") {
        Object.entries(value).reduce(
          (_acc2, [key2, value2]) =>
            fn(acc, [key2, value2], `${prefix || ""}${key}.`),
          acc
        );
      }

      return acc;
    };

    return Object.entries(errors).reduce(
      (acc, [key, value]) => fn(acc, [key, value]),
      []
    );
  }, [errors]);

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      dispatch(
        addToServiceFeedback({
          type: "danger",
          title: "Required fields missing",
          message: rows,
        })
      );
    }
  }, [isSubmitting, isValid, isValidating, dispatch, rows]);

  return null;
};

export default FormValidationNotification;
