import { useField } from "formik";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import cx from "classnames";
import TooltipIcon from "../TooltipIcon";

const DateTimeField = ({
  className,
  label,
  maxDate,
  minDate,
  name,
  tooltip,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);

  const id = useMemo(() => props.id || name || uuidv4(), [name, props.id]);

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={id}>
          {label}
          {tooltip && <TooltipIcon label={tooltip} className="ms-1" />}
        </label>
      )}
      <DatePicker
        disabled={props.disabled}
        className={cx("form-control", props.disabled && "opacity-50")}
        maxDate={maxDate}
        minDate={minDate}
        onChange={date => setValue(date)}
        placeholderText="Select a Date &amp; Time"
        selected={
          field.value && typeof field.value === "string"
            ? moment(field.value).toDate()
            : field.value || null
        }
        dateFormat="h:mm aa, do MMM yyyy"
        showTimeSelect
      />
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default DateTimeField;
