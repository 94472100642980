import React from "react";
import TableHeaderRow from "../../../shared/components/TableHeaderRow";

const RewardTableHeaderRow = () => (
  <TableHeaderRow
    columns={["Name", "Brand", "Category", "Created", "Actions"]}
  />
);

export default RewardTableHeaderRow;
