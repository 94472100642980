import { Form, Formik } from "formik";
import cx from "classnames";
import React, { useState } from "react";
import api from "../../shared/api";
import * as Yup from "yup";
import formValidation from "../../shared/formValidation";
import Textbox from "../../shared/components/Textbox";
import Button from "../../shared/components/Button";
import styles from "./AuthPage.module.scss";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { completeLogin } from "./actions";

const ConfirmAccount = () => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { state } = useLocation();

  console.log("Location State: ", state);

  const onFormSubmit = (
    { email, tmp_password, new_password, first_name, last_name },
    { setSubmitting }
  ) => {
    setSubmitting(true);
    setError(false);

    api
      .post("/auth/confirm-account", {
        email,
        tmp_password,
        new_password,
        first_name,
        last_name,
      })
      .then(({ data }) => {
        setSubmitting(false);
        dispatch(completeLogin(data.data));
      })
      .catch(err => {
        setSubmitting(false);
        if (err && err.response) {
          setError(err.response.data.error);
        }

        console.log(err, err.response);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        tmp_password: "",
        new_password: "",
        first_name: "",
        last_name: "",
        ...(state || {}),
      }}
      onSubmit={onFormSubmit}
      validationSchema={Yup.object().shape({
        email: formValidation.email,
        tmp_password: formValidation.password,
        new_password: formValidation.password,
        first_name: formValidation.name,
        last_name: formValidation.name,
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <Textbox label="Email" name="email" placeholder="jane@dusk.app" />
          <Textbox
            label="Temporary password"
            name="tmp_password"
            placeholder="••••••••"
            type="password"
          />
          <Textbox
            label="New password"
            name="new_password"
            placeholder="••••••••"
            type="password"
          />
          <Textbox label="First name" name="first_name" placeholder="Jane" />
          <Textbox label="Last name" name="last_name" placeholder="Doe" />
          <Button
            className={cx(styles.button, "btn-block")}
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Log in"
            )}
          </Button>
          {error && <p className="text-danger mt-3 mb-0">{error}</p>}
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmAccount;
