import React from "react";
import { Link } from "react-router-dom";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";
import { formatDateTime } from "shared/utilities";

const OfferRedemptionListTable = ({ offerId }) => (
  <LocalStateListPageContents
    apiPath="/offer-redemptions"
    apiParams={{ offer_id: offerId }}
    renderHeader={() => (
      <div className="card-header">
        <h4 className="card-header-title">Redemptions</h4>
      </div>
    )}
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Customer</th>
          <th>Venue</th>
          <th>Date</th>
        </tr>
      </thead>
    )}
    renderRow={redemption => (
      <tr key={redemption.id}>
        <td>
          <Link
            className="d-flex align-items-center"
            to={`/customers/${redemption.customer.id}`}
          >
            <span>
              {[redemption.customer.first_name, redemption.customer.last_name]
                .filter(Boolean)
                .join(" ")}
            </span>
          </Link>
        </td>
        <td>
          <Link to={`/venues/${redemption.venue.id}`}>
            {redemption.venue.name}
          </Link>
        </td>
        <td>{formatDateTime(redemption.created_at)}</td>
      </tr>
    )}
  />
);

export default OfferRedemptionListTable;
