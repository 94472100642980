import axios from "axios";
import constants from "./constants";
import { addToServiceFeedback } from "app/serviceFeedback/actions";
import store from "../redux/store";

const api = axios.create({
  baseURL: constants.apiBaseURL,
  withCredentials: false,
});

const getErrorHeader = config => {
  const actions = {
    get: "getting",
    put: "updating",
    patch: "updating",
    post: "creating",
    delete: "deleting",
  };
  return `Error ${actions[config.method]} ${config.url
    .split("/")[1]
    .slice(0, -1)}`;
};

api.interceptors.response.use(
  response => response,
  error => {
    if ([400, 404, 500].includes(error.response?.status)) {
      const config = error.response.config;
      store.dispatch(
        addToServiceFeedback({
          type: "danger",
          title: getErrorHeader(config),
          message: error.response.data.error,
          error: {
            method: config.method,
            url: config.baseURL + config.url,
            status: error.response.status,
            timestamp: new Date().toISOString(),
            data: config.data,
          },
        })
      );
    }
    throw error;
  }
);

export default api;
