import React from "react";
import { Route, Switch } from "react-router-dom";
import OfferCreatePage from "./offers/OfferCreatePage";
import OfferDetailPage from "./offers/OfferDetailPage";
import OfferEditPage from "./offers/OfferEditPage";
import OfferListPage from "./offers/OfferListPage";

const OfferSection = () => (
  <Switch>
    <Route path="/offers/create" component={OfferCreatePage} />
    <Route exact path="/offers/:id/edit" component={OfferEditPage} />
    <Route exact path="/offers/:id" component={OfferDetailPage} />
    <Route component={OfferListPage} />
  </Switch>
);

export default OfferSection;
