import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import api from "../../shared/api";
import FormSubmitButton from "../../shared/components/FormSubmitButton";
import FormWrapper from "../../shared/components/FormWrapper";
import PageHeader from "../../shared/components/PageHeader";
import Textbox from "../../shared/components/Textbox";
import formValidation from "../../shared/formValidation";
import { extractAPIErrorString } from "shared/utils/extractErrors";

const ChangePassword = () => {
  const [redirect, setRedirect] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const userEmail = useSelector(state => state.auth.user.email);

  const onFormSubmit = (
    { email, old_password, new_password },
    { setSubmitting }
  ) => {
    setErrorText(null);
    setSubmitting(true);

    api
      .post("/auth/change-password", { email, old_password, new_password })
      .then(
        () => {
          setSubmitting(false);
          setRedirect(true);
        },
        extractAPIErrorString(err => {
          setErrorText(err);
          setSubmitting(false);
        })
      );
  };

  if (redirect) {
    return <Redirect to={"/"} />;
  }

  return (
    <FormWrapper>
      <PageHeader pretitle="dusk admin">Change password</PageHeader>
      <Formik
        initialValues={{ email: userEmail, old_password: "", new_password: "" }}
        onSubmit={onFormSubmit}
        validationSchema={Yup.object().shape({
          old_password: formValidation.password,
          new_password: formValidation.password,
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <Textbox
              label="Current password"
              placeholder="••••••••"
              type="password"
              name="old_password"
            />
            <Textbox
              label="New password"
              placeholder="••••••••"
              type="password"
              name="new_password"
            />
            <hr className="my-5" />
            <div className="row pb-5">
              <div className="col text-md-right">
                <FormSubmitButton isSubmitting={isSubmitting}>
                  Update password
                </FormSubmitButton>
                {errorText && <p className="text-danger mt-2">{errorText}</p>}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default ChangePassword;
