import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import api from "../../shared/api";
import FormWrapper from "../../shared/components/FormWrapper";
import PageHeader from "../../shared/components/PageHeader";
import RewardCollectionForm from "./createUpdate/RewardCollectionForm";

const RewardCollectionCreatePage = () => {
  const [createdCollection, setCreatedCollection] = useState(null);

  const onFormSubmit = formData => {
    console.log("Form: ", formData);
    return new Promise((resolve, reject) => {
      api
        .post("/reward-collections", formData)
        .then(({ data }) => {
          setCreatedCollection(data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  if (createdCollection) {
    return (
      <Redirect
        to={{ pathname: `/reward-collections/${createdCollection.id}` }}
      />
    );
  }

  return (
    <FormWrapper>
      <PageHeader pretitle="dusk admin">Create a Reward Collection</PageHeader>
      <RewardCollectionForm onFormSubmit={onFormSubmit} />
    </FormWrapper>
  );
};

export default RewardCollectionCreatePage;
