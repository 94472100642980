import React, { useEffect } from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";

const AutoDismissModalButton = ({
  onClose,
  isLoading,
  isSuccess = false,
  buttonText,
  buttonTextSuccess,
}) => {
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  }, [isSuccess, onClose]);
  return (
    <button className="btn btn-primary" type="submit">
      {isSuccess && <span className="fe fe-check me-2" />}
      {isLoading ? (
        <LoadingSpinner />
      ) : isSuccess ? (
        buttonTextSuccess
      ) : (
        buttonText
      )}
    </button>
  );
};

export default AutoDismissModalButton;
