import React from "react";
// import ClientSideTable from "./ClientSideTable";

const TableLibrary = () => {
  return (
    <section className="mb-5">
      <h2>The Tables have turned.</h2>
      <p>Oops.. the client side table broke. Check back later!</p>
      {/* <ClientSideTable /> */}
    </section>
  );
};

export default TableLibrary;
