import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

const FormLeavePrompt = () => {
  const formik = useFormikContext();
  const history = useHistory();

  const unsavedChangesCallback = useMemo(
    () => e => {
      let message = "You have unsaved changes, are you sure you want to leave?";
      e.returnValue = message;
      return message;
    },
    []
  );

  // Add beforeunload listener, called on page reload, removed when form "clean"
  useEffect(() => {
    if (formik.dirty) {
      window.addEventListener("beforeunload", unsavedChangesCallback);
    } else {
      window.removeEventListener("beforeunload", unsavedChangesCallback);
    }
  }, [formik.dirty, unsavedChangesCallback]);

  // Prevent history change if form dirty, remove beforeunload event listener on submit or confirm
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (formik.dirty) {
        if (
          formik.isSubmitting ||
          // eslint-disable-next-line no-alert
          window.confirm(
            "You have unsaved changes, are you sure you want to leave?"
          )
        ) {
          window.removeEventListener("beforeunload", unsavedChangesCallback);
          return;
        }
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history, unsavedChangesCallback, formik.dirty, formik.isSubmitting]);

  return null;
};

export default FormLeavePrompt;
