import React from "react";
import BootstrapCard from "./BootstrapCard";

const DataCard = ({ children, icon, label }) => (
  <BootstrapCard>
    <div className="row align-items-center gx-0">
      <div className="col">
        <h6 className="text-uppercase text-muted mb-2">{label}</h6>
        <span className="h2 mb-0">{children}</span>
      </div>
      {icon && (
        <div className="col-auto">
          <span className={`h2 fe fe-${icon} text-muted mb-0`} />
        </div>
      )}
    </div>
  </BootstrapCard>
);

export default DataCard;
