import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DraggableList = ({ list, onDragged, ListComponent, isDragDisabled }) => {
  function handleOnDragEnd(result) {
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    onDragged(items);
  }

  if (!list) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="guides">
        {provided => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="list-unstyled"
          >
            {list.map((item, index) => {
              return (
                <Draggable
                  key={item.id.toString()}
                  draggableId={item.id.toString()}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {listItemProvided => (
                    <div
                      ref={listItemProvided.innerRef}
                      {...listItemProvided.draggableProps}
                    >
                      <li {...listItemProvided.dragHandleProps}>
                        <ListComponent
                          isDraggable={!isDragDisabled}
                          {...item}
                        />
                      </li>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
