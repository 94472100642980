import React, { useState } from "react";
import SidebarNavigationLink from "../../shared/components/SidebarNavigationLink";
import NavigationProfileIcon from "../../shared/components/NavigationProfileIcon";
import NavigationSidebarSubmenu from "./NavigationSidebarSubmenu";
import { Link } from "react-router-dom";

const NavigationSidebar = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light">
      <div className="container-fluid">
        {/* Mobile Toggle */}
        <button
          className="navbar-toggler collapsed"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* DUSK logo at the top */}
        <Link className="navbar-brand" to="/">
          <img
            alt="dusk"
            className="navbar-brand-img mx-auto"
            src={require("../../assets/images/dusk-logo.png")}
            width={150}
          />
        </Link>

        {/* Mobile profile icon */}
        <NavigationProfileIcon className="d-block d-md-none" />

        {/* Navigation Items */}
        <div className={`navbar-collapse ${collapsed ? "collapse" : ""}`}>
          {/* Home */}
          <ul className="navbar-nav d-none d-md-block">
            <SidebarNavigationLink
              icon="fe-home"
              url="/"
              onNavigation={() => setCollapsed(true)}
            >
              Home
            </SidebarNavigationLink>
          </ul>

          {/* Customer Links */}
          <NavigationSidebarSubmenu
            onNavigation={() => setCollapsed(true)}
            title="Customers"
            menuItems={[
              {
                icon: "fe-users",
                url: "/customers",
                title: "Customers",
              },
              {
                icon: "fe-credit-card",
                url: "/cards",
                title: "Cards",
              },
            ]}
          />

          {/* Venue Links */}
          <NavigationSidebarSubmenu
            onNavigation={() => setCollapsed(true)}
            title="Venues"
            menuItems={[
              {
                icon: "campaign",
                iconVariant: "round",
                url: "/campaigns",
                title: "Campaigns",
              },
              {
                icon: "fe-home",
                url: "/venues",
                title: "Venues",
              },
              {
                icon: "local_bar",
                url: "/offers",
                title: "Offers",
              },
              {
                icon: "fe-book-open",
                url: "/guides",
                title: "Guides",
              },
              {
                icon: "apps",
                url: "/venue-chains",
                title: "Groups",
              },
              {
                icon: "app_registration",
                url: "/venue-collections",
                title: "Collections",
              },
              {
                icon: "fe-hash",
                url: "/venue-categories",
                title: "Categories",
              },
              {
                icon: "location_city",
                iconVariant: "round",
                url: "/cities",
                title: "Cities",
              },
            ]}
          />

          {/* Rewards Links */}
          <NavigationSidebarSubmenu
            onNavigation={() => setCollapsed(true)}
            title="Rewards"
            menuItems={[
              {
                icon: "fe-star",
                url: "/rewards",
                title: "Rewards",
              },
              {
                icon: "fe-briefcase",
                url: "/reward-brands",
                title: "Brands",
              },
              {
                icon: "app_registration",
                url: "/reward-collections",
                title: "Collections",
              },
              {
                icon: "fe-hash",
                url: "/reward-categories",
                title: "Categories",
              },
            ]}
          />

          {/* Admin Links */}
          <NavigationSidebarSubmenu
            onNavigation={() => setCollapsed(true)}
            title="Admin"
            menuItems={[
              {
                icon: "admin_panel_settings",
                iconVariant: "outlined",
                url: "/admin-users",
                title: "Admin Users",
              },
            ]}
          />
        </div>

        {/* Desktop profile icon */}
        <NavigationProfileIcon className="d-none d-md-block mt-3" />
      </div>
    </nav>
  );
};

export default NavigationSidebar;
