import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import moment from "moment";

import PageHeader from "shared/components/PageHeader";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import TableHeaderSortable from "shared/components/TableHeaderSortable";
import VenueCollectionQuickActionButton from "./listPage/VenueCollectionQuickActionButton";

const VenueCollectionListPage = () => {
  const history = useHistory();
  const [resetKey, setResetKey] = useState(null);

  const [sortParams, setSortParams] = useQueryParams({
    sort: withDefault(StringParam, null),
  });

  return (
    <div>
      <Helmet>
        <title>Venue Collections</title>
      </Helmet>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto">
            <Link
              to="/venue-collections/create"
              className="btn btn-primary d-block lift"
            >
              Create a Collection
            </Link>
          </div>
        )}
      >
        Venue Collections
      </PageHeader>
      <QueryStringListPageContents
        apiPath="/venue-collections"
        sortParams={sortParams}
        setSortParams={setSortParams}
        resetKey={resetKey}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Name", sortBy: "name" },
              { label: "Venues", sortBy: "venue_count" },
              { label: "Modified", sortBy: "updated_at" },
              { label: "Actions" },
            ]}
            setSortParams={setSortParams}
            sortParams={sortParams}
          />
        )}
        renderRow={collection => (
          <tr key={collection.id}>
            <td
              role="button"
              onClick={e => {
                history.push(`/venue-collections/${collection.id}/edit`);
              }}
            >
              {collection.name}
            </td>
            <td
              role="button"
              onClick={e => {
                history.push(`/venue-collections/${collection.id}/edit`);
              }}
            >
              {collection.venue_count}
            </td>
            <td
              role="button"
              onClick={e => {
                history.push(`/venue-collections/${collection.id}/edit`);
              }}
            >
              {moment(collection.updated_at).format("Do MMM YYYY")}
            </td>
            <td>
              <VenueCollectionQuickActionButton
                venue={collection}
                className="menu-icon"
                handleDelete={() => setResetKey(moment())}
              />
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default VenueCollectionListPage;
