import React, { useCallback, useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import Helmet from "react-helmet";
import api from "shared/api";
import FormSpinner from "shared/components/FormSpinner";
import PageHeader from "shared/components/PageHeader";
import { badgesForVenue } from "./shared";
import cx from "classnames";
import BootstrapCard from "shared/components/BootstrapCard";
import SettingListSwitchItem from "shared/components/SettingListSwitchItem";
import MediaBlock from "./detailPage/MediaBlock";
import VenueEditorialBlock from "./detailPage/VenueEditorialBlock";
import VenueOfferListBlock from "./detailPage/VenueOfferListBlock";
import DeleteModal from "shared/modals/DeleteModal";
import VenueOfferRedemptionListTable from "./detailPage/VenueOfferRedemptionListTable";
import LocationBlock from "./detailPage/LocationBlock";
import StaticMap from "shared/components/StaticMap";
import OpeningHoursCard from "./detailPage/OpeningHoursCard";

const VenueDetailPage = () => {
  const { id: venueId } = useParams();

  const [updatingParam, setUpdatingParam] = useState(null);

  const [venue, setVenue] = useState(null);
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (venue && venue.id === parseInt(venueId, 10)) {
      return;
    }

    api.get(`/venues/${venueId}`).then(({ data }) => {
      setVenue(data.data);
    });
  }, [venue, venueId]);

  const deleteVenue = useCallback(() => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    api
      .delete(`/venues/${venueId}`)
      .then(() => setRedirect("/venues"))
      .finally(() => setIsDeleting(false));
  }, [isDeleting, venueId]);

  const updateVenue = useCallback(
    (param, value) => {
      if (updatingParam) {
        return;
      }

      setUpdatingParam(param);

      api.put(`/venues/${venueId}`, { [param]: value }).then(({ data }) => {
        setVenue({
          ...venue,
          ...data.data,
        });
        setUpdatingParam(null);
      });
    },
    [venue, venueId, updatingParam]
  );

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (!venue) {
    return <FormSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{`${venue.name} | Venues`}</title>
      </Helmet>
      <PageHeader
        pretitle="venues"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link
              className="btn btn-primary lift"
              to={`/venues/${venueId}/edit`}
            >
              Edit
            </Link>
            <button
              className="btn btn-outline-danger lift ms-3"
              onClick={() => setIsShowingDeleteModal(true)}
            >
              Remove
            </button>
          </div>
        )}
      >
        {venue.name}
        <span className="h5 ms-2 align-middle">
          {badgesForVenue(venue).map((badge, idx) => (
            <span className={cx(`badge bg-${badge.variant}`, "ms-2")} key={idx}>
              {badge.label}
            </span>
          ))}
        </span>
      </PageHeader>
      <div className="row">
        <div className="col-12 col-lg-8">
          <BootstrapCard title="Settings">
            <div className="list-group list-group-flush my-n3">
              <SettingListSwitchItem
                icon="eye"
                isChecked={Boolean(venue.published_at)}
                isLoading={updatingParam === "published_at"}
                title="Is Published"
                subtitle="Toggling on will immediately publish to app. Off will remove."
                onChange={e => updateVenue("published_at", e)}
              />
              <SettingListSwitchItem
                icon="credit-card"
                isChecked={venue.offers_require_card_link}
                isLoading={updatingParam === "offers_require_card_link"}
                title="Requires Linked Card"
                subtitle="When true, venue requires customers to have a linked card in order to redeem."
                onChange={e => updateVenue("offers_require_card_link", e)}
              />
              <SettingListSwitchItem
                icon="cpu"
                isChecked={venue.is_test}
                isLoading={updatingParam === "is_test"}
                title="Test Venue"
                subtitle="When true, venue is only visible to test users."
                onChange={e => updateVenue("is_test", e)}
              />
            </div>
          </BootstrapCard>
          <VenueEditorialBlock venue={venue} />
          {venue.offers && (
            <VenueOfferListBlock
              onOfferAdded={offers =>
                setVenue({
                  ...venue,
                  offers: offers,
                })
              }
              onOfferRemoved={offerId =>
                setVenue({
                  ...venue,
                  offers: venue.offers.filter(
                    ({ id }) => String(offerId) !== String(id)
                  ),
                })
              }
              onOfferUpdated={offer =>
                setVenue({
                  ...venue,
                  offers: venue.offers.map(existingOffer =>
                    String(existingOffer.id) === String(offer.id)
                      ? offer
                      : existingOffer
                  ),
                })
              }
              venue={venue}
            />
          )}
          <VenueOfferRedemptionListTable venueId={venueId} />
        </div>
        <div className="col-12 col-lg-4">
          <BootstrapCard bodyClassName="mb-n3" title="Media">
            <MediaBlock mediaUrls={venue.media_urls} />
          </BootstrapCard>
          <BootstrapCard title="Location">
            {venue.location?.latitude && (
              <StaticMap location={venue.location} />
            )}
            <LocationBlock venue={venue} />
          </BootstrapCard>
          <OpeningHoursCard
            title="Opening Hours"
            times={venue.opening_times}
            closedText="Closed"
          />
          <OpeningHoursCard
            title="Offer Availability"
            times={venue.base_redemption_times}
            closedText="N/A"
          />
        </div>
      </div>
      {/* Modals! */}
      <DeleteModal
        isDeleting={isDeleting}
        isOpen={isShowingDeleteModal}
        item="Venue"
        handleAction={deleteVenue}
        handleClose={() => setIsShowingDeleteModal(false)}
      />
    </div>
  );
};

export default VenueDetailPage;
