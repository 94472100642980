import React from "react";
import { Link } from "react-router-dom";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";
import { formatDateTime } from "shared/utilities";

const CustomerOfferRedemptionListTable = ({ customerId }) => (
  <LocalStateListPageContents
    apiPath="/offer-redemptions"
    apiParams={{ customer_id: customerId }}
    renderHeader={() => (
      <div className="card-header">
        <h4 className="card-header-title">Offer Redemptions</h4>
      </div>
    )}
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Offer</th>
          <th>Venue</th>
          <th>Date</th>
        </tr>
      </thead>
    )}
    renderRow={redemption => (
      <tr key={redemption.id}>
        <td>
          <Link
            className="d-flex align-items-center"
            to={`/offers/${redemption.offer.id}`}
          >
            {redemption.offer.icon_url && (
              <div
                className="d-flex justify-content-center me-2"
                style={{ width: "30px", height: "20px" }}
              >
                <img
                  alt={redemption.offer.name}
                  src={redemption.offer.icon_url}
                  height="20"
                />
              </div>
            )}
            <span>{redemption.offer.name}</span>
          </Link>
        </td>
        <td>
          <Link to={`/venues/${redemption.venue.id}`}>
            {redemption.venue.name}
          </Link>
        </td>
        <td>{formatDateTime(redemption.created_at)}</td>
      </tr>
    )}
  />
);

export default CustomerOfferRedemptionListTable;
