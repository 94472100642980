import { useField } from "formik";
import React, { useMemo } from "react";
import MaskedTextField from "./MaskedTextField";
import cx from "classnames";
import TooltipIcon from "shared/components/TooltipIcon";

import styles from "./WeeklyHoursFieldRow.module.scss";

const WeeklyHoursFieldRow = ({
  name,
  onUpdate,
  onRemove,
  boundaryHours = [],
}) => {
  const [field] = useField(name);
  const redemptionHoursValid = useMemo(() => {
    return Boolean(
      boundaryHours?.find(
        ot => ot.start <= field.value.start && ot.end >= field.value.end
      )
    );
  }, [boundaryHours, field]);
  return (
    <div className="row align-items-center form-group">
      <div className="col-auto">
        <select
          className="form-select form-select-sm"
          onChange={e => onUpdate({ day_idx: parseInt(e.target.value, 10) })}
          value={field.value.day_idx || 0}
        >
          {[
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ].map((dayName, idx) => (
            <option key={idx} value={String(idx)}>
              {dayName}
            </option>
          ))}
        </select>
      </div>
      <div className="col d-flex flex-row align-items-center ps-md-5">
        <MaskedTextField
          alwaysShowMask
          inputClassName={cx("form-control-sm", styles.field)}
          mask="99:99"
          name={`${name}.start`}
        />
        <MaskedTextField
          alwaysShowMask
          className="ms-3"
          inputClassName={cx("form-control-sm", styles.field)}
          mask="99:99"
          name={`${name}.end`}
        />
        <span
          className="fe fe-x-circle ms-2"
          onClick={onRemove}
          role="button"
        />
        {Boolean(boundaryHours.length) && !redemptionHoursValid && (
          <TooltipIcon
            label="Redemption hours are outside the venue opening times"
            placement="right"
            className="d-flex"
            icon="alert-circle"
            iconClass="text-warning ms-3"
          />
        )}
      </div>
    </div>
  );
};

export default WeeklyHoursFieldRow;
