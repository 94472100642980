import React, { useCallback } from "react";
import cx from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./RewardCollectionRewardList.module.scss";
import Button from "../../../shared/components/Button";

const RewardCollectionRewardList = ({
  className,
  rewards,
  onRemove,
  onReorder,
}) => {
  const onDragEnd = useCallback(
    ({ source, destination }) => {
      if (source && destination) {
        onReorder(source.index, destination.index);
      }
    },
    [onReorder]
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="collection-rewards" direction="vertical">
        {provided => (
          <div
            className={cx(
              "list-group list-group-md list-group-flush",
              className
            )}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {rewards.map((reward, idx) => (
              <Draggable
                draggableId={`reward-${reward.id}`}
                index={idx}
                key={reward.id}
              >
                {providedDraggable => (
                  <div
                    className={cx("list-group-item", styles.listItem)}
                    key={reward.id}
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                  >
                    <div className="kanban-category">
                      <div className="kanban-item">
                        <div className="card card-sm mb-0">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="my-0">{reward.name}</p>
                              </div>
                              <div className="ms-auto col-auto">
                                <Button
                                  buttonType="danger"
                                  className="btn-sm"
                                  onClick={() => onRemove(idx)}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RewardCollectionRewardList;
