import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import BootstrapCard from "shared/components/BootstrapCard";
import DailyRedemptionLimitsField from "shared/components/formFields/DailyRedemptionLimitsField";
import WeeklyHoursField from "shared/components/formFields/WeeklyHoursField";

const VenueHoursForm = ({ googlePlaceData }) => {
  const { setFieldValue, values } = useFormikContext();

  const onGoogleRefreshClick = useCallback(() => {
    setFieldValue("opening_times", googlePlaceData.opening_times || []);
  }, [googlePlaceData, setFieldValue]);

  const onCopyOpeningTimesToAvailabilityClick = useCallback(() => {
    setFieldValue(
      "base_redemption_times",
      values.opening_times.map(period => ({
        ...period,
        start: period.start < "12:00" ? "12:00" : period.start,
        end: period.end < "06:00" ? "00:00" : period.end,
      }))
    );
  }, [values?.opening_times, setFieldValue]);
  return (
    <>
      <BootstrapCard
        title="Opening hours"
        renderAdditionalHeaderContent={() =>
          googlePlaceData?.opening_times ? (
            <div className="col-auto">
              <button
                className="btn btn-white d-flex align-items-center"
                onClick={onGoogleRefreshClick}
                type="button"
              >
                <span className="fe fe-refresh-ccw me-2" />
                Refresh from Google
              </button>
            </div>
          ) : null
        }
      >
        <WeeklyHoursField name="opening_times" />
      </BootstrapCard>
      <BootstrapCard
        title="Offer availability"
        renderAdditionalHeaderContent={() =>
          values.opening_times ? (
            <div className="col-auto">
              <button
                className="btn btn-white d-flex align-items-center"
                onClick={onCopyOpeningTimesToAvailabilityClick}
                type="button"
              >
                <span className="fe fe-copy me-2" />
                Copy opening hours
              </button>
            </div>
          ) : null
        }
      >
        <WeeklyHoursField
          name="base_redemption_times"
          hoursToCompare={values.opening_times}
        />
      </BootstrapCard>
      <BootstrapCard title="Daily drink limits">
        <div className="row">
          <div className="col-12 col-md-6">
            <DailyRedemptionLimitsField
              name="daily_redemption_limits"
              baseTooltip="Total number of drinks that can be redeemed each day. If empty, there is no limit on redemptions per day."
              dailyTooltip="Apply limits to specific days of the week. If day is not entered, it will refer to daily limit."
            />
          </div>
        </div>
      </BootstrapCard>
    </>
  );
};

export default VenueHoursForm;
