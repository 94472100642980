import React from "react";
import cx from "classnames";

const PagePagination = ({
  setPage: gotoPage,
  page = 1,
  maxPage = 1,
  className,
}) => {
  const totalPages = [];
  for (let i = 1; i <= maxPage; i++) {
    totalPages.push(i);
  }

  // react-table starts from 0..
  const setPage = newPage => {
    gotoPage(newPage - 1);
  };

  return (
    <nav className={cx(className)} aria-label="pagination navigation">
      <ul className="pagination">
        {maxPage > 5 && page > 3 && (
          <li className="page-item page-link" onClick={() => setPage(1)}>
            First
          </li>
        )}
        {page > 1 && (
          <li className="page-item page-link" onClick={() => setPage(page - 1)}>
            Previous
          </li>
        )}
        {totalPages.map(number => (
          <li
            className={`page-item 
            ${page === number && "active"}
            ${page - number < -2 && "d-none"}
            ${page - number > 2 && "d-none"}
            `}
            onClick={() => setPage(number)}
            key={number}
          >
            <span className="page-link">{number}</span>
          </li>
        ))}
        {page < maxPage && (
          <li className="page-item page-link" onClick={() => setPage(page + 1)}>
            Next
          </li>
        )}
        {maxPage > 5 && page < maxPage - 2 && (
          <li className="page-item page-link" onClick={() => setPage(maxPage)}>
            Last
          </li>
        )}
      </ul>
    </nav>
  );
};

export default PagePagination;
