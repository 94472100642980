import { useField } from "formik";
import React, { useCallback } from "react";
import WeeklyHoursFieldRow from "./WeeklyHoursFieldRow";

const WeeklyHoursField = ({ name, hoursToCompare = [] }) => {
  const [field, , { setValue }] = useField(name);
  const onRowUpdate = useCallback(
    (partial, idx) => {
      // Update the relevant row
      const updatedSlices = field.value.map((row, rowIdx) =>
        idx === rowIdx
          ? {
              ...(row || {}),
              ...partial,
            }
          : row
      );

      // Re-sort the slices incase a day was changed
      updatedSlices.sort((a, b) => {
        if (a.day_idx !== b.day_idx) {
          return a.day_idx - b.day_idx;
        }

        if (a.start > b.start) {
          return 1;
        }

        return a.start < b.start ? -1 : 0;
      });

      // Update the Formik value
      setValue(updatedSlices);
    },
    [field.value, setValue]
  );

  const onNewRow = useCallback(() => {
    const lastDay =
      field.value && field.value.length > 0
        ? field.value[field.value.length - 1]
        : null;

    const nextDayIdx = lastDay ? Math.min(6, lastDay.day_idx + 1) : 0;

    setValue([
      ...(field.value || []),
      {
        day_idx: nextDayIdx,
        start: lastDay?.start || "",
        end: lastDay?.end || "",
      },
    ]);
  }, [field.value, setValue]);

  const boundaryHours = useCallback(
    idx =>
      hoursToCompare?.filter(ot => ot.day_idx === field.value[idx].day_idx),
    [hoursToCompare, field]
  );

  return (
    <div>
      {(field.value || []).map((slice, idx) => (
        <WeeklyHoursFieldRow
          key={idx}
          name={`${name}.${idx}`}
          onUpdate={partial => onRowUpdate(partial, idx)}
          onRemove={() => setValue(field.value.filter(row => slice !== row))}
          slice={slice}
          boundaryHours={boundaryHours(idx)}
        />
      ))}
      <button
        className="btn btn-primary btn-sm"
        onClick={onNewRow}
        type="button"
      >
        Add Row
      </button>
    </div>
  );
};

export default WeeklyHoursField;
