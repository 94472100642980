import React, { useState } from "react";
import cx from "classnames";

import DeleteModal from "shared/modals/DeleteModal";

import styles from "./DeleteVenueSetButton.module.scss";

const DeleteVenueSetButton = ({ handleDelete }) => {
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState();
  return (
    <div className="col-auto">
      <button
        className="btn btn-sm btn-white"
        onClick={() => {
          setIsShowingDeleteModal(true);
        }}
        type="button"
      >
        <span className="fe fe-trash-2" />
      </button>
      <DeleteModal
        isOpen={isShowingDeleteModal}
        handleClose={() => setIsShowingDeleteModal(false)}
        handleAction={() => handleDelete() && setIsShowingDeleteModal(false)}
      >
        <p className={cx(styles.message, "my-0")}>
          Are you sure you wish to delete this set of offers and venues?
        </p>
      </DeleteModal>
    </div>
  );
};

export default DeleteVenueSetButton;
