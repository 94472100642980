import React from "react";
import cx from "classnames";
import moment from "moment";

export const renderBadgesForCampaign = campaign => {
  const now = new Date();

  return [
    campaign.published_from === null && {
      variant: "light",
      label: "Unpublished",
    },
    campaign.published_until !== null &&
      moment(campaign.published_until).toDate() <= now && {
        variant: "danger",
        label: "Expired",
      },
    campaign.published_from &&
      moment(campaign.published_from).toDate() > now && {
        variant: "primary",
        label: "Scheduled",
      },
    campaign.published_from &&
      !campaign.is_paused &&
      moment(campaign.published_from).toDate() < now &&
      (campaign.published_until === null ||
        moment(campaign.published_until).toDate() > now) && {
        variant: "success",
        label: "Live",
      },
    (campaign.total_redemption_limit ||
      campaign.daily_redemption_limits?.base ||
      campaign.daily_redemption_limits?.days?.length) && {
      variant: "info",
      label: "Limited",
    },
    campaign.is_paused && {
      variant: "secondary",
      label: "Paused",
    },
    campaign.is_test && {
      variant: "warning",
      label: "Test mode",
    },
  ]
    .filter(Boolean)
    .map((badge, idx) => (
      <span className={cx(`badge bg-${badge.variant}`, idx > 0 && "ms-2")}>
        {badge.label}
      </span>
    ));
};
