import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";

import styles from "./HomePage.module.scss";
import PageHeader from "../shared/components/PageHeader";
import axios from "axios";

const HomePage = () => {
  const [randomJoke, setRandomJoke] = useState(null);

  const jokeTypes = useMemo(() => {
    const categories = ["Programming", "Misc", "Pun", "Spooky"];
    if (new Date().getMonth() >= 10) {
      categories.push("Christmas");
    }

    return categories.join(",");
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://v2.jokeapi.dev/joke/${jokeTypes}?blacklistFlags=nsfw,religious,racist,sexist`
      )
      .then(
        ({ data }) => {
          if (!data.error) {
            setRandomJoke(data);
          }
        },
        error => {
          console.log(error);
        }
      );
  }, [jokeTypes]);

  return (
    <div className={cx(styles.container)}>
      <PageHeader pretitle="dusk admin">Home</PageHeader>
      {randomJoke && (
        <div>
          <h3>Here's a random joke for you:</h3>
          <div className="card p-3">
            <span className="badge bg-success-soft align-self-start mb-3">
              {randomJoke.category}
            </span>
            <p className="m-0">{randomJoke.setup}</p>
            <p className="m-0">{randomJoke.delivery}</p>
            <p className="m-0">{randomJoke.joke}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
