import React from "react";
import cx from "classnames";
import BootstrapCard from "shared/components/BootstrapCard";
import CopyButton from "shared/components/CopyButton";

const VenueEditorialBlock = ({ venue }) => (
  <BootstrapCard title="Editorial">
    {venue.chain && (
      <>
        <h5>Group</h5>
        <p className="small">{venue.chain.name}</p>
      </>
    )}
    <h5>Description</h5>
    <p className="small">{venue.description}</p>
    <h5>Categories</h5>
    <div>
      {venue.categories.map((category, idx) => (
        <span className={cx("badge bg-light", idx > 0 && "ms-2")} key={idx}>
          {category.name}
        </span>
      ))}
    </div>
    {venue.share_url && (
      <>
        <h5 className="mt-3">Share URL</h5>
        <p className="small">
          {venue.share_url}{" "}
          <CopyButton className="ms-2" text={String(venue.share_url)} />
        </p>
      </>
    )}
    <div className="row mt-3">
      <div className="col-6">
        <h5>Price level</h5>
        <p className="small">
          {Array(venue.price_level)
            .fill("")
            .map((_, idx) => (
              <span className="me-1" key={idx}>
                £
              </span>
            ))}
        </p>
      </div>
      {/* Instagram */}
    </div>
    <h5>Venue ID</h5>
    <p className="small">
      {venue.id} <CopyButton className="ms-2" text={String(venue.id)} />
    </p>
    <h5>Fidel ID</h5>
    <p className="small mb-0">
      {venue.fidel_location_id || "N/A"}{" "}
      {venue.fidel_location_id && (
        <CopyButton className="ms-2" text={venue.fidel_location_id} />
      )}
    </p>
  </BootstrapCard>
);

export default VenueEditorialBlock;
