import { handleActions } from "redux-actions";
import {
  ADD_TO_SERVICE_FEEDBACK,
  REMOVE_FROM_SERVICE_FEEDBACK,
} from "./actionTypes";

const initialState = {
  toasties: [],
  lastUsedId: 0,
};

export default handleActions(
  {
    [ADD_TO_SERVICE_FEEDBACK]: (state, { payload }) => ({
      toasties: [...state.toasties, { ...payload, id: state.lastUsedId + 1 }],
      lastUsedId: state.lastUsedId + 1,
    }),
    [REMOVE_FROM_SERVICE_FEEDBACK]: (state, { payload }) => {
      return {
        ...state,
        toasties: state.toasties.filter(toast => toast.id !== payload),
      };
    },
  },
  initialState
);
