import { useField } from "formik";
import React, { useMemo } from "react";
import cx from "classnames";
import Select from "react-select";

const SingleSelectField = ({
  className,
  disabled,
  label,
  options,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props.name);

  const selectOptions = useMemo(
    () =>
      options.map(data => ({
        value: String(data.id),
        label: data.name,
        raw: data,
      })),
    [options]
  );

  const optionLookup = useMemo(
    () =>
      (selectOptions || []).reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [selectOptions]
  );

  const fieldValue = useMemo(() => {
    if (["string", "number"].indexOf(typeof field.value) !== -1) {
      return optionLookup[String(field.value)] || "";
    }

    return "";
  }, [field.value, optionLookup]);

  const fieldId = props.id || props.name;

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={fieldId}>
          {label}
        </label>
      )}
      <Select
        className={cx(meta?.touched && meta?.error && "is-invalid")}
        isDisabled={disabled}
        options={selectOptions}
        {...field}
        {...props}
        onChange={({ value }) => setValue(value)}
        value={fieldValue}
      />
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default SingleSelectField;
