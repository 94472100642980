import React, { useMemo } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CardModal from "shared/components/CardModal";
import TextField from "shared/components/formFields/TextField";
import AutoDismissModalButton from "./AutoDismissModalButton";
import _ from "lodash";

const UpdateCustomerFieldModal = ({
  name,
  fieldValue,
  isOpen,
  handleClose,
  handleAction,
  error,
  isSuccess,
}) => {
  const readableName = useMemo(() => _.startCase(name), [name]);
  return (
    <CardModal
      show={isOpen}
      onClose={handleClose}
      title={`Update ${readableName}`}
    >
      {() => (
        <Formik
          initialValues={{
            new_value: "",
          }}
          onSubmit={({ new_value }, { resetForm }) => {
            handleAction(name, new_value).then(() => {
              resetForm({
                new_value: "",
              });
            });
          }}
          validationSchema={Yup.object().shape({
            new_value: Yup.string().required(
              `${readableName} cannot be empty.`
            ),
          })}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="card-body">
                <div className="form-group ">
                  <label className="form-label">Existing {readableName}</label>
                  <input
                    label={`Existing ${readableName}`}
                    value={fieldValue}
                    className="form-control text-muted"
                    disabled
                  />
                </div>
                <TextField
                  className="form-group"
                  label={`New ${readableName}`}
                  name="new_value"
                />
                {error && <p className="text-danger mb-0">{error}</p>}
              </div>
              <div className="card-footer d-flex justify-content-end">
                <AutoDismissModalButton
                  onClose={handleClose}
                  isLoading={isSubmitting}
                  isSuccess={isSuccess}
                  buttonText={`Update ${readableName}`}
                  buttonTextSuccess="Updated"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </CardModal>
  );
};

export default UpdateCustomerFieldModal;
