import React from "react";
import TableHeaderCell from "./TableHeaderCell";

const TableHeaderRow = ({ columns }) => (
  <tr>
    {columns.map((column, idx) => (
      <TableHeaderCell key={idx}>{column}</TableHeaderCell>
    ))}
  </tr>
);

export default TableHeaderRow;
