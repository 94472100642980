import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import formValidation from "../../../shared/formValidation";
import Textbox from "../../../shared/components/Textbox";
import ImageDrop from "../../../shared/components/ImageDrop";
import FormSubmitButton from "../../../shared/components/FormSubmitButton";
import DeleteButton from "../../../shared/components/DeleteButton";

const CategoryForm = ({
  onFormSubmit,
  initialValues = { name: "", subtitle: "", image_url: "", colour: "" },
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onFormSubmit}
    validationSchema={Yup.object().shape({
      image_url: formValidation.imageURL,
      name: formValidation.name,
      colour: Yup.string().matches(
        /^#[0-9a-fA-F]{6}$/,
        "Must be a valid RGB value."
      ),
    })}
  >
    {({ isSubmitting, values }) => (
      <Form>
        <Textbox label="Category name" placeholder="Food" name="name" />
        <Textbox
          label="Category subtitle"
          placeholder="Cool beautiful things"
          name="subtitle"
        />
        <ImageDrop
          label="Cover image"
          name="image_url"
          imageType="reward_category"
        />
        <Textbox label="Category colour" placeholder="#FF0000" name="colour" />
        <hr className="my-5" />
        <div className="row pb-5">
          <div className="col text-md-right">
            {values.id && (
              <DeleteButton endpoint="reward-categories" id={values.id}>
                Category
              </DeleteButton>
            )}
            <FormSubmitButton isSubmitting={isSubmitting}>
              {values.id ? "Save" : "Create"} Category
            </FormSubmitButton>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default CategoryForm;
