import React from "react";
import cx from "classnames";
import Tooltip from "./Tooltip";

const TooltipIcon = ({
  label,
  placement = "top",
  icon = "help-circle",
  iconClass,
}) => (
  <Tooltip label={label} placement={placement} className="ms-1">
    <button
      type="button"
      className={cx(`fe fe-${icon} ml-2"`, iconClass)}
      style={{ background: "none", border: "none" }}
    />
  </Tooltip>
);

export default TooltipIcon;
