import React from "react";
import Helmet from "react-helmet";
import moment from "moment";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import { Link, useHistory } from "react-router-dom";

import PageHeader from "shared/components/PageHeader";
import HeaderTabs from "shared/components/HeaderTabs";
import { renderBadgesForOffer } from "./shared";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import TableHeaderSortable from "shared/components/TableHeaderSortable";

const OfferListPage = () => {
  const history = useHistory();

  const [listParams, setListParams] = useQueryParams({
    sort: withDefault(StringParam, null),
    type: withDefault(StringParam, "all"),
  });

  return (
    <div>
      <Helmet>
        <title>Offers</title>
      </Helmet>
      <PageHeader
        pretitle="Dusk Admin"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link className="btn btn-primary lift" to="/offers/create">
              Create Offer
            </Link>
          </div>
        )}
        renderTabs={() => (
          <HeaderTabs
            currentTabId={listParams.type}
            onTabSelected={type => setListParams({ type })}
            tabs={[
              {
                id: "all",
                label: "All",
              },
              {
                id: "published",
                label: "Live",
              },
              {
                id: "unpublished",
                label: "Unpublished",
              },
            ]}
          />
        )}
      >
        Offers
      </PageHeader>
      <QueryStringListPageContents
        apiPath="/offers"
        sortParams={listParams}
        setSortParams={setListParams}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Name", sortBy: "name" },
              { label: "Status", sortBy: "status" },
              { label: "Campaigns", sortBy: "campaigns" },
              { label: "Redemptions", sortBy: "redemption_count" },
              { label: "Created", sortBy: "created_at" },
            ]}
            setSortParams={setListParams}
            sortParams={listParams}
          />
        )}
        renderRow={offer => (
          <tr
            key={offer.id}
            role="button"
            onClick={e => {
              if (
                e.target.className === "rowlink" ||
                e.target.tagName === "A"
              ) {
                return;
              }
              history.push(`/offers/${offer.id}`);
            }}
          >
            <td>
              <Link className="rowlink" to={`/offers/${offer.id}`} />
              <div className="d-flex align-items-center">
                {offer.image_url && (
                  <div className="avatar me-3">
                    <img
                      className="avatar-img rounded"
                      src={offer.image_url.replace(
                        "cdn.drinki.com",
                        "cdn.dusk.app"
                      )}
                      alt={offer.name}
                    />
                  </div>
                )}
                <div>
                  <h4 className="fw-normal mb-0">{offer.name}</h4>
                  <small className="text-muted">
                    {/* For Syntax Highlighting */}
                    {
                      Object({ free_drink: "Free Drink", custom: "Offer" })[
                        offer.type
                      ]
                    }
                  </small>
                </div>
              </div>
            </td>
            <td>{renderBadgesForOffer(offer)}</td>
            <td>
              <div className="d-flex flex-column align-items-start">
                {offer.campaigns &&
                  offer.campaigns.map(campaign => (
                    <Link to={`/campaigns/${campaign.id}`}>
                      {campaign.name}
                    </Link>
                  ))}
              </div>
            </td>
            <td>
              {offer.redemption_count} Redemption
              {offer.redemption_count !== 1 && "s"}
            </td>
            <td>{moment(offer.created_at).format("Do MMM YYYY")}</td>
          </tr>
        )}
      />
    </div>
  );
};

export default OfferListPage;
