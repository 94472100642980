import React, { useState } from "react";

import Button from "../../shared/components/Button";
import { Form, Formik } from "formik";
import formValidation from "../../shared/formValidation";
import Textbox from "../../shared/components/Textbox";
import cx from "classnames";
import styles from "./AuthPage.module.scss";

import * as Yup from "yup";
import api from "../../shared/api";
import { Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { completeLogin } from "./actions";

const Login = () => {
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const dispatch = useDispatch();

  // This won't be called unless the form has validated.
  const onFormSubmit = ({ email, password }, { setSubmitting }) => {
    setSubmitting(true);
    setError(false);

    //
    api
      .post("/auth/login", { email, password })
      .then(({ data }) => {
        setSubmitting(false);
        dispatch(completeLogin(data.data));
      })
      .catch(err => {
        setSubmitting(false);
        if (err && err.response) {
          if (err.response.status === 409) {
            setRedirect({
              pathname: "/confirm-account",
              state: {
                email: email,
                tmp_password: password,
              },
            });
          } else {
            setError(err.response.data.error);
          }
        }

        console.log(err, err.response);
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={onFormSubmit}
      validationSchema={Yup.object().shape({
        email: formValidation.email,
        password: formValidation.password,
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <Textbox label="Email" name="email" placeholder="jane@dusk.app" />
          <Textbox
            label="Password"
            name="password"
            placeholder="••••••••"
            type="password"
          />
          <Button
            className={cx(styles.button, "btn-block")}
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Log in"
            )}
          </Button>
          {error && <p className="text-danger mt-3 mb-0">{error}</p>}
          <p className="mkit-p-14 mt-3">
            <Link to="/forgot-password">Forgot password? Click here</Link>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
