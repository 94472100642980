import React from "react";
import { Link } from "react-router-dom";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";
import { formatDateTime } from "shared/utilities";

const CustomerRewardRedemptionListTable = ({ customerId }) => (
  <LocalStateListPageContents
    apiPath="/reward-redemptions"
    apiParams={{ customer_id: customerId }}
    renderHeader={() => (
      <div className="card-header">
        <h4 className="card-header-title">Reward Redemptions</h4>
      </div>
    )}
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Reward</th>
          <th>Code</th>
          <th>Points</th>
          <th>Date</th>
        </tr>
      </thead>
    )}
    renderRow={redemption => (
      <tr key={redemption.id}>
        <td>
          <Link to={`/rewards/${redemption.reward.id}/edit`}>
            {redemption.reward.name}
          </Link>
        </td>
        <td>{redemption.code || "N/A"}</td>
        <td>{redemption.reward.points_cost}</td>
        <td>{formatDateTime(redemption.created_at)}</td>
      </tr>
    )}
  />
);

export default CustomerRewardRedemptionListTable;
