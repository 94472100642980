import React from "react";
import { Route, Switch } from "react-router-dom";
import RewardCodeCreatePage from "./rewardCode/RewardCodeCreatePage";

const RewardCodeSection = () => (
  <Switch>
    <Route component={RewardCodeCreatePage} />
  </Switch>
);

export default RewardCodeSection;
