import moment from "moment";

export const badgesForVenue = venue =>
  [
    venue.is_test && {
      variant: "dark",
      label: "Test",
    },
    venue.published_at === null && {
      variant: "light",
      label: "Unpublished",
    },
    venue.published_at &&
      new Date() >= moment(venue.published_at).toDate() && {
        variant: "success",
        label: "Published",
      },
    venue.published_at &&
      new Date() < moment(venue.published_at).toDate() && {
        variant: "secondary",
        label: "Scheduled",
      },
    (venue.daily_redemption_limits?.base ||
      venue.daily_redemption_limits?.days?.length) && {
      variant: "info",
      label: "Limited",
    },
    venue.fidel_location_id && {
      variant: "primary",
      label: "Fidel",
    },
  ].filter(Boolean);
