import React, { useMemo } from "react";
import Select from "react-select";

const VenueSelectionSearchTypeSelect = ({ onSelect, value }) => {
  const options = useMemo(
    () => [
      {
        label: "By Venue",
        value: "venue",
      },
      {
        label: "By Group",
        value: "chain",
      },
      {
        label: "By Collection",
        value: "collection",
      },
    ],
    []
  );

  const currentValue = useMemo(
    () => options.find(option => option.value === value) || options[0],
    [options, value]
  );

  return (
    <div style={{ minWidth: "160px" }}>
      <Select
        isSearchable={false}
        onChange={({ value: newValue }) => onSelect(newValue)}
        options={options}
        value={currentValue}
      />
    </div>
  );
};

export default VenueSelectionSearchTypeSelect;
