import React, { useCallback, useEffect, useState } from "react";
import api from "../../shared/api";
import PageHeader from "../../shared/components/PageHeader";
import GenericTable from "../../shared/components/GenericTable";
import AdminUserTableHeaderRow from "./listPage/AdminUserTableHeaderRow";
import AdminUserTableRow from "./listPage/AdminUserTableRow";
import { Link } from "react-router-dom";
import { extractAPIErrorString } from "../../shared/utils/extractErrors";
import { useDispatch } from "react-redux";
import { addToServiceFeedback } from "../serviceFeedback/actions";

const AdminUserListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/users")
      .then(
        ({ data }) => {
          setUsers(data.data);
        },
        extractAPIErrorString(error => {
          dispatch(
            addToServiceFeedback({
              type: "warning",
              message: error,
            })
          );
        })
      )
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  const deleteUser = useCallback(
    id => {
      if (isDeleting) {
        return;
      }
      setIsDeleting(true);
      api
        .delete(`/users/${id}`)
        .then(() => {
          setUsers(users.filter(user => user.id !== id));
        })
        .finally(() => setIsDeleting(false));
    },
    [isDeleting, users]
  );

  return (
    <div>
      <PageHeader
        pretitle="dusk admin"
        renderAdditionalComponents={() => (
          <div className="col-12 col-md-auto">
            <Link to="/admin-users/invite" className="btn btn-primary d-block">
              <span className="fe fe-plus me-2" />
              Invite New
            </Link>
          </div>
        )}
      >
        Admin Users
      </PageHeader>
      <GenericTable
        data={users}
        isLoading={isLoading}
        renderHeaderRow={() => <AdminUserTableHeaderRow />}
        renderItem={user => (
          <AdminUserTableRow
            key={user.id}
            user={user}
            onDelete={() => deleteUser(user.id)}
          />
        )}
      />
    </div>
  );
};

export default AdminUserListPage;
