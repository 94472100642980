import { useField } from "formik";
import React from "react";
import TooltipIcon from "../TooltipIcon";

const SwitchField = ({ className, label, tooltip, ...props }) => {
  const [field, meta] = useField(props.name);

  return (
    <div className={className}>
      {label && (
        <label className="form-label" htmlFor={props.id || props.name}>
          {label}
          {tooltip && <TooltipIcon label={tooltip} className="ms-1" />}
        </label>
      )}
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={field.value}
          {...field}
          {...props}
        />
      </div>
      {meta?.touched && meta?.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default SwitchField;
