import React from "react";
import cx from "classnames";

import styles from "./RemovableMediaPreview.module.scss";
import MediaItem from "app/venues/detailPage/MediaItem";

const RemovableMediaPreview = ({ className, imageUrl, onRemove, style }) => (
  <div className={cx([styles.imagePreviewContainer, className])} style={style}>
    <div
      className={cx([styles.remove, "border border-light"])}
      onClick={onRemove}
      role="button"
    >
      <span className="fe fe-x text-muted" />
    </div>
    <div
      className="mb-3"
      style={{
        paddingBottom: "100%",
        position: "relative",
      }}
    >
      <MediaItem
        alt="Alternative Media"
        className={styles.image}
        mediaUrl={imageUrl}
        style={{ position: "absolute", height: "100%" }}
      />
    </div>
  </div>
);

export default RemovableMediaPreview;
