import React, { useEffect, useState, useCallback } from "react";
import Button from "shared/components/Button";
import api from "shared/api";
import { badgesForVenue } from "app/venues/shared";
import YetAnotherTable from "shared/components/YetAnotherTable";
import cx from "classnames";
import moment from "moment";
import VenueSelectionCard from "shared/components/VenueSelectionCard";
import TooltipIcon from "shared/components/TooltipIcon";

const VenueChainAddVenuesForm = ({ handleAddVenues }) => {
  const [chains, setChains] = useState(null);
  const [collections, setCollections] = useState(null);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [isAdding, setIsAdding] = useState(null);
  const [showTable, setShowTable] = useState(false);

  // Load Chains
  useEffect(() => {
    if (chains) {
      return;
    }

    api
      .get("/venue-chains", { params: { count: 250 } })
      .then(({ data }) => setChains(data.data));
  }, [chains]);

  // Load Collections
  useEffect(() => {
    if (collections) {
      return;
    }

    api
      .get("/venue-collections", { params: { count: 250 } })
      .then(({ data }) => setCollections(data.data));
  }, [collections]);

  const onSubmitVenues = useCallback(() => {
    setIsAdding(true);
    handleAddVenues(selectedVenues).then(
      () => {
        setSelectedVenues([]);
        setIsAdding(false);
        setShowTable(false);
      },
      () => setIsAdding(false)
    );
  }, [setIsAdding, handleAddVenues, selectedVenues, setSelectedVenues]);

  return (
    <>
      <VenueSelectionCard
        chains={chains}
        collections={collections}
        onVenuesSelected={venues => {
          setSelectedVenues([...selectedVenues, ...venues]);
          setShowTable(true);
        }}
      />

      {showTable && (
        <YetAnotherTable
          data={selectedVenues}
          renderTitleExtras={() => (
            <button
              className="btn btn-sm btn-white"
              type="button"
              onClick={() => {
                setSelectedVenues([]);
              }}
            >
              Clear
            </button>
          )}
          renderHeaderRow={() => (
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Areas</th>
                <th>City</th>
                <th>Group</th>
                <th>Created</th>
                <th />
              </tr>
            </thead>
          )}
          renderRow={venue => (
            <tr key={venue.id}>
              <td>
                <div className="d-flex">
                  {venue.chain?.id && (
                    <TooltipIcon
                      label="Adding this venue will overwrite the existing group it belongs to"
                      icon="alert-circle"
                      iconClass="text-danger me-2"
                    />
                  )}
                  {venue.name}
                </div>
              </td>
              <td>
                {badgesForVenue(venue).map((badge, idx) => (
                  <span
                    className={cx(
                      `badge bg-${badge.variant}`,
                      idx > 0 && "ms-2"
                    )}
                    key={idx}
                  >
                    {badge.label}
                  </span>
                ))}
              </td>
              <td>{venue.city_areas.map(({ name }) => name).join(", ")}</td>
              <td>{venue.city?.name || "N/A"}</td>
              <td>{venue.chain?.name || "N/A"}</td>
              <td>{moment(venue.created_at).format("Do MMM YYYY, HH:mm")}</td>
              <td>
                <i
                  className="h2 fe fe-x-circle"
                  onClick={() => {
                    setSelectedVenues(
                      selectedVenues.filter(item => item.id !== venue.id)
                    );
                  }}
                  role="button"
                />
              </td>
            </tr>
          )}
          title={`Venues (${selectedVenues.length})`}
          renderFooter={() => (
            <div className="p-4 d-flex justify-content-end">
              <Button isLoading={isAdding} onClick={onSubmitVenues}>
                <i className="fe fe-plus me-2" />
                Add Venues
              </Button>
            </div>
          )}
        />
      )}
    </>
  );
};
export default VenueChainAddVenuesForm;
