import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import api from "../api";
import Modal from "./Modal";
import { extractAPIErrorStringNoCB } from "../utils/extractErrors";
import { useDispatch } from "react-redux";
import { addToServiceFeedback } from "../../app/serviceFeedback/actions";

const DeleteButton = ({
  children,
  endpoint,
  id,
  className = "",
  style = {},
}) => {
  const [modal, showModal] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const dispatch = useDispatch();

  function deleteItem() {
    return new Promise(async resolve => {
      api.delete(`/${endpoint}/${id}`).then(
        () => {
          dispatch(
            addToServiceFeedback({
              type: "success",
              message: `Successfully deleted ${children}`,
            })
          );
          setRedirect(true);
          resolve();
        },
        err => {
          dispatch(
            addToServiceFeedback({
              type: "warning",
              message: extractAPIErrorStringNoCB(err),
            })
          );
          resolve();
        }
      );
    });
  }

  if (redirect) {
    return <Redirect to={{ pathname: `/${endpoint}` }} />;
  }

  return (
    <>
      <button
        className={`btn btn-outline-danger me-4 ${className}`}
        type="button"
        onClick={() => showModal(true)}
        style={style}
      >
        <i className={`fe fe-x me-2`} />
        Delete {children}
      </button>

      {modal && (
        <Modal
          onClose={() => {
            showModal(!modal);
          }}
          open={modal}
          header={`Delete ${children}?`}
          footer={
            <div>
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={() => showModal(!modal)}
              >
                I've changed my mind
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={deleteItem}
              >
                Yes, please delete
              </button>
            </div>
          }
          type="danger"
        >
          <p className="mkit-p-16 m-0">
            Are you sure you want to remove this {children}? <br />
            This action cannot be reversed.
          </p>
        </Modal>
      )}
    </>
  );
};

export default DeleteButton;
