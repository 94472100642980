import { useField } from "formik";
import React from "react";
import Input from "../Input";

const WeeklyNumericFieldRow = ({ name, onUpdate, onRemove }) => {
  const [field] = useField(name);
  return (
    <div className="row align-items-center form-group">
      <div className="col-auto">
        <select
          className="form-select form-select-sm"
          onChange={e => onUpdate({ day_idx: parseInt(e.target.value, 10) })}
          value={field.value.day_idx || 0}
        >
          {[
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ].map((dayName, idx) => (
            <option key={idx} value={String(idx)}>
              {dayName}
            </option>
          ))}
        </select>
      </div>
      <div className="col d-flex flex-row align-items-center ps-md-5">
        <Input
          name={`${name}.limit`}
          className="form-control form-control-sm"
          type="number"
          placeholder="Enter a number"
          min="0"
          isGroup={false}
        />
        <span
          className="fe fe-x-circle ms-2"
          onClick={onRemove}
          role="button"
        />
      </div>
    </div>
  );
};

export default WeeklyNumericFieldRow;
