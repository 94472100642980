import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import LocalStateListPageContents from "shared/components/LocalStateListPageContents";

import cx from "classnames";

const CampaignVenueListTable = ({ offerIds, venueSetId }) => (
  <LocalStateListPageContents
    apiPath="/venues"
    apiParams={{ venue_set_id: venueSetId }}
    perPage={10}
    isFlush
    renderHeaderRow={() => (
      <thead>
        <tr>
          <th>Venue</th>
          <th>Areas</th>
          <th>City</th>
        </tr>
      </thead>
    )}
    renderRow={venue => (
      <tr key={venue.id}>
        <td>
          <Link to={`/venues/${venue.id}`}>{venue.name}</Link>

          {[
            (!venue.published_at ||
              new Date() < moment(venue.published_at).toDate()) && {
              variant: "light",
              label: "Unpublished",
            },
            Boolean(
              venue.offers.find(
                offer => offerIds.indexOf(offer.id) !== -1 && offer.is_paused
              )
            ) && {
              variant: "warning",
              label: "Paused",
            },
            Boolean(
              venue.offers.find(
                offer =>
                  offerIds.indexOf(offer.id) !== -1 &&
                  (offer.total_redemption_limit ||
                    offer.daily_redemption_limits?.base ||
                    offer.daily_redemption_limits?.days?.length)
              )
            ) && {
              variant: "info",
              label: "Limited",
            },
          ]
            .filter(Boolean)
            .map(({ label, variant }) => (
              <span className={cx(`badge bg-${variant} ms-2`)}>{label}</span>
            ))}
        </td>
        <td>{venue.city_areas.map(({ name }) => name).join(", ")}</td>
        <td>{venue.city.name}</td>
      </tr>
    )}
  />
);

export default CampaignVenueListTable;
