import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CardModal from "shared/components/CardModal";
import TextField from "shared/components/formFields/TextField";
import api from "shared/api";
import LoadingSpinner from "shared/components/LoadingSpinner";

const VenueChainFormModal = ({ chain, onClose, onConfirm, show }) => (
  <CardModal
    onClose={onClose}
    show={show}
    title={chain ? "Update Group" : "Create Group"}
  >
    {() => (
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: chain?.name || "",
        }}
        onSubmit={({ name }) =>
          new Promise(resolve => {
            const apiRequest = chain
              ? api.put(`/venue-chains/${chain.id}`, { name })
              : api.post("/venue-chains", { name });

            apiRequest
              .then(({ data }) => onConfirm(data.data))
              .finally(resolve);
          })
        }
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            "Name is required... It's the only field!"
          ),
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <TextField label="Group Name" name="name" />
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end">
              <button className="btn btn-white" onClick={onClose} type="button">
                Cancel
              </button>
              <button className="btn btn-primary ms-3">
                {isSubmitting ? <LoadingSpinner /> : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )}
  </CardModal>
);

export default VenueChainFormModal;
