import React from "react";
import cx from "classnames";

const LoadingSpinner = ({ className }) => (
  <div
    className={cx("spinner-border spinner-border-sm", className)}
    role="status"
  >
    <span className="visually-hidden">Loading...</span>
  </div>
);

export default LoadingSpinner;
