import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Gravatar from "react-gravatar";
import { useSelector } from "react-redux";
import { logoutUser } from "../../app/auth/actions";

const NavigationProfileIcon = ({ className = "" }) => {
  const userEmail = useSelector(state => state.auth.user.email);
  const dispatch = useDispatch();

  return (
    <div className={`navbar-user text-center mx-0 ${className}`}>
      <div className="dropdown dropup">
        <div className="dropdown-menu">
          <Link to="/profile">
            <span className="dropdown-item">Profile</span>
          </Link>
          <Link to="/change-password">
            <span className="dropdown-item">Change password</span>
          </Link>
          <hr className="dropdown-divider" />
          <span
            className="dropdown-item"
            onClick={() => dispatch(logoutUser())}
          >
            Logout
          </span>
        </div>
        <span
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Gravatar email={userEmail} style={{ borderRadius: 25 }} />
        </span>
      </div>
    </div>
  );
};

export default NavigationProfileIcon;
