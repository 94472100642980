import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import PageHeader from "shared/components/PageHeader";
import HeaderTabs from "shared/components/HeaderTabs";
import { renderBadgesForCampaign } from "./shared";
import QueryStringListPageContents from "shared/components/QueryStringListPageContents";
import TableHeaderSortable from "shared/components/TableHeaderSortable";
import DropdownButton from "shared/components/DropdownButton";
import api from "shared/api";
import OfferCountBadges from "./shared/OfferCountBadges";

const CampaignListPage = () => {
  const history = useHistory();
  const [resetKey, setResetKey] = useState(null);

  const [listParams, setListParams] = useQueryParams({
    sort: withDefault(StringParam, null),
    type: withDefault(StringParam, "all"),
  });

  const toggleCampaignPause = useCallback(campaign => {
    api
      .put(`/campaigns/${campaign.id}`, {
        is_paused: campaign.is_paused ? false : true,
      })
      .then(() => {
        setResetKey(Date.now());
      });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>
      <PageHeader
        pretitle="Dusk Admin"
        renderAdditionalComponents={() => (
          <div className="col-auto">
            <Link className="btn btn-primary lift" to="/campaigns/create">
              Create Campaign
            </Link>
          </div>
        )}
        renderTabs={() => (
          <HeaderTabs
            currentTabId={listParams.type}
            onTabSelected={type => setListParams({ type })}
            tabs={[
              {
                id: "all",
                label: "All",
              },
              {
                id: "published_or_scheduled",
                label: "Live",
              },
              {
                id: "unpublished",
                label: "Drafts",
              },
              {
                id: "archived",
                label: "Archive",
              },
            ]}
          />
        )}
      >
        Campaigns
      </PageHeader>

      <QueryStringListPageContents
        apiPath="/campaigns"
        sortParams={listParams}
        setSortParams={setListParams}
        resetKey={resetKey}
        renderHeaderRow={() => (
          <TableHeaderSortable
            columns={[
              { label: "Name", sortBy: "name" },
              { label: "Status", sortBy: "status" },
              { label: "Redemptions", sortBy: "redemptions_count" },
              { label: "Offers", sortBy: "offers" },
              { label: "Venues", sortBy: "venues" },
              { label: "Start", sortBy: "start" },
              { label: "End", sortBy: "end" },
              { label: "" },
            ]}
            setSortParams={setListParams}
            sortParams={listParams}
          />
        )}
        renderRow={campaign => (
          <tr
            key={campaign.id}
            role="button"
            onClick={e => {
              if (e.target.className === "rowlink") {
                return;
              }
              history.push(`/campaigns/${campaign.id}`);
            }}
          >
            <td>
              <Link className="rowlink" to={`/campaigns/${campaign.id}`} />
              <div className="d-flex align-items-center">
                <div>
                  <h4 className="fw-normal mb-0">{campaign.name}</h4>
                </div>
              </div>
            </td>
            <td>{renderBadgesForCampaign(campaign)}</td>
            <td>{campaign.redemption_count}</td>
            <td>
              <OfferCountBadges
                freeDrinkCount={
                  campaign.offer_counts.free_drink +
                  campaign.offer_counts.free_af_drink
                }
                otherOfferCount={campaign.offer_counts.custom}
              />
            </td>
            <td>{campaign.venue_count}</td>
            <td>
              {campaign.published_from
                ? moment(campaign.published_from).format("Do MMM YY")
                : "N/A"}
            </td>
            <td>
              {campaign.published_until
                ? moment(campaign.published_until).format("Do MMM YY")
                : campaign.total_redemption_limit !== null
                ? `${campaign.redemption_count}/${campaign.total_redemption_limit}`
                : "N/A"}
            </td>
            <td onClick={e => e.stopPropagation()}>
              <DropdownButton type="ellipses">
                <button
                  className="dropdown-item"
                  onClick={() => history.push(`/campaigns/${campaign.id}/edit`)}
                  type="button"
                >
                  Edit
                </button>
                {campaign.published_from && (
                  <button
                    className="dropdown-item"
                    onClick={() => toggleCampaignPause(campaign)}
                    type="button"
                  >
                    {campaign.is_paused ? "Unpause" : "Pause"}
                  </button>
                )}
                <button
                  className="dropdown-item"
                  onClick={() => navigator.clipboard.writeText(campaign.id)}
                  type="button"
                >
                  Copy ID
                </button>
              </DropdownButton>
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default CampaignListPage;
