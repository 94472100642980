import React, { forwardRef, useCallback } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import LoadingSpinner from "./LoadingSpinner";
import { BOOTSTRAP_COLORS, FEATHER_ICONS } from "../variables";
import { Link } from "react-router-dom";

const Button = forwardRef(
  (
    {
      buttonType = "primary", // phase this out if possible
      variant,
      size,
      children: _children,
      className,
      isLoading,
      isOutlined,
      icon,
      iconDirn = "left",
      isLink,
      type = "button",
      ...props
    },
    ref
  ) => {
    let Tag = "button";
    if (isLink) {
      Tag = Link;
    }

    const _className = cx(
      {
        [`btn-outline-${variant || buttonType}`]: isOutlined,
        [`btn-${variant || buttonType}`]: !isOutlined,
        [`btn-${size}`]: size,
        "position-relative": isLoading,
        disabled: isLoading,
      },
      `btn`,
      className
    );
    const iconClassName = cx(
      {
        "me-2": iconDirn === "left" && _children,
        "ms-2": iconDirn === "right" && _children,
        [`fe-${icon}`]: icon,
      },
      `fe`
    );

    const Wrapper = useCallback(
      ({ children }) =>
        isLoading ? (
          <div style={{ opacity: 0, pointerEvents: "none" }}>{children}</div>
        ) : (
          children
        ),
      [isLoading]
    );
    return (
      <Tag ref={ref} className={_className} type={type} {...props}>
        <Wrapper>
          {icon && iconDirn === "left" && <i className={iconClassName} />}
          {_children}
          {icon && iconDirn === "right" && <i className={iconClassName} />}
        </Wrapper>
        {isLoading && (
          <div
            className="w-100 position-absolute d-flex align-items-center justify-content-center"
            style={{ top: 0, left: 0, bottom: 0, right: 0 }}
          >
            <LoadingSpinner />
          </div>
        )}
      </Tag>
    );
  }
);

Button.propTypes = {
  size: PropTypes.oneOf(["sm", "lg", undefined]),
  icon: PropTypes.oneOf(FEATHER_ICONS),
  buttonType: PropTypes.string,
  variant: PropTypes.oneOf(BOOTSTRAP_COLORS),
  isOutlined: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  iconDirn: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Button;
