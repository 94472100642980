import React from "react";
import { useCallback, useState } from "react";
import GenericListPageContents from "./GenericListPageContents";

const LocalStateListPageContents = props => {
  const [listParams, setListParams] = useState({
    per_page: props.perPage || 25,
    page: 1,
    q: "",
  });

  // Just so we don't have to change the method signature
  const innerSetListParams = useCallback(
    params => {
      setListParams(existingParams => ({ ...existingParams, ...params }));
    },
    [setListParams]
  );

  return (
    <GenericListPageContents
      paginationParams={listParams}
      setPaginationParams={innerSetListParams}
      {...props}
    />
  );
};

export default LocalStateListPageContents;
